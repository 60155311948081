import React, {useState, useEffect} from 'react'
import {Tab,Row,Nav,Col,InputGroup} from 'react-bootstrap'
import {checkAbaNumber,checkRoutingNumber,checkEmailAddress, checkPhoneNumber} from '../../lib/fieldValidation'
import appConfig from '../../config/app.json'
import {HelpCircle, Circle, CheckCircle, Disc} from 'react-feather'

export default function NVSPartTwo(props){
    const {register, step, handleSubmit, setValue, errors, setError, clearError, values, setValues, setStep} = props

    const [paymentType, setPaymentType] = useState('credit')
    const [showPaymentInfo, setShowPaymentInfo] = useState(false)
    const [usePrimary, setUsePrimary] = useState(false)

    useEffect(() => {
        if(step === 4 && !showPaymentInfo) setShowPaymentInfo(values.payment_type)
    })

    function loadPrimaryContactInfo(checked){
        if(checked){
            var tempInfo = {...props.values.payment_info}
            tempInfo.payment_contact_name = values.primary_contact_name
            tempInfo.payment_contact_email = values.primary_contact_email
            tempInfo.payment_contact_number = values.primary_contact_number
            props.clearError()
            props.setValues({...props.values, payment_info: tempInfo, usePrimary: true})
        }

        if(!checked){
            var tempInfo = {...props.values.payment_info}
            tempInfo.payment_contact_name = ''
            tempInfo.payment_contact_email = ''
            tempInfo.payment_contact_number = ''
            props.clearError()
            props.setValues({...props.values, payment_info: tempInfo, usePrimary: false})
        }
    }

    function validateStep(){
        var valid = true
        var config = paymentType === 'ach' ? appConfig.ach_payment_info_needs : appConfig.credit_payment_info_needs
        config.forEach((field) => {
            if(field.validation === 'email') if(!checkEmailAddress(values.payment_info[field.key], field.key, props, true)) valid = false
            if(field.validation === 'aba') if(!checkAbaNumber(values.payment_info[field.key], field.key, props, true)) valid = false
            if(field.validation === 'routing') if(!checkRoutingNumber(values.payment_info[field.key], field.key, props, true)) valid = false
            if(field.validation === 'phone') if(!checkPhoneNumber(values.payment_info[field.key], field.key, props, true)) valid = false
            if(!values.payment_info[field.key]){
                valid = false
                setError(field.key, 'missing_value', 'Please enter a value')
            }
        })

        if(valid) {
            //setValues({...values, ...formValues, payment_type: paymentType})
            setStep(3)
            return true
        }

        return false
    }

    function validateField(event, validation, target){
        if(validation === 'email') return checkEmailAddress(event.target.value, target, props, false)
        if(validation === 'aba') return checkAbaNumber(event.target.value, target, props, false)
        if(validation === 'routing') return checkRoutingNumber(event.target.value, target, props, false)
        if(validation === 'phone') return checkPhoneNumber(event.target.value, target, props, false)
    }

    console.log(errors)

    return (
        <div class="col-10 mr-auto ml-auto">
            <section className="progress-bullets">
                <ul>
                    <li className="complete">
                        <span>Step 1:</span> Name and Tax Information
                        <CheckCircle size={28} />
                    </li>
                    <li className="active">
                        <span>Step 2:</span> Payment Information
                        <Disc size={28} />
                    </li>
                    <li>
                        <span>Step 3:</span> Address Information
                        <Circle size={28} />
                    </li>
                </ul>
            </section>
            <div className="tile">
                {step === 2 ? <div className="col-12">
                    <h1>Part 2: Payment Information</h1>
                    <p>Please select a payment method below.</p>
                </div>: ''}
                <form onSubmit={handleSubmit(validateStep)} className="col-12">
                    <div className="form-row">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row className="col-12">
                                <Col sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link active={props.values.payment_info.payment_type === 'credit' ? true : false} onClick={() => {
                                                props.setValues({...values, payment_info: {...props.values.payment_info, payment_type: 'credit'}})
                                                clearError()
                                                }} eventKey="credit">Credit Card</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link active={props.values.payment_info.payment_type === 'ach' ? true : false} onClick={() => {
                                                props.setValues({...values, payment_info: {...props.values.payment_info, payment_type: 'ach'}})
                                                clearError()
                                                }} eventKey="ach">ACH</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane active={props.values.payment_info.payment_type === 'credit' ? true : false} eventKey="credit">
                                            Credit Card payments require no additional processing time allowing you to receive your payment faster and with less hassle.  With each payment you will receive a notification and instructions to claim your funds.
                                        </Tab.Pane>
                                        <Tab.Pane active={props.values.payment_info.payment_type === 'ach' ? true : false} eventKey="ach">
                                            ACH (Automated Clearing House) requires an ABA and Routing number for your bank.  Payment processing per banking standards requires additional processing time.
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>

                    {/* Payment Info needs for Credit Cards */}
                    {values.payment_info.payment_type === 'credit' ? 
                        <div class="form-row py-4">
                            <React.Fragment>
                                {appConfig.credit_payment_info_needs.map((field) => {
                                    return (
                                        <div className="col-md-3 py-2">
                                            <label for="legal_name">{field.name}</label>
                                            <input type="text" onChange={(e) => validateField(e, field.validation, {parent: "payment_info", field: field.key})} className={errors[field.key] ? "form-control is-invalid" : "form-control"} name={field.key} id={field.key} value={values.payment_info[field.key]}></input>
                                            {errors[field.key] && errors[field.key].message}
                                        </div>
                                    )
                                })}
                                <div className="col-md-3 checkbox-container">
                                    <input type="checkbox" checked={props.values.usePrimary} name="primary-contact-info" onClick={(e) => loadPrimaryContactInfo(e.target.checked)}  />
                                    <label for="primary-contact-info">Use primary contact</label>
                                </div>
                            </React.Fragment>
                        </div>
                    : ''}

                    {/* Payment Info needs for ACH */}
                    {values.payment_info.payment_type === 'ach' ? 
                        <div class="form-row py-4">
                            <React.Fragment>
                                {appConfig.ach_payment_info_needs.map((field) => {
                                    return (
                                        <div className="col-md-3 py-2">
                                            <label for="legal_name">{field.name}</label>
                                            <input type="text" onChange={(e) => validateField(e, field.validation, {parent: 'payment_info', key: field.key})} className={errors[field.key] ? "form-control is-invalid" : "form-control"} name={field.key} id={field.key} value={values.payment_info[field.key]}></input>
                                            {errors[field.key] && errors[field.key].message}
                                        </div>
                                    )
                                })}
                                <div className="col-md-3 checkbox-container">
                                    <input type="checkbox" checked={props.values.usePrimary}  name="primary-contact-info" onClick={(e) => loadPrimaryContactInfo(e.target.checked)}  />
                                    <label for="primary-contact-info">Use primary contact</label>
                                </div>
                            </React.Fragment>
                        </div>
                    : ''}

                    <div class="form-row my-4">
                        <div class="col-md-6 button-group left">
                            <button className="btn btn-secondary btn-lg" onClick={() => props.setStep(props.step-1)} type="button">Previous</button>
                            <button className="btn btn-primary btn-lg" type="submit">Next</button>
                            <span className="error">{errors.payment_type && errors.payment_type.message}</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}