import React, {useState,useEffect} from 'react'
import {GetAllVendorsAndSetups,CancelNewVendorSetup,ResendSetupEmail} from '../lib/mongoDbhelpers'

export default function useVendorHook(){//this hook provides all the available vendors, selected vendor, and the fucntions needs to manipulate those vendors
    const [vendors, setVendors] = useState(null)
    const [vendorSetups, setVendorSetups] = useState(null)
    const [errors, setErrors] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(vendors === null && !loading) {
            getAllVendors(setVendors, setLoading, setVendorSetups, setErrors)
            setLoading(true)
        }
    })

    return {
        errors: errors, 
        vendors: vendors,
        vendorSetups: vendorSetups,
        getAllVendors: getAllVendors,
        AddNewVendor,
        CancelNewVendorSetup,
        ResendSetupEmail,
        setVendors,
        setLoading,
        setVendorSetups,
        setErrors
    }
}

async function getAllVendors(setVendors, setLoading, setVendorSetups, setErrors){
    var results = await GetAllVendorsAndSetups()
    if(results.errorCode){
        setErrors({errorCode: '011', errorMessage: results.errorMessage})
    }

    setVendors(results.vendors)
    setLoading(false)
}

async function AddNewVendor(values){

}