import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {ArrowLeft,ArrowRight,Search} from 'react-feather'
import {fieldValueFormatter} from '../../lib/util'
import Datepicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import appConfig from '../../config/app.json'

export default function PaymentsTable(props){
    var payments = props.payments
    var paymentContext = props.paymentContext
    const [selectedDate, setSelectedDate] = useState(false)

    return(
        <React.Fragment>
            {/* Header options */}
            <div className="tile">
                {/* <h1>Payments Information</h1> */}
                <table class="table table-sm table-hover w-100 border-bottom payments-table" id="accordionExample">
                    <thead className="thead-light table-striped table-filters">
                        {/* Table header Names */}
                        <tr>
                            {appConfig.payment_table_fields.map((hdr, index) => {
                                if(hdr.hidden) return false
                                return(
                                    <th key={index}>{hdr.name}</th>
                                )
                            })}
                        </tr>

                        {/* Table input filters */}
                        <tr>
                            {appConfig.payment_table_fields.map((hdr, index) => {
                                if(hdr.hidden) return false
                                if(hdr.date){
                                    return(
                                        <th key={index}>
                                            <Datepicker selected={selectedDate} name="invoiceTableFilters" id={`invoiceTableFilters${index}`} onChange={(e) => {
                                                props.addFilterLogic(hdr.key, e)
                                                setSelectedDate(e)
                                            }}></Datepicker>
                                            <Search />
                                        </th>
                                    )
                                }

                                if(hdr.dropdown){
                                    return(
                                        <th key={index}>
                                            <select className="form-control" onChange={(e) => props.addFilterLogic(hdr.key, e.target.value)} name="invoicePaymentFilters">
                                                <option>All</option>
                                                <option>ACH</option>
                                                <option>Credit Card</option>
                                            </select>
                                            {/* <input className="mx-0 px-0" name="invoiceTableFilters" id={`invoiceTableFilters${index}`} onChange={(e) => props.addFilterLogic(hdr.key, e.target.value)} type="text"></input> */}
                                        </th>
                                    )
                                }

                                return(
                                    <th key={index}>
                                        <Search />
                                        <input className="mx-0 px-0" name="invoicePaymentFilters" id={`invoicePaymentFilters${index}`} onChange={(e) => props.addFilterLogic(hdr.key, e.target.value)} type="text"></input>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {/* Primary Payment List */}
                        {payments && payments.slice(props.options.idxStart, props.options.idxEnd).map((pay, index) => {
                            return(
                                <tr key={index} onClick={(e) => props.setPaymentContext({payment: pay, open: true})} className={paymentContext && pay.payment_number === paymentContext.payment.payment_number ? 'table-primary' : ''}>
                                    {appConfig.payment_table_fields.map((hdr, index) => {
                                        return <td style={{width:"10%"}} key={index}>{fieldValueFormatter(hdr, pay)}</td>
                                    })}
                                </tr>
                            )
                        })}

                        {/* Navigation options for list */}
                        {payments && payments.length > 15 ?
                            <tr>
                                <td colSpan={appConfig.invoice_table_fields.length}>
                                    <div className="col-12 mr-auto ml-auto text-center">
                                        {props.options.idxStart != 0 ? <ArrowLeft onClick={() => props.handlePageIncrement(-15, payments.length)} className={"featherTableButtons feather-home mt-1"}/>: ''}
                                        <label style={{fontSize:20}} className="align-middle">{`${props.options.idxStart}-${props.options.idxEnd} of ${payments.length}`}</label>
                                        {props.options.idxEnd != payments.length ? <ArrowRight onClick={() => props.handlePageIncrement(15, payments.length)} className={"featherTableButtons feather-home mt-1"}/>: ''}
                                    </div>
                                </td>
                            </tr>
                        : ''}

                        {/*Text for when no results from search*/}
                        {payments && payments.length === 0 ? 
                            <tr>
                                <td colSpan="5">
                                    <h6 className="error">No results were found.</h6>
                                </td>
                            </tr>
                        : ''}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}