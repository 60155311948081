import React, {useState,useEffect} from 'react'
import {GetWorkflowItems} from '../lib/mongoDbhelpers'

export default function useWorkflowItemsHook(){
    const [workflowItems, setWorkflowItems] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(!workflowItems && !loading) {
            setLoading(true)
            GetWorkflowItems(setWorkflowItems)
        }
    })

    function updateWorkflowItems(){
        GetWorkflowItems(setWorkflowItems)
    }

    return {workflowItems: workflowItems, updateWorkflowItems: updateWorkflowItems}
}