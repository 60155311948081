import React, {useState} from 'react'
import moment from 'moment'
import {Button} from 'react-bootstrap'
import {ArrowRightCircle} from 'react-feather'
import cssConfig from '../config/css.json'
import appConfig from '../config/app.json'
import {formatPrice} from '../lib/util'


export default function InvoiceContext(props){
    // Animate the sidebar in/out, setTimeout waits till sidebar is mounted to the DOM
    // Look at this in parent component - not always true
    setTimeout(() => {
        const sidebar = document.querySelector('.sidebar-right');
        sidebar.setAttribute('style', 'right: 0');
    }, 10)

    const closeSidebar = () => {
        const sidebar = document.querySelector('.sidebar-right');
        sidebar.setAttribute('style', 'right: -238px');
        setTimeout(() => props.onClose({...props.invoice, open: false}), 150);
    }

    return(
        <nav class="col-md-2 d-none d-md-block bg-light sidebar-right ml-4">
            <div class="sidebar-sticky col-12">
                <button className="btn btn-icon-left no-border btn-close" onClick={closeSidebar}>
                    <ArrowRightCircle size={20} className='featherTableButtons feather-home mt-1'/>
                    Close
                </button>
                <h5 className="mx-2">Invoice Information</h5>
                <div className={cssConfig.defaultSection}>
                    <table className="table table-sm border-bottom">
                        <tbody>
                            {appConfig.invoice_context_fields.map((field) => {
                                if(!appConfig.display_null_context_fields && !props.invoice.invoice[field.key]) return null

                                if(field.amount) return (
                                    <tr>
                                        <td>{field.name}</td>
                                        <td>{`${formatPrice(props.invoice.invoice[field.key].replace('.', ''))}`}</td>
                                    </tr>
                                )

                                if(field.upper){
                                    return (
                                        <tr>
                                            <td>{field.name}</td>
                                            <td>{`${props.invoice.invoice[field.key].charAt(0).toUpperCase()}${props.invoice.invoice[field.key].slice(1)}`}</td>
                                        </tr>
                                    )
                                }

                                return (
                                    <tr>
                                        <td>{field.name}</td>
                                        <td>{field.format ? moment(props.invoice.invoice[field.key]).format(field.format) : props.invoice.invoice[field.key]}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="col-12">
                    <Button
                        className="btn btn-info btn-sm btn-block"
                        onClick={() => {
                            props.onClose({...props.invoice, open: false})
                            props.history.push({pathname: '/invoices', search: `?i=${props.invoice.invoice['invoice_number']}`, state: {path: window.location.pathname, params: window.location.search}})
                        }}
                    >More Information</Button>
                </div>
                
            </div>
        </nav>
    )
}