import React, {useState,useEffect} from 'react'
import {Modal,Button,Spinner} from 'react-bootstrap'
import {DeactivateUser,ActivateUser,ResetUserPassword} from '../../lib/mongoDbhelpers'
import {fieldValueFormatter} from '../../lib/util'

export default function ViewUserModal(props){
    const handleClose = () => props.setModals({...props.modals, viewUserModal: {open: false, data: false}})
    if(!props.modals.viewUserModal.open) return false

    const headerName = `${props.modals.viewUserModal.data.profile.firstName} ${props.modals.viewUserModal.data.profile.lastName}`

    async function resetUserPassword(){
        props.formHelper.setLoading(true)
        var result = await ResetUserPassword(props.modals.viewUserModal.data.id)
        if(result.error && result.error.errorCode){
            var errors = result.error.errorCauses.map(a => a.errorSummary)
            props.formHelper.setError('submit_error', 'function_failed', errors.join('~'))
            props.formHelper.setLoading(false)
            return false
        } else if (result.errorCode){
            props.formHelper.setError('submit_error', 'function_failed', result.errorMessage)
            props.formHelper.setLoading(false)
            return false
        } else {
            props.formHelper.setLoading(false)
            props.formHelper.clearError()
            props.formHelper.setShowToast(true)
            props.setModals({...props.modals, viewUserModal: {open: true, data: result}})
            props.usersHook.refresh()
        }
    }

    async function deactivateUser(){
        props.formHelper.setLoading(true)
        var result = await DeactivateUser(props.modals.viewUserModal.data.id)
        if(result.error && result.error.errorCode){
            var errors = result.error.errorCauses.map(a => a.errorSummary)
            props.formHelper.setError('submit_error', 'function_failed', errors.join('~'))
            props.formHelper.setLoading(false)
            return false
        } else if (result.errorCode){
            props.formHelper.setError('submit_error', 'function_failed', result.errorMessage)
            props.formHelper.setLoading(false)
            return false
        } else {
            props.formHelper.setLoading(false)
            props.formHelper.clearError()
            props.formHelper.setShowToast(true)
            props.setModals({...props.modals, viewUserModal: {open: true, data: result}})
            props.usersHook.refresh()
        }
    }

    async function activateUser(){
        props.formHelper.setLoading(true)
        var result = await ActivateUser(props.modals.viewUserModal.data.id)
        if(result.error && result.error.errorCode){
            var errors = result.error.errorCauses.map(a => a.errorSummary)
            props.formHelper.setError('submit_error', 'function_failed', errors.join('~'))
            props.formHelper.setLoading(false)
            return false
        } else if (result.errorCode){
            props.formHelper.setError('submit_error', 'function_failed', result.errorMessage)
            props.formHelper.setLoading(false)
            return false
        } else {
            props.formHelper.setLoading(false)
            props.formHelper.clearError()
            props.formHelper.setShowToast(true)
            props.setModals({...props.modals, viewUserModal: {open: true, data: result}})
            props.usersHook.refresh()
        }
    }

    return (
        <Modal size="lg" show={props.modals.viewUserModal.open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>User: {headerName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="tile company-info">
                    <div class="form-row">
                        <div class="col-md-4 mb-2 detail-group">
                            <label for={"user_name"}>Name</label>
                            <h6>{props.modals.viewUserModal.data.profile.firstName} {props.modals.viewUserModal.data.profile.lastName}</h6>
                        </div>
                        <div class="col-md-4 mb-2 detail-group">
                            <label for={"user_name"}>Email</label>
                            <h6>{props.modals.viewUserModal.data.profile.email}</h6>
                        </div>
                        <div class="col-md-4 mb-2 detail-group">
                            <label for={"user_name"}>Status</label>
                            <h6>{props.modals.viewUserModal.data.status}</h6>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-4 mb-2 detail-group">
                            <label for={"user_name"}>Created</label>
                            <h6>{new Date(props.modals.viewUserModal.data.created).toLocaleString()}</h6>
                        </div>
                        <div class="col-md-4 mb-2 detail-group">
                            <label for={"user_name"}>Last Logged In</label>
                            <h6>{props.modals.viewUserModal.data.lastLogin || 'N/A'}</h6>
                        </div>
                        <div class="col-md-4 mb-2 detail-group">
                            <label for={"user_name"}>Password Updated</label>
                            <h6>{new Date(props.modals.viewUserModal.data.passwordChanged).toLocaleString() || 'N/A'}</h6>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-4 mb-2 detail-group">
                            <label for={"user_name"}>Security Group</label>
                            <h6>{fieldValueFormatter({key: 'security_group', upper: true}, {"security_group": props.modals.viewUserModal.data.security_group})}</h6>
                        </div>
                        <div class="col-md-4 mb-2 detail-group">
                            <label for={"user_name"}>Vendors</label>
                            <h6>{props.modals.viewUserModal.data.vendors.join(",")}</h6>
                        </div>
                    </div>
                    <div>
                        {props.formHelper.loading ? 
                            <Button variant="primary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                            </Button>
                        : 
                            <div className="button-group left">
                                {props.modals.viewUserModal.data.status === "PROVISIONED" ? 
                                    <button type="submit" onClick={() => deactivateUser()} className="btn btn-secondary btn-sm">
                                        Deactivate User
                                    </button>
                                : ''}
                                {props.modals.viewUserModal.data.status === 'DEPROVISIONED' ? 
                                    <button type="submit" onClick={() => activateUser()} className="btn btn-secondary btn-sm">
                                        Activate User
                                    </button>
                                : ''}
                                {["ACTIVE","RECOVERY"].includes(props.modals.viewUserModal.data.status) ? 
                                    <React.Fragment>
                                        <button type="submit" onClick={() => deactivateUser()} className="btn btn-secondary btn-sm">
                                            Deactivate User
                                        </button>
                                        <button type="submit" onClick={() => resetUserPassword()} className="btn btn-secondary btn-sm">
                                            Reset Password
                                        </button>
                                    </React.Fragment>
                                : ''}
                                

                                
                                {/* <Button type="submit" className="btn btn-primary mx-3">
                                    Modify User Setup
                                </Button> */}
                            </div>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}