import React, {useState,useEffect} from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import {Circle, CheckCircle, Disc, FilePlus, File, ArrowRight, Trash2} from 'react-feather'
import {validateInvoiceNumber,checkPoNumber,checkInvoiceDate,checkFiles,checkVendorNumber} from '../../lib/fieldValidation'
import Select from 'react-select'
import appConfig from '../../config/app.json'

export default function SubmitInvoicePartOne(props){
    const [vendorList, setVendorList] = useState(false)

    const selectStyles = {
        menu: (provided, state) => ({
            ...provided,
            marginTop: '0'
        }),
        menuList: (provided, state) => ({
            ...provided,
            padding: '0'
        }),
        option: (provided, state) => ({
            ...provided,
            '&:hover': {
                cursor: 'pointer'
            }
        })
    }
    var {register, step, handleSubmit, setValue, errors, setError, clearError, values, setValues, setStep} = props

    async function validateStep(){
        var valid = true
        if(!await validateInvoiceNumber(values.invoice_number, props, true)) valid = false
        if(!await checkPoNumber(values.po_number, props, true)) valid = false
        if(!await checkInvoiceDate(values.invoice_date, props, true)) valid = false
        if(!await checkFiles(values.files, props, true)) valid = false
        if(!await checkVendorNumber(values.vendor_number, props, true))
        if(valid) setStep(2)
    }

    function removeAttachment(event, index){
        var tempFiles = [...values.files]
        tempFiles.splice(index,1)
        setValues({...values, files: tempFiles})
    }

    return(
        <main role="main" class="col-10 ml-sm-auto">
            <section className="progress-bullets">
                <ul>
                    {appConfig.submit_invoice_steps.map((submitStep) => {
                        return (
                            <li 
                                key={submitStep.step_number}
                                className={
                                    (step > submitStep.step_number && 'complete') ||
                                    (step == submitStep.step_number && 'active') ||
                                    'to-do'
                                }
                            >
                                <span>{submitStep.name_one}:</span> {submitStep.name_two}
                                {
                                    (step > submitStep.step_number && <CheckCircle size={28} />) ||
                                    (step == submitStep.step_number && <Disc size={28} />) ||
                                    <Circle size={28} />
                                }
                            </li>
                        )
                    })}
                </ul>
            </section>
            <div className="tile">
                <h1>Step 1: Invoice Number, Date, and PO Number</h1>
                <p>Please enter an Invoice Number, Date, and PO Number (if applicable).</p>
                <form onSubmit={handleSubmit(validateStep)} >
                        <div class="form-row">
                            {props.userData.vendors && props.userData.vendors[0] === '*' ?
                                <div class="form-group col-md-3">
                                    <label for="vendor_number">Vendor Number</label>
                                    {/* <input type="text" className={errors.vendor_number ? "form-control is-invalid" : "form-control"} name="vendor_number" id="vendor_number" value={values.vendor_number}></input> */}
                                    <Select isClearable isSearchable value={values.vendor_number} onChange={(e) => checkVendorNumber(e, props, false)} options={props.vendors} styles={selectStyles} />
                                    <span className="error">{errors.vendor_number && errors.vendor_number.message}</span>
                                </div>
                            : ''}
                            <div class="form-group col-md-3">
                                <label for="invoice_number">Invoice Number</label>
                                <input type="text" onChange={(e) => validateInvoiceNumber(e.target.value, props, false)} className={errors.invoice_number ? "form-control is-invalid" : "form-control"} name="invoice_number" id="invoice_number" value={values.invoice_number}></input>
                                <span className="error">{errors.invoice_number && errors.invoice_number.message}</span>
                            </div>
                            <div class="form-group col-md-3">
                                <label for="inputPassword4">Invoice Date</label>
                                <DatePicker
                                    selected={values.invoice_date}
                                    onChange={(e) => checkInvoiceDate(e, props, false)}
                                    className={errors.invoice_date ? "form-control is-invalid" : "form-control"}
                                />
                                <span className="error">{errors.invoice_date && errors.invoice_date.message}</span>
                            </div>
                            <div class="form-group col-md-3">
                                <label for="invoice_number">PO Number</label>
                                <input type="text" onChange={(e) => {checkPoNumber(e.target.value, props, false)}} class="form-control" name="po_number" id="po_number" value={values.po_number}></input>
                                <span className="error">{errors.po_number && errors.po_number.message}</span>
                            </div>
                        </div>
                        <div className="tile-secondary">
                            <h2>Attachments</h2>
                            <p>Attach a PDF of the invoice and other necessary documents.</p>
                            <div className="attachments">
                                {errors.missing_files ? <p className="error">{errors.missing_files.message}</p> : ''}
                                {values.files.map((file, index) => {
                                    return <div className="attachment">{/*<File size={16}/>*/}<img onClick={() => window.open(window.URL.createObjectURL(file), '_blank')}  style={{height:25,width:25}} src={window.URL.createObjectURL(file)}></img>{file.name}<Trash2 className="remove" onClick={(e) => removeAttachment(e, index)}/></div>
                                })}
                            </div>
                            <label className="btn btn-tertiary btn-icon-left btn-sm" for="file"><FilePlus size={16}/> Attach a PDF</label>
                            <input onClick={(e) => e.target.value = null} onChange={(e) => checkFiles(e, props, false)} name="file" id="file" type='file'></input>
                            
                        </div>
                    {step !== 4 ?
                        <button className="btn btn-primary btn-lg btn-icon-right" type="submit">Next <ArrowRight /></button>
                    : ''}
                </form>
            </div>
        </main>
    )
}