import React, {useState,useEffect} from 'react'
import {Modal,Button,Spinner} from 'react-bootstrap'
import {StartNewVendorSetup} from '../../lib/mongoDbhelpers'

export default function AddVendorModal(modals, setModals, vendorsHook, formHelper, updateWorkflowItems){
    const handleClose = () => setModals({...modals, addVendorModal: {open: false, data: false}})
    const processRequest = async (values) => {
        //assume the data is valid, being processing the request
        formHelper.setLoading(true)
        var result = await StartNewVendorSetup(values)
        if(result.errorCode){
            formHelper.setError('submit_error', 'function_failed', result.errorMessage)
            formHelper.setLoading(false)
            return false
        } else{
            formHelper.setLoading(false)
            formHelper.clearError()
            formHelper.setShowToast(true)
            formHelper.setValue("new_vendor_contact_name", '')
            formHelper.setValue("new_vendor_contact_email", '')
            vendorsHook.getAllVendors(vendorsHook.setVendors, vendorsHook.setLoading, vendorsHook.setVendorSetups, vendorsHook.setErrors)
            handleClose()
            updateWorkflowItems()
        }
    }

    return (
        <Modal show={modals.addVendorModal.open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Vendor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please enter a name and email.</p>
                    <form onSubmit={formHelper.handleSubmit(processRequest)}>
                        <div className="form-row">
                            <div class="col-md-6">
                                <label for="invoice_number">Contact Name</label>
                                <input ref={formHelper.register({required: 'Required'})} className="form-control" type="text" name="new_vendor_contact_name" id="new_vendor_contact_name"></input>
                                {formHelper.errors.new_vendor_contact_name && formHelper.errors.new_vendor_contact_name.message}
                            </div>
                            <div class="col-md-6">
                                <label for="invoice_number">Contact Email</label>
                                <input ref={formHelper.register({required: 'Required'})} className="form-control" type="text" name="new_vendor_contact_email" id="new_vendor_contact_email"></input>
                                {formHelper.errors.new_vendor_contact_email && formHelper.errors.new_vendor_contact_email.message}
                            </div>
                            {formHelper.errors.submit_error && formHelper.errors.submit_error.message}
                        </div>
                        
                        {formHelper.loading ? 
                            <Button variant="primary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                            </Button>
                        : 
                            <div className="button-group left">
                                <Button className="btn btn-secondary" onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button type="submit" className="btn btn-primary">
                                    Submit
                                </Button>
                            </div>
                        }
                    </form>
            </Modal.Body>
            <Modal.Footer>
                
            </Modal.Footer>
        </Modal>
    );
}