import React from 'react'

export default function SubmitInvoiceWelcome(props){
    return (
        <main role="main" class="col-10 ml-sm-auto">
            <div className="col-6 my-0 py-4">
                <p className="lead">You will be guided through submitting an invoice.  You will need a copy of the invoice. Click "Start" to get going...</p>
                <button className="btn btn-primary btn-lg" type="button" onClick={() => props.setStep(1)}>Start</button>
            </div>
        </main>
    )
}