import React, {useState,useEffect} from 'react'
import appConfig from '../../config/app.json'
import {Trash2} from 'react-feather'
import {Form,Button} from 'react-bootstrap'
import {CheckCircle, Disc, PlusCircle} from 'react-feather'
import {doesValueExist, checkAddress, checkZipCode} from '../../lib/fieldValidation'
import Select from 'react-select'
import states from '../../config/states.json'

export default function NVSPartThree(props){
    const {register, step, handleSubmit, setValue, errors, setError, clearError, values, setValues, setStep} = props

    function deleteLine(index){
        var tempLines = [...values.addresses]
        tempLines.splice(index, 1)
        setValues({...values, addresses: tempLines})
        //setAddresses(tempLines)
    }

    function addLine(){
        var tempLines = [...values.addresses]
        tempLines.push({"address_type": '', "address_attn": '', "address_address": "", "address_city": '', "address_state": "", "address_zip": ""})
        setValues({...values, addresses: tempLines})
    }

    function checkValue(event, index, target){
        var tempLines = [...values.addresses]
        tempLines[index][target] = event.target.value
        setValues({...values, addresses: tempLines})
    }

    function updateSelect(event, index, target){
        var tempLines = [...values.addresses]
        tempLines[index][target] = event
        setValues({...values, addresses: tempLines})
    }

    function checkStep(){
        var valid = true
        values.addresses.forEach((address, index) => {
            appConfig.address_fields.forEach((field) => {
                if(field.validation === 'exists' && !doesValueExist(address[field.key], `${field.key}${index}`, props)) return valid = false
                if(field.validation === 'address' && !checkAddress(address[field.key], `${field.key}${index}`, props)) return valid = false
                if(field.validation === 'zip' && !checkZipCode(address[field.key], `${field.key}${index}`, props)) return valid = false
            })
        })

        if(valid) {
            clearError()
            setStep(4)
            return true
        }

        return false
    }

    return (
        <div class="col-10 mr-auto ml-auto">
            <section className="progress-bullets">
                <ul>
                    <li className="complete">
                        <span>Step 1:</span> Name and Tax Information
                        <CheckCircle size={28} />
                    </li>
                    <li className="complete">
                        <span>Step 2:</span> Payment Information
                        <CheckCircle size={28} />
                    </li>
                    <li className="active">
                        <span>Step 3:</span> Address Information
                        <Disc size={28} />
                    </li>
                </ul>
            </section>
            <div className="tile">
            {step === 3 ? <div className="col-12">
                    <h1>Step 3: Address Information</h1>
                    <p>Please enter your primary and secondary physicial and mailing addresses.</p>
                </div>: ''}
                <form onSubmit={handleSubmit(checkStep)}>
                    <table className="table table-sm add-records addresses">
                        <thead>
                            {appConfig.address_fields.map((field) => {
                                if(!field.icon) return <th><label for="invoice_number">{field.name}</label></th>
                            })}
                            <th></th>
                        </thead>
                        <tbody>
                            {values.addresses ? values.addresses.map((address, index) => {
                                return (
                                    <tr>
                                        {appConfig.address_fields.map((field) => {
                                            if(field.dropdown && field.key === 'address_state') return (
                                                <td>
                                                    <Select value={address.address_state} onChange={(e) => updateSelect(e, index, field.key)} isSearchable isClearable options={Object.keys(states).map(state => {
                                                        return {label: states[state], value: state}
                                                    })}/>
                                                    <span className="error">{errors[`${field.key}${index}`] && errors[`${field.key}${index}`].message}</span>
                                                </td>
                                                // <td>
                                                //     <Form.Group onChange={(e) => checkValue(e, index, field.key)} name={`${field.key}${index}`}controlId="exampleForm.ControlSelect1">
                                                //         <Form.Control as="select">
                                                //             <option>Select...</option>
                                                //             <option>Mailing</option>
                                                //             <option>Remittance</option>
                                                //         </Form.Control>
                                                //     </Form.Group>
                                                //     <span className="error">{errors[`${field.key}${index}`] && errors[`${field.key}${index}`].message}</span>
                                                // </td>
                                            )

                                            if(field.dropdown && field.key != 'address_state'){
                                                return (
                                                    <td>
                                                        <Select value={address.address_type} onChange={(e) => updateSelect(e, index, field.key)} options={[{label: 'Mailing', value: 'mailing'},{label: 'Remit To', value: 'remit_to'}]}/>
                                                        <span className="error">{errors[`${field.key}${index}`] && errors[`${field.key}${index}`].message}</span>
                                                    </td>
                                                )
                                            }

                                            if(field.icon){
                                                return (
                                                    <td>
                                                        <Trash2 onClick={() => deleteLine(index)}/>
                                                    </td>
                                                )
                                            }

                                            return (
                                                <td>
                                                    <input type="text" onChange={(e) => checkValue(e, index, field.key)} className={errors[`${field.key}${index}`] ? "form-control is-invalid" : "form-control mt-3"} name={`${field.key}${index}`} id={`${field.key}${index}`} value={address[field.key]}></input>
                                                    <span className="error">{errors[`${field.key}${index}`] && errors[`${field.key}${index}`].message}</span>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            }): ''}
                        </tbody>
                    </table>
                    <div className="button-group">
                        {step !== 4 ? <Button className="bt btn-tertiary btn-icon-left btn-sm" onClick={() => addLine()}><PlusCircle />Add New Address</Button> : ''}
                        <br />
                        <br />
                        {step !== 4 ? <Button className="btn btn-secondary btn-lg" onClick={() => props.setStep(props.step-1)} type="button">Previous</Button> : ''}
                        {step !== 4 ? <Button className="btn btn-primary btn-lg" type="submit">Next</Button> : ''}
                    </div>
                </form>
            </div>
        </div>
    )
}