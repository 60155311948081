import React, {useState} from 'react'
import NonPolines from './NonPolines'
import PoLines from './PoLines'
import {CheckCircle, Circle, Disc, ArrowLeft, ArrowRight} from 'react-feather'
import appConfig from '../../config/app.json'
import {checkInvoiceTotals,checkNonPoLineData} from './SubmitInvoiceFunctions'

export default function SubmitInvoicePartThree(props){
    const {register, handleSubmit, setValue, errors, setError, clearError, setStep, values, getValues, setValues, step} = props

    var [isPo, setIsPo] = useState(null)
    var [totalSteps, setTotalSteps] = useState(0)

    if(isPo === null){
        if(values.po_number == '') setIsPo(false)
        else setIsPo(true)
    }
    
    function validateStep(){
        //validate line information
        if(!isPo){
            var valid = true
            if(valid) valid = checkNonPoLineData(props)
            if(valid) valid = checkInvoiceTotals(props)
        
            if(valid) {
                setStep(4)
                return
            }
        }
    }

    console.log(totalSteps);

    return(
        <main role="main" class="col-10 ml-sm-auto">
            <section className="progress-bullets">
            <ul className={props.totalSteps}>
                    {appConfig.submit_invoice_steps.map((submitStep) => {
                        return (
                            <li 
                                key={submitStep.step_number}
                                className={
                                    (step > submitStep.step_number && 'complete') ||
                                    (step == submitStep.step_number && 'active') ||
                                    'to-do'
                                }
                            >
                                <span>{submitStep.name_one}:</span> {submitStep.name_two}
                                {
                                    (step > submitStep.step_number && <CheckCircle size={28} />) ||
                                    (step == submitStep.step_number && <Disc size={28} />) ||
                                    <Circle size={28} />
                                }
                            </li>
                        )
                    })}
                </ul>
            </section>
            <div className="tile">
                <h1>Step 3: Invoice Details</h1>
                <p>Please enter an invoice line for all charges.</p>
                <form onSubmit={handleSubmit(validateStep)}>
                    {isPo ?
                        <PoLines/>
                    : ''}

                    {!isPo ?
                        <NonPolines setStep={setStep} register={register} handleSubmit={handleSubmit} setValue={setValue} errors={errors} getValues={getValues} setError={setError} clearError={clearError} setValues={setValues} values={values} step={step}/>
                    : ''}

                    {errors.step_three_validation ? <p className="error">{errors.step_three_validation.message}</p> : ''}
                    {errors.form_validation ? <input className="is-invalid submit-validation" value={errors.form_validation.message}></input> : ''}

                    {step !== 4 ?
                        <div class="button-group">
                            <button className="btn btn-secondary btn-lg btn-icon-left" onClick={() => props.setStep(2)}><ArrowLeft /> Previous</button>
                            <button className="btn btn-primary btn-lg btn-icon-right" type="submit">Next <ArrowRight /></button>
                        </div>
                    : ''}
                </form>
            </div>
        </main>
    )
}