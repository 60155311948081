import React, {useState,useEffect} from 'react'
import {Link} from 'react-router-dom'

export default function SubmitInvoiceSuccess(props){
    return (
        <main role="main" class="col-10 ml-sm-auto">
            <div className="tile">
                <h1>Invoice submitted successfully!</h1>
                <p>To submit a new invoice, <Link to="/submit" onClick={() => props.resetForm(props.setValues, props.setStep)}>click here</Link>.</p>
            </div>
        </main>
    )
}