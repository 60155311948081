import moment from 'moment'

export function getSessionItem(key, userData){
    //see if the key exists
    var keyData = sessionStorage.getItem(key)

    if(!keyData) return false

    var expires = JSON.parse(keyData).expires
    var userId = JSON.parse(keyData).id

    if(userId != userData.id){
        sessionStorage.removeItem(key)
        return false
    }

    if(moment().isSameOrAfter(expires)) {
        sessionStorage.removeItem(key)
        return false
    }

    return JSON.parse(keyData).data
}

export function setSessionItem(name, data, userData){
    var obj = {
        data: data,
        expires: moment().add(1, 'minute').format(),
        id: userData.id
    }
    try{
        sessionStorage.setItem(name, JSON.stringify(obj, null, 2))
    } catch(e) {
        throw e
    }
    
}