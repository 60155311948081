import React, {useState, useEffect} from 'react'
import Loading from '../components/Loading'
import useForm from 'react-hook-form'
import useVendorsHook from '../hooks/useVendorsHook'
import usePageOptions from '../hooks/usePageOptions'
import ViewVendorModal from '../components/Settings/ViewVendorModal'
import AddVendorModal from '../components/Settings/AddVendorModal'
import VendorSetupModal from '../components/Settings/VendorSetupModal'
import NewInvoiceModal from '../components/Settings/NewInvoiceModal'
import NewUserModal from '../components/Settings/NewUserModal'
import ViewUserModal from '../components/Settings/ViewUserModal'
import CancelWorkflowItemModal from '../components/Settings/CancelWorkflowItemModal'
import useWorkflowItemsHook from '../hooks/useWorkflowItemsHooks'
import {createNewUserRequest} from '../lib/mongoDbhelpers'
import {getAllUsers,deactivateUser} from '../lib/oktaAuthHelpers'
import {Modal,Button,Toast} from 'react-bootstrap'
import {PlusCircle,CheckSquare,XSquare, Eye, Codepen, Users, Settings, GitPullRequest, Edit, Trash2} from 'react-feather'
import appConfig from '../config/app.json'
import moment from 'moment'
import Select from 'react-select'
import {fieldValueFormatter} from '../lib/util'
import useUsersHook from '../hooks/useUsersHook'

import {Tab,Row,Nav,Col,InputGroup} from 'react-bootstrap'

export default function ClientSettings(props){
    const {errors, setError, getValues, setValue, register, handleSubmit, clearError} = useForm()
    const vendorsHook = useVendorsHook()
    const pageOptions = usePageOptions({idxStart: 0, idxEnd: 15})
    
    const [newVendorModal, setNewVendorModal] = useState(false)

    const users = useUsersHook()

    const [loading, setLoading] = useState(false)

    const [showVendorModal, setShowNewVendorModal] = useState(false)
    const [showSuccessToast, setShowSuccessToast] = useState(false)
    const [successStatus, setSuccessStatus] = useState(null)
    const [selectedVendor, setSelectedVendor] = useState(false)
    const [selectedUser, setSelectedUser] = useState(false)
    const [userError, setUserError] = useState(false)
    const [showNewUserModal, setShowNewUserModal] = useState(false)
    const workflowItems = useWorkflowItemsHook(false)

    const [modals, setModals] = useState({
        addVendorModal: {open: false},
        viewVendorModal: {open: false, data: false},
        deleteVendorSubmitModal: {open: false},
        viewVendorSetupModal: {open: false, data: false, edit: false},
        viewNewInvoiceModal: {open: false, data: false},
        newUserModal: {open: false},
        viewUserModal: {open: false, data: false},
        cancelWorkflowItemModal: {open: false, data: false}
    })

    const [showToast, setShowToast] = useState(false)

    const formHelper = {errors, setError, getValues, setValue, register, handleSubmit, loading, setLoading, clearError, setShowToast}

    if(!vendorsHook || !vendorsHook.vendors || !users.users) return <Loading/>

    function openWorkflowModal(item){
        if(item.type === 'New Vendor' && ['pending setup', 'cancelled'].includes(item.status)) setModals({...modals, viewVendorSetupModal: {open: true, data: item}})
        if(item.type === 'New Vendor' && item.status === 'pending approval') setModals({...modals, viewVendorModal: {open: true, data: item.setup_data}})
        if(item.type === 'New Invoice') setModals({...modals, viewNewInvoiceModal: {open: true, data: item}})
    }

    return (
        <main role="main" class="col-10 ml-sm-auto d-flex justify-content-between flex-wrap flex-md-nowrap">

            <div className="tile tab-tile">
                <Tab.Container id="left-tabs-example" defaultActiveKey="account-management">
                    <Nav className="flex-row">
                        <Nav.Item>
                            <Nav.Link eventKey="account-management"><Codepen /> Vendor Management</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="user-management"><Users /> User Management</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="application-management"><Settings /> System Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="workflow-items"><GitPullRequest /> Workflow Items</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="account-management">                        
                            <div className="row py-2">
                                <div className="col-12">
                                    <table className="table table-sm table-hover">
                                        <thead>
                                            <th>Vendor Number</th>
                                            <th>Vendor Name</th>
                                            <th>Contact Name</th>
                                            <th>Contact Phone</th>
                                            <th>Contact Email</th>
                                            <th>Status</th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            {vendorsHook.vendors && vendorsHook.vendors.map((vendor) => {
                                                return (
                                                    <tr onClick={() => setModals({...modals, viewVendorModal: {open: true, data: vendor}})}>
                                                        <td>{vendor.vendor_number}</td>
                                                        <td>{vendor.dba_name}</td>
                                                        <td>{vendor.primary_contact_name}</td>
                                                        <td>{vendor.primary_contact_number}</td>
                                                        <td>{vendor.primary_contact_email}</td>
                                                        <td>{`${vendor.vendor_status ? vendor.vendor_status.charAt(0).toUpperCase() : ''}${vendor.vendor_status.slice(1)}`}</td>
                                                        <td className='table-actions'>
                                                            {/* <button className='inline-action'><Edit/></button>
                                                            <button className='inline-action'><Trash2/></button> */}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <button
                                type="button" 
                                className={"View All" === 'test' ? "btn btn-sm btn-secondary btn-icon-left" + " active selected" : "btn btn-sm btn-tertiary btn-icon-left"} 
                                onClick={() => setModals({...modals, addVendorModal: {open: true}})}
                            >
                                <PlusCircle /> Add New Vendor
                            </button>
                        </Tab.Pane>
                        <Tab.Pane eventKey="user-management">
                            {/* <h1>User Management</h1> */}
                            {selectedUser && selectedUser.status === 'ACTIVE' ? <button 
                                type="button" 
                                className={"View All" === 'test' ? "btn btn-sm btn-outline-secondary mr-auto" + " active selected" : "btn btn-sm btn-secondary btn-icon-left"}
                                onClick={() => deactivateUser()}
                            >
                                <PlusCircle /> Deactivate User
                            </button> : ''}

                            {selectedUser && selectedUser.status !== 'ACTIVE' ? <button 
                                type="button" 
                                className={"View All" === 'test' ? "btn btn-sm btn-outline-secondary mr-auto" + " active selected" : "btn btn-sm btn-secondary btn-icon-left"}
                                onClick={() => deactivateUser()}
                            >
                                <PlusCircle /> Deactivate User
                            </button> : ''}
                            <div className="row py-2">
                                {userError ? <h6>{userError.errorMessage}</h6> : ''}
                                <div className="col-12">
                                    <table className="table table-sm">
                                        <thead>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Vendors</th>
                                            <th>Security</th>
                                            <th>User Status</th>
                                        </thead>
                                        <tbody>
                                            {users.users.map((user) => {
                                                return (
                                                    <tr className={user.id === selectedUser.id ? 'table-active': ''} onClick={() => setModals({...modals, viewUserModal: {open: true, data: user}})}>
                                                        <td>{user.profile.firstName} {user.profile.lastName}</td>
                                                        <td>{user.profile.email}</td>
                                                        <td>{user.vendors.join(",")}</td>
                                                        <td>{fieldValueFormatter({key: 'security_group', upper: true}, {"security_group": user.security_group})}</td>
                                                        <td>{user.status}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <button 
                                type="button" 
                                className={"View All" === 'test' ? "btn btn-sm btn-outline-secondary mr-auto" + " active selected" : "btn btn-sm btn-tertiary btn-icon-left"}
                                onClick={() => setModals({...modals, newUserModal: {open: true}})}
                            >
                                <PlusCircle /> Add New User
                            </button>
                        </Tab.Pane>
                        <Tab.Pane eventKey="application-management">
                            {/* <h1>System Settings</h1> */}
                        </Tab.Pane>
                        <Tab.Pane eventKey="workflow-items">
                            {/* <h1>Workflow Items</h1> */}
                            <div className="row py-2">
                                <div className="col-12">
                                    <table className="table table-sm table-hover">
                                        <thead>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Last Updated</th>
                                            <th>Key Data</th>
                                        </thead>
                                        <tbody>
                                            {workflowItems.workflowItems && workflowItems.workflowItems.map((item) => {
                                                return (
                                                    <tr onClick={() => openWorkflowModal(item)}>
                                                        <td>{item.type}</td>
                                                        <td>{fieldValueFormatter({key: "status", upper: true}, item)}</td>
                                                        <td>{moment(item.last_updated).format('MM/DD/YYYY HH:mm:ss')}</td>
                                                        <td>{Object.values(item.key_data).join(" - ")}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>

            <ViewVendorModal modals={modals} setModals={setModals} formHelper={formHelper} vendorsHook={vendorsHook} updateWorkflowItems={workflowItems.updateWorkflowItems}/>
            {AddVendorModal(modals, setModals, vendorsHook, formHelper, workflowItems.updateWorkflowItems)}
            {<VendorSetupModal modals={modals} setModals={setModals} vendorsHook={vendorsHook} formHelper={formHelper} updateWorkflowItems={workflowItems.updateWorkflowItems}/>}
            {<NewInvoiceModal modals={modals} userData={props.userData} setModals={setModals} invoiceHooks={props.invoiceHooks} formHelper={formHelper} updateWorkflowItems={workflowItems.updateWorkflowItems}/>}
            <NewUserModal vendorsHook={vendorsHook} modals={modals} userData={props.userData} setModals={setModals} formHelper={formHelper} usersHook={users} updateWorkflowItems={workflowItems.updateWorkflowItems}/>
            <ViewUserModal vendorsHook={vendorsHook} modals={modals} userData={props.userData} setModals={setModals} formHelper={formHelper} usersHook={users} updateWorkflowItems={workflowItems.updateWorkflowItems}/>
            <CancelWorkflowItemModal vendorsHook={vendorsHook} modals={modals} userData={props.userData} setModals={setModals} formHelper={formHelper} usersHook={users} updateWorkflowItems={workflowItems.updateWorkflowItems}/>


            {/* {addNewVendorModal(showNewVendorModal, setShowNewVendorModal, handleSubmit, error, successStatus, setError)}
            {viewVendorModal(showVendorModal, setShowVendorModal, selectedVendor, ApproveNewVendor, rejectNewVendor)}
            {addNewUserModal(showNewUserModal, setShowNewUserModal, formValues, setFormValues, vendors)}
            {viewVendorModal(showVendorModal, setShowVendorModal, selectedVendor, ApproveNewVendor, rejectNewVendor)} */}
            {/* {showSuccessToast ? submitSuccessToast({message: showSuccessToast.message}, showSuccessToast, setShowSuccessToast) : ''} */}
        </main>
    )
}