import React from 'react'
import {BrowserRouter,Switch,Route,Redirect} from 'react-router-dom'
import {ImplicitCallback} from '@okta/okta-react'
import NewVendorSetup from '../Pages/NewVendorSetup'
import ErrorPage from '../Pages/ErrorPage'

//Components
import MenuBarUnauthed from '../components/MenuBarUnauthed'
import HomePageUnauthed from '../components/HomePageUnauthed'

export default function Unauthenticated(props){
    if(!navigator.onLine) return <ErrorPage error={"You are not connected to the internet"}/>

    return (
        <BrowserRouter>
            <MenuBarUnauthed auth={props.auth}/>
            <Switch>
                <Route exact path="/" component={HomePageUnauthed} auth={props.auth}/>

                {/* --Okta Callback-- */}
                <Route path='/implicit/callback' component={ImplicitCallback}/>
                <Route path='/new-vendor' component={NewVendorSetup}/>
                <Route render={(props) => <Redirect to="/"/>}/>
            </Switch>
        </BrowserRouter>
    )
}