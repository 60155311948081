import React, {useState,useEffect} from 'react'
import {formatPrice} from '../../lib/util'

export default function InvoiceLines(props){
    const errors = props.errors

    return (
        <React.Fragment>
            <table className="table table-sm add-invoice-details">
                {/* This needs to be moved into a config file */}
                <thead>
                    <th>Line #</th>
                    <th>Item Description</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Extended Amount</th>
                </thead>
                <tbody>
                    {props.invoiceLines && props.invoiceLines.map((line, index) => {
                        return (
                            <tr>
                                <td className="line-number">
                                    {index+1}
                                </td>
                                <td className="item-description">
                                    {line['line_description']}
                                    {errors[`line_description${index}`] && errors[`line_description${index}`].message}
                                </td>
                                <td>
                                    {line['line_quantity']}
                                    {errors[`line_quantity${index}`] && errors[`line_quantity${index}`].message}
                                </td>
                                <td>
                                    {formatPrice(line['line_price'].toString().replace('.', ''))}
                                    {errors[`line_price${index}`] && errors[`line_price${index}`].message}
                                </td>
                                <td>
                                    {formatPrice(line[`line_extended_amount`].toString().replace('.', ''))}
                                    {errors[`line_extended_amount${index}`] && errors[`line_extended_amount${index}`].message}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </React.Fragment>
    )
}