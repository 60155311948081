import {Auth} from '@okta/okta-react'
import oktaConfig from '../config/okta.json'
import userList from '../config/users.json'
import {getUsers} from '../lib/mongoDbhelpers'

export async function checkAuthentication(auth, setAuthStatus){
    try{
        setAuthStatus(await auth.isAuthenticated())
    } catch(e) {
        setAuthStatus({errorCode: 10002, errorMessage: e.toString()})
    }
}

export async function getUserToken(auth){
    try {
        const token = await auth.getIdToken()
        return token
    } catch(e){
        return {errorCode: '101', errorMessage: 'Unable to get users token'}
    }
}

export async function getAllUsers(setUsers){
    try{
        var users = await getUsers()
        setUsers(users)
    } catch(e){
        setUsers(userList)
    }
}

export async function getUser(auth, setUser){
    setUser(await auth.getUser())
}

export async function logOut(auth){
    auth.logout()
    .catch(err => {
        window.location.replace(oktaConfig.logOutPath)
    })
}

export async function logIn(auth){
    await auth.login('/implicit/callback')
}

export async function deactivateUser(id){
    try {
        var results = await fetch(`https://dev-128370.okta.com/api/v1/users/${id}/lifecycle/deactivate`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `SSWS ${'00SV4N0OFwjfI3p_4sadByCpeCnjGlB-FZhZ1tR-z2'}`
        }})
        var resultBody = await results.text()
        if(!resultBody) return true
        else return {errorCode: '001', errorMessage: "There was an error processing this request."}
    } catch(e){
        return {errorCode: '001', errorMessage: "There was an error processing this request."}
    }
}

export async function activateUser(id){
    try {
        var results = await fetch(`https://dev-128370.okta.com/api/v1/users/${id}/lifecycle/activate?sendEmail=true`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": `SSWS ${'00SV4N0OFwjfI3p_4sadByCpeCnjGlB-FZhZ1tR-z2'}`
        }})
        var resultBody = await results.text()
        if(!resultBody) return true
        else return {errorCode: '001', errorMessage: "There was an error processing this request."}
    } catch(e){
        return {errorCode: '001', errorMessage: "There was an error processing this request."}
    }
}

export function createNewOktaUser(){
    
}

export function OktaAuth(){
    if(process.env.NODE_ENV === 'development'){
        return new Auth({
            issuer: oktaConfig.issuer,
            client_id: oktaConfig.client_id,
            redirect_uri: 'https://localhost:3000/implicit/callback'
        })
    }

    return new Auth({
        issuer: oktaConfig.issuer,
        client_id: oktaConfig.client_id,
        redirect_uri: 'https://www.fidlervp.com/implicit/callback'
    })
}