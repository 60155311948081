import React, {useState,useEffect} from 'react'
import {Modal,Button,Spinner} from 'react-bootstrap'
import {ApproveNewVendor} from '../../lib/mongoDbhelpers'
import {fieldValueFormatter} from '../../lib/util'
import Select from 'react-select'
import appConfig from '../../config/app.json'

export default function ViewVendorModal(props){
    const {modals, setModals, formHelper, vendorsHook, updateWorkflowItems} = props
    const handleClose = () => setModals({...modals, viewVendorModal: {open: false, data: false}})
    if(!modals.viewVendorModal.data) return false

    const vendorNumber = modals.viewVendorModal.data.vendor_number;
    let modalTitle;
    if(vendorNumber) {
        modalTitle = `Vendor Number: ${vendorNumber}`
    } else {
        modalTitle = `Vendor Information`
    }

    async function approveNewVendor(){
        props.formHelper.setLoading(true)
        var result = await ApproveNewVendor(modals.viewVendorModal.data)
        setTimeout(() => {
            if(result){
                setTimeout(() => {
                    vendorsHook.getAllVendors(vendorsHook.setVendors, vendorsHook.setLoading, vendorsHook.setVendorSetups, vendorsHook.setErrors)
                    updateWorkflowItems()
                    handleClose()
                },2000)
            }
        })
    }

    async function rejectNewVendor(){

    }
    
    return (
        <Modal size="lg" show={modals.viewVendorModal.open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="tile company-info">
                    <div class="form-row">
                        {modals.viewVendorModal.data.vendor_status === 'pending approval' ? 
                            <div class="col-md-3 mb-2 detail-group">
                                <label for="vendor_number">Vendor Number</label>
                                <Select options={[{value: 10005, label: 10005}]}></Select>
                            </div>
                        : ''}
                        {appConfig.company_name_fields.map((field) => {
                            if(field.key === 'vendor_number') {
                                return
                            }
                            return (
                                <div class="col-md-3 mb-2 detail-group">
                                    <label for={field.key}>{field.name}</label>
                                    <h6>{fieldValueFormatter(field, modals.viewVendorModal.data)}</h6>
                                </div>
                            )
                        })}
                    </div>
                    <h1>Contact Information</h1>
                    <div class="form-row">
                        <table className="table table-sm">
                            <thead>
                                {appConfig.company_contact_fields.map((field) => {
                                    return (
                                        <th>{field.name}</th>
                                    )
                                })}
                            </thead>
                            <tbody>
                            {modals.viewVendorModal.data.contact && modals.viewVendorModal.data.contact.length > 0 ? modals.viewVendorModal.data.contact.map((contact) => {
                                return(
                                    <tr>
                                        {appConfig.company_contact_fields.map((field) => {
                                            if(field.upper) return <td>{`${contact[field.key].charAt(0).toUpperCase()}${contact[field.key].slice(1)}`}</td>

                                            return (
                                                <td>{contact[field.key]}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            }): ''}
                            </tbody>
                        </table>
                    </div>
                    <h1>Address Information</h1>
                    <div class="form-row">
                        <table className="table table-sm">
                            <thead>
                                {appConfig.company_address_information.map((field) => {
                                    return <th>{field.name}</th>
                                })}
                            </thead>
                            <tbody>
                                {modals.viewVendorModal.data.addresses.map((address) => {
                                    return (
                                        <tr>
                                            {appConfig.company_address_information.map((field) => {
                                                if(field.upper) return <td>{`${address[field.key].charAt(0).toUpperCase()}${address[field.key].slice(1)}`}</td>
                                                return <td>{address[field.key]}</td>
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <h1>Payment Information</h1>
                    <div class="form-row">
                        <table className="table table-sm">
                            <thead>
                                {modals.viewVendorModal.data.payment_info && modals.viewVendorModal.data.payment_info.payment_type === 'credit'  && appConfig.credit_payment_info_needs.map((field) => {
                                    return <th>{field.name}</th>
                                })}

                                {modals.viewVendorModal.data.payment_info && modals.viewVendorModal.data.payment_info.payment_type === 'ach'  && appConfig.ach_payment_info_needs.map((field) => {
                                    return <th>{field.name}</th>
                                })}

                                {!modals.viewVendorModal.data.payment_info && modals.viewVendorModal.data.payment_type === 'ach'  && appConfig.ach_payment_info_needs.map((field) => {
                                    return <th>{field.name}</th>
                                })}

                                {!modals.viewVendorModal.data.payment_info && modals.viewVendorModal.data.payment_type === 'credit'  && appConfig.credit_payment_info_needs.map((field) => {
                                    return <th>{field.name}</th>
                                })}
                            </thead>
                            <tbody>
                                <tr>
                                    {modals.viewVendorModal.data.payment_info && modals.viewVendorModal.data.payment_info.payment_type === 'credit' ?
                                            appConfig.credit_payment_info_needs.map((field) => {
                                                try{
                                                    if(field.mask) return <td>{`XXXXXX${modals.viewVendorModal.data.payment_info[field.key].slice(6)}`}</td>
                                                    if(field.upperCase) return <td>{modals.viewVendorModal.data.payment_info[field.key].toUpperCase()}</td>
                                                    return <td>{modals.viewVendorModal.data.payment_info[field.key]}</td>
                                                } catch(e){
                                                    return null
                                                }
                                            })
                                        : ''}
                                    
                                    {modals.viewVendorModal.data.payment_info && modals.viewVendorModal.data.payment_info.payment_type === 'ach' ?
                                        appConfig.ach_payment_info_needs.map((field) => {
                                            try{
                                                if(field.mask) return <td>{`XXXXXX${modals.viewVendorModal.data.payment_info[field.key].slice(6)}`}</td>
                                                if(field.upperCase) return <td>{modals.viewVendorModal.data.payment_info[field.key].toUpperCase()}</td>
                                                return <td>{modals.viewVendorModal.data.payment_info[field.key]}</td>
                                            } catch(e){
                                                return null
                                            }
                                        })
                                    : ''}

                                    {!modals.viewVendorModal.data.payment_info && modals.viewVendorModal.data.payment_type === 'credit' ?
                                        appConfig.credit_payment_info_needs.map((field) => {
                                            try{
                                                if(field.mask) return <td>{`XXXXXX${modals.viewVendorModal.data[field.key].slice(6)}`}</td>
                                                if(field.upperCase) return <td>{modals.viewVendorModal.data[field.key].toUpperCase()}</td>
                                                return <td>{modals.viewVendorModal.data[field.key]}</td>
                                            } catch(e){
                                                return null
                                            }
                                        })
                                    : ''}
                                    {!modals.viewVendorModal.data.payment_info && modals.viewVendorModal.data.payment_type === 'ach' ?
                                        appConfig.ach_payment_info_needs.map((field) => {
                                            try{
                                                if(field.mask) return <td>{`XXXXXX${modals.viewVendorModal.data[field.key].slice(6)}`}</td>
                                                if(field.upperCase) return <td>{modals.viewVendorModal.data[field.key].toUpperCase()}</td>
                                                return <td>{modals.viewVendorModal.data[field.key]}</td>
                                            } catch(e){
                                                return null
                                            }
                                        })
                                    : ''}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {modals.viewVendorModal.data.vendor_status === 'pending approval' ?
                        <div className="form-row">
                            {formHelper.loading ? 
                                <Button variant="primary" disabled>
                                    <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                </Button>
                            : 
                                <React.Fragment>
                                    <Button className="btn-danger" onClick={() => rejectNewVendor()} className="mx-2">Reject</Button>
                                    <Button className="btn-success" onClick={() => approveNewVendor()} className="mx-2">Approve</Button>
                                </React.Fragment>
                            }
                            
                        </div>
                    : ''}
                </div>
            </Modal.Body>
        </Modal>
    );
}