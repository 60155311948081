import React from 'react'
import {Link,Redirect} from 'react-router-dom'
import {logOut} from '../lib/oktaAuthHelpers'
import parseUrl from 'query-string'

export default function MenuBarComponent(props){
    const location = window.location.pathname.replace('/', '');
    let pageTitle = location.charAt(0).toUpperCase() + location.slice(1);
    // If page has query string
    if (pageTitle.indexOf('?') > 0) {
        pageTitle = pageTitle.substring(0, pageTitle.indexOf('?'))
    } else if (pageTitle === "Submit") {
        pageTitle = "Add Invoices"
    } else if (pageTitle === "Info") {
        pageTitle = "Company Info"
    }
    pageTitle = pageTitle.replace(/#/g,'')


    return(
        <nav className="navbar fixed-top flex-md-nowrap p-0">
            <Link to={'/'} className='navbar-brand col-sm-3 col-md-2 mr-0'>Vendor Portal</Link>
            <h1 className="page-title">{pageTitle}</h1>
            <ul className="navbar-nav px-3">
                <li className="nav-item text-nowrap sign-in-out">
                    {props.userData.fullName} <span>|</span> 
                    <a onClick={() => logOut(props.auth)} href="#">Sign out</a>
                </li>
            </ul>
        </nav>
    )
}