import {isValidNumber,setToCurrency} from '../lib/util'
import currency from 'currency.js'

export function calculateTotal(values, key){
    var total = 0
    values.forEach((value) => {
        if(!key){
            if(isValidNumber(value)) return total += parseFloat(value)
            else return
        }

        if(key){
            if(isValidNumber(value[key])) return total += parseFloat(value[key])
            else return
        }
    })
    return total
}

export function toBeDistributedCalc(props){
    var toBeDistributed = parseFloat(props.values.invoice_amount)
    var lineAmount = calculateTotal(props.values.invoice_lines, 'line_extended_amount')
    var miscAmount = calculateTotal(props.values.misc_charges, 'charge_amount')
    toBeDistributed -= parseFloat(lineAmount)
    toBeDistributed -= parseFloat(miscAmount)
    toBeDistributed -= parseFloat(props.values.sales_tax)
    toBeDistributed -= parseFloat(props.values.freight_amount)
    return currency(toBeDistributed).toString()
}

export function calculateLineTotal(props, index){
    var [quantity, price] = [props.values.invoice_lines[index]['line_quantity'], props.values.invoice_lines[index]['line_price']]
    
    if(isNaN(quantity) || quantity == '') quantity = 0
    if(isNaN(price) || price == '') price = 0
    
    var total = parseFloat(quantity)*parseFloat(price)
    total = currency(total).toString()
    var tempLines = [...props.values.invoice_lines]
    tempLines[index]['line_extended_amount'] = total
    props.setValues({...props.values, invoice_lines: tempLines})
}