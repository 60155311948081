import React from 'react'

//Custom components for the form go here
export function miscChargeOptions(options, values){
    return (
        options.map((option) => {
            if(!values.misc_charges) return <option>{option.charge_name}</option>
            if(values.misc_charges.findIndex(a => a.charge_type === option.charge_type) > -1) return null
            return <option>{option.charge_name}</option>
        })
    )
}