import React, {useState,useEffect} from 'react'
import {Modal,Button,Tab,Row,Nav,Col,Spinner} from 'react-bootstrap'
import appConfig from '../../config/app.json'
import {Trash2,Edit} from 'react-feather'
import {SubmitNewVendorForm} from '../../lib/mongoDbhelpers'
import states from '../../config/states.json'
import Select from 'react-select'

export default function FinalReviewModal(props){
    const {register, handleSubmit, setValue, errors, setError, clearError, setStep, values, getValues, setValues, step} = props
    const [loading, setLoading] = useState(false)
    console.log(props.requestDetails)

    async function validateSetup(){
        //perform validations here
        
        //submit to mongodb for processing
        try{
            setLoading(true)
            var result = await SubmitNewVendorForm({...values}, props.requestDetails, props.userData)
            if(result){
                setTimeout(()=> {
                    setStep(5)
                },2000)
            }
        } catch(e){
            console.log(e)
        }
    }

    return (
        <Modal size="lg" show={step === 4 ? true: false} onHide={(e) => {setStep(3)}}>
            <Modal.Header closeButton>
                <Modal.Title>Please review your setup before submitting</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{paddingTop: '0'}}>
                <form className="submit-confirmation">
                    <section>
                        <p><strong>Name and Tax Information</strong></p>
                        <Button className="btn btn-secondary btn-xs btn-icon-left" onClick={() => setStep(1)}><Edit /> Edit</Button>
                        <div class="form-row">
                            {appConfig.vendor_setup_part_one.map((field) => {
                                if(field.dropdown){
                                    return (
                                        <div className="col-md-3 py-2">
                                            <label for="legal_name">{field.name}</label>
                                            <select value={values[field.key] || ''} name={field.key} id={field.key} className="form-control">
                                                {field.options.map((option) => {
                                                    return <option>{option}</option>
                                                })}
                                            </select>
                                            <span className="error">{errors[field.key] && errors[field.key].message}</span>
                                        </div>
                                    )
                                }
                                return (
                                    <div className="col-md-3 py-2">
                                        <label for="legal_name">{field.name}</label>
                                        <input type="text"  className={errors[field.key] ? "form-control is-invalid" : "form-control"} name={field.key} id={field.key} value={values[field.key]}></input>
                                        <span className="error">{errors[field.key] && errors[field.key].message}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </section>
                    <section>
                        <p><strong>Payment Information</strong></p>
                        <Button className="btn btn-secondary btn-xs btn-icon-left" onClick={() => setStep(2)}><Edit /> Edit</Button>
                        <div className="form-row">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <Row className="col-12">
                                    <Col sm={3}>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link active={props.values.payment_info.payment_type === 'credit' ? true : false}>Credit Card</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link active={props.values.payment_info.payment_type === 'ach' ? true : false}>ACH</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={9}>
                                        <Tab.Content>
                                            <Tab.Pane active={props.values.payment_info.payment_type === 'credit' ? true : false} eventKey="first">
                                                Credit Card payments require no additional processing time allowing you to receive your payment faster and with less hassle.  With each payment you will receive a notification and instructions to claim your funds.
                                            </Tab.Pane>
                                            <Tab.Pane active={props.values.payment_info.payment_type === 'ach' ? true : false} eventKey="second">
                                                {/* <Sonnet /> */}ACH (Automated Clearing House) requires an ABA and Routing number for your bank.  Payment processing per banking standards requires additional processing time.
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                        {/* Payment Info needs for Credit Cards */}
                        {values.payment_info.payment_type === 'credit' ? 
                            <div class="form-row py-4">
                                <React.Fragment>
                                    {appConfig.credit_payment_info_needs.map((field) => {
                                        return (
                                            <div className="col-md-3 py-2">
                                                <label for="legal_name">{field.name}</label>
                                                <input type="text" className={errors[field.key] ? "form-control is-invalid" : "form-control"} name={field.key} id={field.key} value={values.payment_info[field.key]}></input>
                                                {errors[field.key] && errors[field.key].message}
                                            </div>
                                        )
                                    })}
                                    <div className="col-md-3 checkbox-container">
                                        <input type="checkbox" checked={props.values.usePrimary} name="primary-contact-info"/>
                                        <label for="primary-contact-info">Use primary contact</label>
                                    </div>
                                </React.Fragment>
                            </div>
                        : ''}

                        {/* Payment Info needs for ACH */}
                        {values.payment_info.payment_type === 'ach' ? 
                            <div class="form-row py-4">
                                <React.Fragment>
                                    {appConfig.ach_payment_info_needs.map((field) => {
                                        return (
                                            <div className="col-md-3 py-2">
                                                <label for="legal_name">{field.name}</label>
                                                <input type="text" className={errors[field.key] ? "form-control is-invalid" : "form-control"} name={field.key} id={field.key} value={values.payment_info[field.key]}></input>
                                                {errors[field.key] && errors[field.key].message}
                                            </div>
                                        )
                                    })}
                                    <div className="col-md-3 checkbox-container">
                                        <input type="checkbox" checked={props.values.usePrimary}  name="primary-contact-info"/>
                                        <label for="primary-contact-info">Use primary contact</label>
                                    </div>
                                </React.Fragment>
                            </div>
                        : ''}
                    </section>
                    <section>
                        <p><strong>Address Information</strong></p>
                        <Button className="btn btn-secondary btn-xs btn-icon-left" onClick={() => setStep(3)}><Edit /> Edit</Button>
                        <table className="table table-sm add-records addresses">
                            <thead>
                                {appConfig.address_fields.map((field) => {
                                    if(!field.icon) return <th><label for="invoice_number">{field.name}</label></th>
                                })}
                                <th></th>
                            </thead>
                            <tbody>
                            {values.addresses ? values.addresses.map((address, index) => {
                                return (
                                    <tr>
                                        {appConfig.address_fields.map((field) => {
                                            if(field.dropdown && field.key === 'address_state') return (
                                                <td>
                                                    <Select value={address.address_state} isSearchable isClearable options={Object.keys(states).map(state => {
                                                        return {label: states[state], value: state}
                                                    })}/>
                                                    <span className="error">{errors[`${field.key}${index}`] && errors[`${field.key}${index}`].message}</span>
                                                </td>
                                            )

                                            if(field.dropdown && field.key != 'address_state'){
                                                return (
                                                    <td>
                                                        <Select value={address.address_type} options={[{label: 'Mailing', value: 'mailing'},{label: 'Remit To', value: 'remit_to'}]}/>
                                                        <span className="error">{errors[`${field.key}${index}`] && errors[`${field.key}${index}`].message}</span>
                                                    </td>
                                                )
                                            }

                                            if(field.icon){
                                                return (
                                                    <td>
                                                        {/* <Trash2 onClick={() => deleteLine(index)}/> */}
                                                    </td>
                                                )
                                            }

                                            return (
                                                <td>
                                                    <input type="text" className={errors[`${field.key}${index}`] ? "form-control is-invalid" : "form-control mt-3"} name={`${field.key}${index}`} id={`${field.key}${index}`} value={address[field.key]}></input>
                                                    <span className="error">{errors[`${field.key}${index}`] && errors[`${field.key}${index}`].message}</span>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            }): ''}
                            </tbody>
                        </table>
                    </section>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {loading ? 
                    <Button variant="primary" disabled>
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                    </Button>
                : 
                    <React.Fragment>
                        <Button type="submit" className="btn btn-primary" onClick={() => validateSetup()}>
                            Submit
                        </Button>
                    </React.Fragment>
                }
            </Modal.Footer>
        </Modal>
    )
}