import React, {useState,useEffect} from 'react'
import {GetCompanyInfo} from '../lib/mongoDbhelpers'

export function useGetCompanyInfo(userData){
    const [companyInfo, setCompanyInfo] = useState(null)
    const [loading, setLoading] = useState(false)

    if((!companyInfo || companyInfo === null) && !loading){
        GetCompanyInfo(setCompanyInfo, setLoading, userData)
        setLoading(true)
    }

    return companyInfo
}