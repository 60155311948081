import React from 'react'
import {Link} from 'react-router-dom'

export default function PageNotFound(props){
    return (
        <main role="main" class="col-10 ml-sm-auto">
            <div class="jumbotron">
                <p class="lead">Whoops! This page doesn't exist.  Please use one of the options in the sidebar or click the button below to return to the home page.</p>
                <button class="btn btn-primary btn-lg" onClick={() => props.history.push('/')}>Home Page</button>
            </div>
        </main>
    )
}