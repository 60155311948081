import React, {useState, useEffect} from 'react'
import appConfig from '../../config/app.json'
import {Circle, CheckCircle, Disc} from 'react-feather'
import {checkBusinessName, checkTaxId,checkEmailAddress,checkPhoneNumber} from '../../lib/fieldValidation'

export default function NVSPartOne(props){
    const {register, step, handleSubmit, setValue, errors, setError, clearError, values, setValues, setStep} = props

    function validateStep(formValues){
        var valid = true
        appConfig.vendor_setup_part_one.forEach((field) => {
            if(field.validation === 'legal_name') if(!checkBusinessName(values[field.key], field.key, props, true)) valid = false
            if(field.validation === 'tax_id') if(!checkTaxId(values[field.key], field.key,props, true)) valid = false
            if(field.validation === 'email') if(!checkEmailAddress(values[field.key], field.key,props, true)) valid = false
            if(field.validation === 'phone') if(!checkPhoneNumber(values[field.key], field.key, props, true)) valid = false
            if(field.key === 'vendor_class' && !values[field.key]) {
                props.setError('vendor_class', 'missing_value', 'Please select a vendor class')
                valid = false
            } 
        })

        if(valid) {
            props.setError()
            setStep(2)
            return true
        }

        return false
    }

    function validateField(event, validation){
        if(validation === 'legal_name') return checkBusinessName(event.target.value, event.target.name, props, false)
        if(validation === 'tax_id') return checkTaxId(event.target.value, event.target.name, props, false)
        if(validation === 'email') return checkEmailAddress(event.target.value, event.target.name, props, false)
        if(validation === 'phone') return checkPhoneNumber(event.target.value, event.target.name, props, false)
        if(event.target.name === 'vendor_class'){
            props.setValues({...props.values, vendor_class: event.target.value})
            props.clearError(event.target.name)
        }
    }

    return (
        <div class="col-10 mr-auto ml-auto">
            <section className="progress-bullets">
                <ul>
                    <li className="active">
                        <span>Step 1:</span> Name and Tax Information
                        <Disc size={28} />
                    </li>
                    <li>
                        <span>Step 2:</span> Payment Information
                        <Circle size={28} />
                    </li>
                    <li>
                        <span>Step 3:</span> Address Information
                        <Circle size={28} />
                    </li>
                </ul>
            </section>
            <div className="tile">
                {step !== 4 ? <div className="col-12">
                    <h1>Part 1: Company Name and Tax Information</h1>
                    <p>Please enter your companies doing business as name, legal name, and tax information.</p>
                </div>: ''}
                <form onSubmit={handleSubmit(validateStep)} className="py-2">
                    <div class="form-row">
                        {appConfig.vendor_setup_part_one.map((field) => {
                            if(field.dropdown){
                                return (
                                    <div className="col-md-3 py-2">
                                        <label for="legal_name">{field.name}</label>
                                        <select value={values[field.key] || ''} name={field.key} id={field.key} onChange={(e) => validateField(e)} className="form-control">
                                            {field.options.map((option) => {
                                                return <option>{option}</option>
                                            })}
                                        </select>
                                        <span className="error">{errors[field.key] && errors[field.key].message}</span>
                                    </div>
                                )
                            }
                            return (
                                <div className="col-md-3 py-2">
                                    <label for="legal_name">{field.name}</label>
                                    <input type="text" onChange={(e) => validateField(e, field.validation)} className={errors[field.key] ? "form-control is-invalid" : "form-control"} name={field.key} id={field.key} value={values[field.key]}></input>
                                    <span className="error">{errors[field.key] && errors[field.key].message}</span>
                                </div>
                            )
                        })}
                    </div>
                    
                    {step !== 4 ?
                        <div class="form-row my-4">
                            <div class="col-md-1">
                                <input className="btn btn-primary btn-lg" type="submit" value="Next"/>
                            </div>
                        </div>
                    : ''}
                </form>
            </div>
        </div>
    )
}