import React, {useState, useEffect} from 'react'
import {deleteMiscLine,addNewMiscLine,checkMiscLine} from './SubmitInvoiceFunctions'
import {miscChargeOptions} from './SubmitInvoiceComponents'
import {checkAmount,isAmountGreaterThan} from '../../lib/fieldValidation'
import {calculateTotal} from '../../lib/fieldCalculations'
import {setToCurrency} from '../../lib/util'
import appConfig from '../../config/app.json'
import {CheckCircle, Circle, Disc, PlusCircle, ArrowLeft, ArrowRight,Trash2,Trash} from 'react-feather'

export default function SubmitInvoicePartTwo(props){
    const {register, handleSubmit, setValue, errors, setError, clearError, setStep,step, values, setValues} = props

    function validateStep(){
        var valid = true
        valid = checkAmount('invoice_amount', props, true)
        if(valid) valid = checkAmount('sales_tax', props, true)
        if(valid) valid = checkAmount('freight_amount', props, true)
        if(valid) valid = isAmountGreaterThan([values.invoice_amount, calculateTotal([calculateTotal([values.freight_amount, values.sales_tax]), calculateTotal(values.misc_charges, 'charge_amount')])], props, 'step_two_validation')

        if(valid) setStep(3)
    }

    return(
        <main role="main" class="col-10 ml-sm-auto">
            <section className="progress-bullets">
                <ul>
                    {appConfig.submit_invoice_steps.map((submitStep) => {
                        return (
                            <li 
                                key={submitStep.step_number}
                                className={
                                    (step > submitStep.step_number && 'complete') ||
                                    (step == submitStep.step_number && 'active') ||
                                    'to-do'
                                }
                            >
                                <span>{submitStep.name_one}:</span> {submitStep.name_two}
                                {
                                    (step > submitStep.step_number && <CheckCircle size={28} />) ||
                                    (step == submitStep.step_number && <Disc size={28} />) ||
                                    <Circle size={28} />
                                }
                            </li>
                        )
                    })}
                </ul>
            </section>
            <div className="tile">
                <h1>Step 2: Invoice Amounts</h1>
                <p>Please enter an Invoice Amount and other related charges.</p>
                <form onSubmit={handleSubmit(validateStep)}>
                    <div class="form-row">
                        <div class="form-group col-md-4">
                            <label for="invoice_number">Invoice Amount</label>
                            <input type="text" required={true} onChange={(e) => checkAmount(e, props, false)} onBlur={(e) => setToCurrency(props, e.target.value, e.target.name)} className={errors.invoice_amount ? "form-control is-invalid" : "form-control"} name="invoice_amount" id="invoice_amount" value={values.invoice_amount}></input>
                            <span className="error">{errors.invoice_amount && errors.invoice_amount.message}</span>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="invoice_number">Sales Tax</label>
                                <input type="text" onChange={(e) => checkAmount(e, props, false)} onBlur={(e) => setToCurrency(props, e.target.value, e.target.name)} className={errors.sales_tax ? "form-control is-invalid" : "form-control"} name="sales_tax" id="sales_tax" value={values.sales_tax}></input>
                                <span className="error">{errors.sales_tax && errors.sales_tax.message}</span>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="invoice_number">Freight Amount</label>
                                <input type="text" onChange={(e) => checkAmount(e, props, false)} onBlur={(e) => setToCurrency(props, e.target.value, e.target.name)} className={errors.freight_amount ? "form-control is-invalid" : "form-control"} name="freight_amount" id="freight_amount" value={values.freight_amount}></input>
                                <span className="error">{errors.freight_amount && errors.freight_amount.message}</span>
                        </div>
                    </div>
                    <div class="form-row one">
                        {step === 4 && values.misc_charges.length !== 0 ? <p className="lead font-weight-bold">Miscellaneous Charges</p> : ''}
                    </div>
                    <div className="form-row misc-charges tile-secondary">
                        <h2>Miscellaneous Charges</h2>
                        {values.misc_charges ? values.misc_charges.map((charge, index) => {
                            return (
                                <div class="input-group">
                                    <select onChange={(e) => checkMiscLine(props, e.target.value, index, e, 'charge_type')} value={charge.charge_type} class="form-control" id={`charge_type${index}`}>
                                        {miscChargeOptions(appConfig.misc_charge_fields, values)}
                                    </select>
                                    <input onChange={(e) => checkMiscLine(props, e.target.value, index, e, 'charge_amount')} name="charge_amount" onBlur={(e) => setToCurrency(props, e.target.value, e.target.name, {name: 'misc_charges', index: index})} type="text" class="form-control col-md-2 newMiscLineAmount" value={charge.charge_amount}></input>
                                    <Trash2 onClick={() => deleteMiscLine(props, index)}/>
                                </div>
                            )
                        }): ''}
                        <button onClick={() => addNewMiscLine(props)} type="button" className="btn btn-tertiary btn-icon-left btn-sm"><PlusCircle /> Add another charge</button>
                    </div>

                    {step !== 4 ?
                        <div className="button-group">
                            <button className="btn btn-secondary btn-lg btn-icon-left" onClick={() => props.setStep(1)}><ArrowLeft /> Previous</button>
                            <button className="btn btn-primary btn-lg btn-icon-right" type="submit">Next <ArrowRight /></button>
                        </div>
                    : ''}
                    {errors.step_two_validation ? <p className="error">{errors.step_two_validation.message}</p> : ''}
                </form>
            </div>
        </main>
    )
}