import React, {useState,useEffect} from 'react'
import {GetAllUsers} from '../lib/mongoDbhelpers'

export default function useUsersHook(){ 
    const [users, setUsers] = useState(null)
    const [userSetups, setUserSetups] = useState(null)
    const [errors, setErrors] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(users === null && !loading) {
            getAllUsers(setUsers, setLoading, setErrors)
            setLoading(true)
        }
    })

    function refreshUserList(){
        getAllUsers(setUsers, setLoading, setErrors)
    }

    return {users: users, refresh: refreshUserList}
}

async function getAllUsers(setUsers, setLoading, setErrors){
    var results = await GetAllUsers()
    if(results.errorCode){
        setErrors({errorCode: '011', errorMessage: results.errorMessage})
    }

    setUsers(results)
    setLoading(false)
}