import React, {useState,useEffect} from 'react'
import {parseUrl} from 'query-string'
import {GetInvoices,GetInvoiceDetailsByNumber} from '../lib/mongoDbhelpers'
import {getSessionItem} from '../lib/sessionStorageHelper'

export function useGetInvoicesHook(userData, refreshCache){
    const [invoices, setInvoices] = useState(null)
    const [loading, setLoading] = useState(false)
    
    if(!userData) return null

    var invoicesInSession = null
    if(!refreshCache) invoicesInSession = getSessionItem('invoices', userData)
    if(invoicesInSession && !invoices) setInvoices(invoicesInSession)
    if((userData && invoices === null && !loading && !invoicesInSession) || refreshCache) {
        GetInvoices(setInvoices, setLoading, userData)
        setLoading(true)
    }

    function refreshInvoices(){
        GetInvoices(setInvoices, setLoading, userData)
    }

    return {invoices: invoices, refresh: refreshInvoices}
}

export function useGetInvoiceByUrlHook(key, invoiceList){
    const [invoice, setInvoice] = useState(null)

    //parse the url
    var urlQuery = parseUrl(window.location.search)
    
    useEffect(() => {
        if(Object.keys(urlQuery.query).length === 0 && invoice) setInvoice(null)
        if(Object.keys(urlQuery.query).length > 0 && invoice === null) GetInvoiceDetails(urlQuery, key)

        async function GetInvoiceDetails(urlQuery, key){
            var lookupValue = urlQuery.query[key]
            try{
                var invoiceDetails = await GetInvoiceDetailsByNumber(lookupValue)
                if(!invoiceDetails && invoice) setInvoice(null)
                if(invoiceDetails && invoice === null) setInvoice(invoiceDetails)
                if(invoiceDetails && invoice && invoice.invoice_number !== invoiceDetails.invoice_number) setInvoice(invoiceDetails)
            } catch(e){
                console.log(e)
            }
            
        }
    })

    return invoice
}