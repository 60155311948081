import React, {useState,useEffect} from 'react'
import {Modal,Spinner,Button} from 'react-bootstrap'
import {CancelNewVendorSetup} from '../../lib/mongoDbhelpers'
import {fieldValueFormatter} from '../../lib/util'
import Select from 'react-select'
import moment from 'moment'

export default function VendorSetupModal(props){
    const [cancelMode, setCancelMode] = useState(false)
    const [formData, setFormData] = useState({cancelMode: {}})
    const {modals, setModals, vendorsHook, formHelper} = props

    const handleClose = () => setModals({...modals, viewVendorSetupModal: {open: false, data: false}})

    async function processResendEmail(){
        formHelper.setLoading(true)
        var result = await vendorsHook.ResendSetupEmail(modals.viewVendorSetupModal.data._id)
        if(result === true){
            formHelper.setLoading(false)
            formHelper.clearError()
            formHelper.setShowToast(true)
            handleClose()
            vendorsHook.getAllVendors(vendorsHook.setVendors, vendorsHook.setLoading, vendorsHook.setVendorSetups, vendorsHook.setErrors)
        }
    }

    async function cancelNewVendorSetup(){
        props.formHelper.clearError()
        if(!formData.cancelMode.reason) {
            props.formHelper.setError('cancel_error', 'missing_value', "please select a reason...")
            return false
        }

        props.formHelper.setLoading(true)
        var result = await CancelNewVendorSetup(props.modals.viewVendorSetupModal.data._id, formData.cancelMode.reason.value)
        if(result.error && result.error.errorCode){
            var errors = result.error.errorCauses.map(a => a.errorSummary)
            props.formHelper.setError('cancel_error', 'function_failed', errors.join('~'))
            props.formHelper.setLoading(false)
            return false
        } else if (result.errorCode){
            props.formHelper.setError('cancel_error', 'function_failed', result.errorMessage)
            props.formHelper.setLoading(false)
            return false
        } else {
            props.formHelper.setLoading(false)
            props.formHelper.clearError()
            props.formHelper.setShowToast(true)
            props.setModals({...props.modals, viewVendorSetupModal: {open: true, data: result}})
            props.updateWorkflowItems()
        }

    }

    if(cancelMode) return cancelRequest()
    return PendingVendorAction()

    function PendingVendorAction(){
        return(
            <Modal size="lg" show={modals.viewVendorSetupModal.open} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Vendor Setup Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tile company-info">
                        <div class="form-row">
                            <div class="col-md-6 mb-2 detail-group">
                                <label for='contact_name'>Contact Name</label>
                                <h6>{modals.viewVendorSetupModal.data['contact_name']}</h6>
                            </div>
                            <div class="col-md-6 mb-2 detail-group">
                                <label for='contact_name'>Contact Email</label>
                                <h6>{modals.viewVendorSetupModal.data['contact_email']}</h6>
                            </div>
                            <div class="col-md-6 mb-2 detail-group">
                                <label for='contact_name'>Created</label>
                                <h6>{moment(modals.viewVendorSetupModal.data['created']).format('MM/DD/YYYY HH:mm')}</h6>
                            </div>
                            <div class="col-md-6 mb-2 detail-group">
                                <label for='contact_name'>Status</label>
                                <h6>{fieldValueFormatter({key: 'status', upper: true}, {'status': modals.viewVendorSetupModal.data['status']})}</h6>
                            </div>
                            {modals.viewVendorSetupModal.data['status'] === 'cancelled' ? 
                                <div class="col-md-3 mb-2 detail-group">
                                    <label for='contact_name'>Cancelled On</label>
                                    <h6>{moment(modals.viewVendorSetupModal.data['cancel_date']).format("MM/DD/YYYY HH:mm")}</h6>
                                </div>
                            : ''}
                        </div>
                        <span className="error">{props.formHelper.errors.cancel_error && props.formHelper.errors.cancel_error.message}</span>
                            {modals.viewVendorSetupModal.data['status'] == 'pending setup' ?
                                <React.Fragment>
                                        {formHelper.loading ? 
                                            <Button variant="primary" disabled>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </Button>
                                        :
                                            <div className="button-group left">
                                                <Button onClick={() => processResendEmail()} className="btn btn-secondary btn-sm">
                                                    Resend Setup Email
                                                </Button>
                                                <Button onClick={() => setCancelMode(true)} className="btn btn-secondary btn-sm">
                                                    Cancel Setup
                                                </Button>
                                            </div>
                                        }
                                </React.Fragment>
                            : ''}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    function cancelRequest(){
        return (
            <Modal show={modals.viewVendorSetupModal.open} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Please select a reason for cancelling...</label>
                    <Select onChange={(e) => setFormData({...formData, cancelMode: {reason: e}})} options={[{value: "invalid_address", label: "Invalid Email"},{value: "Expired", label: "expired"}]}></Select>
                    <Button onClick={() => setCancelMode(false)} className="btn btn-primary">
                        No
                    </Button>
                    <Button onClick={() => cancelNewVendorSetup()} className="btn btn-primary">
                        Yes
                    </Button>
                    <span className="error">{props.formHelper.errors.cancel_error && props.formHelper.errors.cancel_error.message}</span>
                </Modal.Body>
            </Modal>
        )
    }
}