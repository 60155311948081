import {parseUrl} from 'query-string'
import moment from 'moment'
import currency from 'currency.js'

export function doesValueExistInArray(list, value, key){
    var index = null
    if(key) index = list.findIndex(a => a[key] == value)
    else index = list.findIndex(a => a == value)

    if(index > -1) return true
    return false
}

export function formatPrice(cents) {
    return (cents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  }

export function setToCurrency(props, value, name, arrayBased){
    if(arrayBased){
        var tempArray = [...props.values[arrayBased.name]]
        tempArray[arrayBased.index][name] = currency(value).toString()
        props.setValues({...props.values, [arrayBased.name]: tempArray})
        return true
    }
    props.setValues({...props.values, [name]: currency(value).toString()})
}

export function isValidNumber(amount){
    if(isNaN(amount) && isNaN(Number(amount))) return false
    return true
}

export function createSelectFields(fieldList, key){
    var fieldsToSelect = {}
    fieldList.forEach((field) => {
        fieldsToSelect[field.key] = 1
    })
    return fieldsToSelect
}

export function getUrlParam(key){
    var parsedUrl = parseUrl(window.location.search)

    if(Object.keys(parsedUrl.query).length === 0) {
        return false
    }

    if(Object.keys(parsedUrl.query).length > 0) {
        return parsedUrl.query[key]
    }
}

export function setBackUrl(location){
    if(location.state){
        return `${location.state.path}${location.state.params}`
    }
    return `/${location.pathname}`
}

export function setBackButtonTest(location){
    if(!location.state) return null
    return `${location.state.path.replace('/','').charAt(0).toUpperCase()}${location.state.path.replace('/','').slice(1)}`
}

export function fieldValueFormatter(fieldObj, valueObj){ //This function modifies the source value (database) to the expected value or action (i.e. don't render nulls)

    if(!valueObj[fieldObj.key] || !fieldObj.key || fieldObj.hidden) return ''
    if(fieldObj.date) return moment(valueObj[fieldObj.key]).format(fieldObj.format)
    if(fieldObj.upper) return `${valueObj[fieldObj.key].charAt(0).toUpperCase()}${valueObj[fieldObj.key].slice(1)}`
    if(fieldObj.amount) return `${formatPrice(valueObj[fieldObj.key].toString().replace('.', ''))}`
    if(fieldObj.length) return valueObj[fieldObj.key].length
    if(fieldObj.mask) return `XXXXXX${valueObj[fieldObj.key].slice(valueObj[fieldObj.key].length-4)}`
    return valueObj[fieldObj.key]
}

export function getFieldInfo(event, props, arrayBased){
    var [value, name, required] = []

    //We check the typeof event to allow use of onkey and onclick events that don't have access to the same information
    if(typeof(event) === 'object'){
        [value, name, required] = [event.target.value, event.target.name, event.target.required]
    }

    if(typeof(event) === 'string'){ //assume we pass in the field name/index, must acquire required status
        if(arrayBased) value = props.values[arrayBased.name][arrayBased.index][event]
        else value = props.values[event]
        
        name = event
        
        if(arrayBased) required = document.getElementById(`${event}${arrayBased.index}`).required
        else required = document.getElementById(event).required
    }

    return [value, name, required]
}

export function deriveFieldName(name){
    if(typeof(name) === 'object') return name.key
    return name
}