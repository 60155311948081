import appConfig from '../../config/app.json'
import {SubmitNewInvoice} from '../../lib/mongoDbhelpers'
import {doesValueExistInArray} from '../../lib/util'
import {calculateLineTotal, calculateTotal } from '../../lib/fieldCalculations'
import {checkAmount,checkItemDescription, isAmountGreaterThan, isAmountLessThan} from '../../lib/fieldValidation'
import _ from 'lodash'

export function ResetForm(setValues, setStep){
    setValues(appConfig.submit_invoice_structure)
    setStep(appConfig.submit_invoice_start_screen)
}

export function ValidateInvoice(){
    return true
}

export async function SubmitInvoice(values){
    await SubmitNewInvoice(values)
}

//Step 2 Functions
export function deleteMiscLine(props, index){
    var tempArray = [...props.values.misc_charges]

    if(props.values.misc_charges.length === 1){//only remove the values, don't get rid of the line itself
        tempArray[0].charge_amount = "0.00"
        tempArray[0].charge_type = ''
    } else {
        tempArray.splice(index, 1)
    }
    props.setValues({...props.values, misc_charges: tempArray})
}

export function addNewMiscLine(props){
    var tempArray = [...props.values.misc_charges]
    tempArray.push({"charge_type": '', "charge_amount": "0.00"})
    props.setValues({...props.values, misc_charges: tempArray})
}

export function checkMiscLine(props, value, index, event, target){
    //ensure the charge type doesn't already exist
    if(target === 'charge_type' && doesValueExistInArray(props.values.misc_charges, value, 'charge_type')){
        alert('A line with this charge already exists.')
        return
    }

    //push to state
    var tempMiscCharges = [...props.values.misc_charges]
    tempMiscCharges[index][target] = value
    props.setValues({...props.values, misc_charges: tempMiscCharges})
}

//Step 3 Functions
export function addLine(props){
    var tempLines = _.cloneDeep(props.values.invoice_lines)
    var newLine = _.clone(appConfig.submit_invoice_structure.invoice_lines[0])
    newLine.line_number = props.values.invoice_lines.length+1
    tempLines.push(newLine)
    props.setValues({...props.values, invoice_lines: tempLines})
}

export function deleteLine(props, index){
    var tempLines = _.cloneDeep(props.values.invoice_lines)
    tempLines.splice(index,1)
    props.setValues({...props.values, invoice_lines: tempLines})
}

export function checkInvoiceTotals(props){
    var miscChargeTotal = calculateTotal(props.values.misc_charges, 'charge_amount')
    var invoiceLineTotal = calculateTotal(props.values.invoice_lines, 'line_extended_amount')
    var otherCharges = calculateTotal([props.values.sales_tax, props.values.freight_amount])
    
    if(!isAmountGreaterThan([props.values.invoice_amount, calculateTotal([miscChargeTotal, invoiceLineTotal, otherCharges])], props, 'step_three_validation')) return false

    if(!isAmountLessThan([props.values.invoice_amount, calculateTotal([miscChargeTotal, invoiceLineTotal, otherCharges])], props, 'step_three_validation')) return false

    props.clearError('form_validation')

    return true
}

export function checkNonPoLineData(props){
    var status = true
    props.values.invoice_lines.forEach((line, index) => {
        if(!checkItemDescription(props, 'line_description', {name: "invoice_lines", index: index}, true)) status = false
        if(!checkAmount('line_price', props, true, {name: "invoice_lines", index: index})) status = false
        if(!checkAmount('line_quantity', props, true, {name: "invoice_lines", index: index})) status = false
    })
    return status
}