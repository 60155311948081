import React, {useState} from 'react'

export default function usePageOptions(defaultOptions){
    const [options, setOptions] = useState(defaultOptions)

    function handlePageIncrement(increment, maxLength){
        var nextStart = options.idxStart + increment
        var nextEnd = options.idxEnd + increment

        if(nextEnd > maxLength){
            nextEnd = maxLength
        }

        if(increment < 0 && options.idxEnd === maxLength){
            //only increment by the current difference
            nextEnd = options.idxEnd - (options.idxEnd-options.idxStart)
        }

        setOptions({...options, idxStart: nextStart, idxEnd: nextEnd})
    }

    return {options, setOptions, handlePageIncrement}
}