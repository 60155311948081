import React,{useState,useEffect} from 'react'
import useForm from 'react-hook-form'
import appConfig from '../config/app.json'
import {useGetCompanyInfo} from '../hooks/useCompanyHook'
import Loading from '../components/Loading'

export default function CompanyInfo(props){
    const {register} = useForm()
    const [readOnly, setReadOnly] = useState(true)
    const companyInfo = useGetCompanyInfo(props.userData)
    const [selectedCompany, setSelectedCompany] = useState(null)

    useEffect(() => {
        if(companyInfo !== null && selectedCompany === null){
            setSelectedCompany(companyInfo[0])
        }
    })

    if(companyInfo === null || companyInfo.length === 0 || selectedCompany === null) return <Loading/>

    return (
        <main role="main" class="col-10 ml-sm-auto">
            <div className="tile company-info">
                    <h1>Company Information</h1>
                    {companyInfo.length > 1 ? 
                        <select onChange={(e) => setSelectedCompany(companyInfo[e.target.value])}>
                            {companyInfo.map((company, index) => {
                                return <option value={index}>{company.dba_name}</option>
                            })}
                        </select>
                    : ''}
                    <div class="form-row">
                        {appConfig.company_name_fields.map((field) => {
                            if(field.upper){
                                return (
                                    <div class="col-md-3 mb-3 detail-group">
                                        <label for={field.key}>{field.name}</label>
                                        <h6 ref={register}>{`${selectedCompany[field.key].charAt(0).toUpperCase()}${selectedCompany[field.key].slice(1)}`}</h6>
                                    </div>
                                )
                            }

                            return (
                                <div class="col-md-3 mb-3 detail-group">
                                    <label for={field.key}>{field.name}</label>
                                    <h6 ref={register}>{selectedCompany[field.key]}</h6>
                                </div>
                            )
                        })}
                    </div>
                    <h1>Contact Information</h1>
                    <div class="form-row">
                        <table className="table table-sm">
                            <thead>
                                {appConfig.company_contact_fields.map((field) => {
                                    return (
                                        <th>{field.name}</th>
                                    )
                                })}
                            </thead>
                            <tbody>
                            {selectedCompany.contact.map((contact) => {
                                return(
                                    <tr>
                                        {appConfig.company_contact_fields.map((field) => {
                                            if(field.upper) return <td>{`${contact[field.key].charAt(0).toUpperCase()}${contact[field.key].slice(1)}`}</td>

                                            return (
                                                <td>{contact[field.key]}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    <h1>Address Information</h1>
                    <div class="form-row">
                        <table className="table table-sm">
                            <thead>
                                {appConfig.company_address_information.map((field) => {
                                    return <th>{field.name}</th>
                                })}
                            </thead>
                            <tbody>
                                {selectedCompany.addresses.map((address) => {
                                    return (
                                        <tr>
                                            {appConfig.company_address_information.map((field) => {
                                                if(field.upper) return <td>{`${address[field.key].charAt(0).toUpperCase()}${address[field.key].slice(1)}`}</td>
                                                return <td>{address[field.key]}</td>
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <h1>Payment Information</h1>
                    <div class="form-row">
                        <table className="table table-sm">
                            <thead>
                                {selectedCompany.payment_info && selectedCompany.payment_info.payment_type === 'credit'  && appConfig.credit_payment_info_needs.map((field) => {
                                    return <th>{field.name}</th>
                                })}

                                {selectedCompany.payment_info && selectedCompany.payment_info.payment_type === 'ach'  && appConfig.ach_payment_info_needs.map((field) => {
                                    return <th>{field.name}</th>
                                })}

                                {!selectedCompany.payment_info && selectedCompany.payment_type === 'ach'  && appConfig.ach_payment_info_needs.map((field) => {
                                    return <th>{field.name}</th>
                                })}

                                {!selectedCompany.payment_info && selectedCompany.payment_type === 'credit'  && appConfig.credit_payment_info_needs.map((field) => {
                                    return <th>{field.name}</th>
                                })}
                            </thead>
                            <tbody>
                                <tr>
                                    {appConfig.ach_payment_info_needs.map((field) => {
                                        if(field.mask) return <td>{`XXXXXX${selectedCompany[field.key].slice(6)}`}</td>
                                        if(field.upperCase) return <td>{selectedCompany[field.key].toUpperCase()}</td>
                                        return <td>{selectedCompany[field.key]}</td>
                                    })}
                                </tr>
                            </tbody>
                        </table>


                        
                    </div>
            </div>
        </main>
    )
}