import React, {useState,useEffect} from 'react'
import {Modal,Button,Spinner} from 'react-bootstrap'
import {GetVendorContacts,SubmitRemittanceJob} from '../../lib/mongoDbhelpers'
import _ from 'lodash'

export default function SendRemitModal(props, setModals){
    const handleClose = () => props.setModals({...props.modals, sendRemitModal: {open: false}})
    const [vendorAddresses, setVendorAddresses] = useState(false)
    const [selectedAddresses, setSelectedAddresses] = useState([])
    const [loading, setLoading] = useState(false)
    const [dbLoading, setDbLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if(props.modals.sendRemitModal.open && !vendorAddresses && !loading){
            setLoading(true)
            getVendorAddresses()
        }
        
        async function getVendorAddresses(){
            var data = await GetVendorContacts(props.modals.sendRemitModal.data.vendor_number)
            var uniqueEmail = _.uniqBy(data.contact, 'contact_email')
            setVendorAddresses(uniqueEmail)
            setLoading(false)
        }
    })

    async function handleSend(){
        if(selectedAddresses.length === 0) {
            setError({message: "Please select at least one contact to receive."})
            return
        }

        setError(false)
        //SEND THE EMAILS TO JOB QUEUE
        var email = selectedAddresses.map((contact) => contact.contact_email)
        email = _.uniq(email)
        setDbLoading(true)
        var result = await SubmitRemittanceJob(props.modals.sendRemitModal.data, email)
        if(result && !result.errorCode){
            setDbLoading(false)
            props.setModals({...props.modals, sendRemitModal: {open: false, data: false}})
        } else {
            setError({message: result.errorMessage})
        }
    }

    function handleSelection(event, row){
        var tempList = [...selectedAddresses]
        if(event.target.checked){ //push the row into the array
            tempList.push(row)
        } else { //get index of row and remove it
            var indexOf = tempList.findIndex(a => a.contact_type === row.contact_type && a.contact_email === row.contact_email)
            if(indexOf === -1) return
            tempList.splice(indexOf, 1)
        }
        setSelectedAddresses(tempList)
    }

    return (
        <Modal show={props.modals.sendRemitModal.open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Send Remittance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please select the vendor contacts to receive a copy.</p>
                <table className="table table-sm">
                    <thead>
                        <th></th>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Email</th>
                    </thead>
                    <tbody>
                        {vendorAddresses && vendorAddresses.map((contact) => {
                            return (
                                <tr>
                                    <td><input onClick={(e) => handleSelection(e, contact)} type="checkbox"></input></td>
                                    <td>{contact.contact_type}</td>
                                    <td>{contact.contact_name}</td>
                                    <td>{contact.contact_email}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {error ? <p className="error">{error.message}</p> : ''}
            </Modal.Body>
            <Modal.Footer>
                {dbLoading ? 
                    <Button variant="primary" disabled>
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                    </Button>
                : 
                    <Button className="btn btn-primary" onClick={handleSend}>Send Remittance</Button>
                }
            </Modal.Footer>
        </Modal>
    )
}