import React from 'react'
import {BrowserRouter,Route,Redirect,Switch} from 'react-router-dom'
import {Security} from '@okta/okta-react'
import {OktaAuth} from '../lib/oktaAuthHelpers'
import {useGetUserDataHook} from '../hooks/useUserHook'
import {useGetInvoicesHook} from '../hooks/useInvoicesHook'
import {useGetPaymentsHook} from '../hooks/usePaymentsHook'

import MenuBar from '../components/MenuBar'
import Sidebar from '../components/Sidebar'
import Dashboard from '../Pages/Dashboard'
import Invoices from '../Pages/Invoices'
import Payments from '../Pages/Payments'
import SubmitInvoice from '../Pages/SubmitInvoice'
import CompanyInfo from '../Pages/CompanyInfo'
import Loading from '../components/Loading'
import ClientSettings from '../Pages/ClientSettings'
import PageNotFound from '../Pages/PageNotFound'
import ErrorPage from '../Pages/ErrorPage'
import appConfig from '../config/app.json'

export default function Authenticated(props){
    var auth = props.auth

    //let's get our users account from mongo
    const userData = useGetUserDataHook(auth)
    const invoices = useGetInvoicesHook(userData)
    const payments = useGetPaymentsHook(userData)

    if(userData === null || invoices.invoices === null || payments === null) return <Loading/>

    if(userData.errorCode){
        return <ErrorPage error={userData}/>
    }

    if(!navigator.onLine) return <ErrorPage error={"You are not connected to the internet"}/>

    return (
        <BrowserRouter>
                <Security 
                    issuer={auth._config.issuer}
                    client_id={auth._config.client_id}
                    redirect_uri={auth._config.redirect_uri}
                >
                    <MenuBar props={props} auth={auth} userData={userData}/>
                    <Sidebar auth={auth} userData={userData}/>

                    <Switch>
                        {/* If url points to our home page, determine the correct home page to show*/}
                        <Route exact={true} path="/" render={() => <Redirect to={setHomeRoute().path}/>}/>
                        <Route path="/dashboard" render={(props) => shouldRedirectFromDashboard({...props}, invoices.invoices)}/>
                        <Route path="/invoices" render={(props) => <Invoices {...props} invoices={invoices.invoices}/>}/>
                        <Route path="/payments" render={(props) => <Payments {...props} payments={payments}/>}/>
                        <Route path="/submit" render={(props) => <SubmitInvoice {...props} userData={userData} refreshInvoices={invoices.refresh}/>}/>
                        <Route path="/info" render={(props) => <CompanyInfo {...props} userData={userData}/>}/>
                        <Route path="/settings" render={(props) => <ClientSettings {...props} userData={userData} invoiceHooks={invoices}/>}/>
                        <Route render={(props) => <PageNotFound {...props}/>}/>
                    </Switch>

                    
                </Security>
        </BrowserRouter>
    )
}

function setHomeRoute(){
    if(appConfig.home_page_routing_paths.includes(window.location.pathname)){
        if(!appConfig.dashboard_enabled) return {path: appConfig.primary_invoices_path, component: Invoices}
        else return {path: appConfig.primary_invoices_path, component: Dashboard}
    }
}

function shouldRedirectFromDashboard(props, invoices){
    if(window.location.pathname === '/dashboard' && !appConfig.dashboard_enabled) return <Redirect to={appConfig.primary_invoices_path}/>
    else return <Dashboard {...props} invoices={invoices}/>
}