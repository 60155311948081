import {doesInvoiceExist} from './mongoDbhelpers'
import {getFieldInfo} from '../lib/util'
import {calculateLineTotal} from '../lib/fieldCalculations'
import moment from 'moment'
import _ from 'lodash'
import {deriveFieldName} from './util'
import appConfig from '../config/app.json'

//Our field validators
export function valueIsNot(value, notAllowed){
    if(notAllowed.includes(value)) return false
    return true
}

export function valueExists(value, nullAllowed){
    if(!value) return false
    if(!nullAllowed && value === '') return false
    return true
}

export function minLengthCheck(value, minLength){
    if(value.length < minLength) return false
    return true
}

export function maxLengthCheck(value, maxLength){
    if(value.length > maxLength) return false
    return true
}

export function stringValidation(value, pattern){
    if(!value.match(pattern)) return false
    return true
}

export function dateDifference(value, difference){
    if(difference > 0 && moment(value).diff(moment().startOf('day').format(), 'days') > difference) return true
    if(difference < 0 && moment(value).diff(moment().startOf('day').format(), 'days') < difference) return true
    return false
}

export function validFileType(file){
    if(!['text/pdf', 'application/pdf'].includes(file)) return false
    return true
}

export function checkVendorNumber(value, props, validate){
    props.setValues({...props.values, vendor_number: value})

    if((value === '' || !value) && validate) {
        props.setError("vendor_number", "missing_value", "Vendor Number is required")
        return false
    }

    if(!validate) props.clearError("vendor_number")
    return false
}

export function doesValueAlreadyExists(list, value, key){
    if(!key){
        //search array
        if(list.find(a => a == value)) return false
    }

    if(key){
        //search array by key
        if(list.find(a => a[key] == value)) return false
    }
    return true
}

//Form Field Specific Functions
export async function validateInvoiceNumber(value, props, validate){
    //update state with the value
    props.setValues({...props.values, "invoice_number": value.toUpperCase()})

    if((!valueExists(value, false) || !minLengthCheck(value, 1)) && validate) {
        props.setError("invoice_number", "missing_value", "Invoice number is required")
        return false
    }

    if(!stringValidation(value, /^[a-z0-9-]+$/i) && value){
        props.setError("invoice_number", "invalid_character", "Only 0-9, A-Z, and - allowed.")
        return false
    }

    if(!maxLengthCheck(value, 30)) {
        props.setError("invoice_number", "length_exceeded", "Invoice number must be less than 30 characters.")
        return false
    }

    if(validate && await doesInvoiceExist(value)){ //let's see if this invoice number has already been used
        props.setError("invoice_number", "already_exists", "This invoice already exists in our records.")
        return false
    }

    if(!validate) props.clearError("invoice_number")
    return true
}

export async function checkPoNumber(value, props, validate){
        props.setValues({...props.values, "po_number": value})
        if(!value && validate) return true

        if(!stringValidation(value, /^[0-9]+$/i) && value != ''){
            props.setError("po_number", "invalid_character", "Only 0-9 allowed.")
            return false
        }

        if(!minLengthCheck(value, 6) && validate) {
            props.setError("po_number", "min_length", "PO Number must be at least six characters")
            return false
        }

        if(!maxLengthCheck(value, 9)) {
            props.setError("po_number", "min_length", "PO Number must be less than eleven characters")
            return false
        }

        if(!validate) props.clearError('po_number')

        return true
}

export function checkInvoiceDate(value, props, validate){
    props.setValue("invoice_date", value)
    props.setValues({...props.values, invoice_date: value})

    if(dateDifference(value, appConfig.max_future_invoice_date)){
        props.setError("invoice_date", "missing_value", `Invoice Date must be before ${moment().add(appConfig.max_future_invoice_date, 'days').format('MM/DD/YYYY')}`)
        return false
    }

    if(dateDifference(value, appConfig.min_previous_invoice_date)){
        props.setError("invoice_date", "missing_value", `Invoice Date must be after ${moment().add(appConfig.min_previous_invoice_date, 'days').format('MM/DD/YYYY')}`)
        return false
    }
    
    if((!valueExists(value, false)) && validate) {
        props.setError("invoice_date", "missing_value", "Invoice date is required")
        return false
    }

    props.clearError("invoice_date")
    return true
}

export function checkFiles(files, props, validate){
    if(!validate) return addFiles(files, props)

    if((!valueExists(files.length, false))) {
        props.setError("missing_files", "missing_file", "Please attach a copy of the document.")
        return false
    }

    return true
}

export function addFiles(files, props){
    if((!valueExists(files.target.files.length, false))) {
        props.setError("missing_files", "missing_file", "Please attach a copy of the document")
        return false
    }

    // if(!validFileType(files.target.files[0].type)){
    //     props.setError("missing_files", "invalid_type", `${files.target.files[0].name} is an invalid document type.  Only PDF's are allowed.`)
    //     return false
    // }

    if(!doesValueAlreadyExists(props.values.files, files.target.files[0].name, 'name')){
        props.setError("missing_files", "already_exists", `${files.target.files[0].name} is already attached.`)
        return false
    }

    //update state with the document
    var tempFiles = [...props.values.files]
    var file = new File(files.target.files, files.target.files[0].name, {type: files.target.files[0].type})

    tempFiles.push(file)
    props.setValues({...props.values, files: tempFiles})
    props.clearError('missing_files')
    return true
}

export function checkAmount(event, props, validate, arrayBased, shouldCalcLineAmount){
    var [value, name, required] = getFieldInfo(event, props, arrayBased)
    
    //Ensure amount exists on validate
    if(required && validate && !valueIsNot(parseFloat(value), [0])){
        props.setError(`${name}${arrayBased ? arrayBased.index : ''}`, `missing_${name}`, "Please enter an amount greater than zero.")
        return false
    }

    //Ensure valid amount formatting on validate
    if(event && validate && !value.toString().match(/^[0-9,.]+$/i)) {
        props.setError(name, `missing_${name}`, "Please enter a valid amount format")
        return false
    }

    //Ensure valid characters on key => This is done separately from the validate version because when a user enters an invalid character, we don't want to a display an error message for a character that won't get stored into state and thus the field
    if(value && !value.toString().match(/^[0-9,.]+$/i)) return false

    if(arrayBased){
        var tempArray = [...props.values[arrayBased.name]]
        tempArray[arrayBased.index][name] = value
        props.setValues({...props.values, [arrayBased.name]: tempArray})
    } else {
        props.setValues({...props.values, [name]: value})
    }

    if(!validate) props.clearError(name)
    if(shouldCalcLineAmount) calculateLineTotal(props, arrayBased.index)
    props.clearError(`${name}${arrayBased ? arrayBased.index : ''}`)
    return true
}

export function checkItemDescription(props, event, arrayBased, validate){
    var [value, name, required] = getFieldInfo(event, props, arrayBased)

    //set the value into state
    var tempLines = _.cloneDeep(props.values.invoice_lines)
    tempLines[arrayBased.index][name] = value
    props.setValues({...props.values, invoice_lines: tempLines})

    if((!valueExists(value, false) || !minLengthCheck(value, 1)) && validate) {
        props.setError(`${name}${arrayBased.index}`, "missing_value", "Please enter an item description")
        return false
    }

    if(!validate) props.clearError(`${name}${arrayBased.index}`)
    return true
}

export function isAmountGreaterThan(amounts, props, errorField){
    if(amounts[0] >= amounts[1]) return true
    else {
        props.setError(errorField, 'invoice_amount_too_low', 'Line costs are higher than invoice amount.')
        return false
    }
}

export function isAmountLessThan(amounts, props, errorField){
    if(amounts[0] <= amounts[1]) return true
    else {
        props.setError(errorField, 'invoice_amount_too_low', 'Lines costs are lower than invoice amount.')
        return false
    }
}

export function checkBusinessName(value, name, props, validate){
    //update state with the value
    props.setValues({...props.values, [name]: value})

    if((!valueExists(value, false) || !minLengthCheck(value, 1)) && validate) {
        props.setError(name, "missing_value", "A valid name is required")
        return false
    }

    if(!stringValidation(value, /^[a-z0-9-&, ']+$/i) && value){
        props.setError(name, "invalid_character", "A valid name is required.")
        return false
    }

    if(!maxLengthCheck(value, 50)) {
        props.setError(name, "length_exceeded", "Legal name must be less than 50 characters.")
        return false
    }

    props.clearError(name)
    return true
}

export function checkTaxId(value, name, props, validate){
    //update state with the value
    props.setValues({...props.values, [name]: value.toUpperCase()})

    if((!valueExists(value, false) || !minLengthCheck(value, 1)) && validate) {
        props.setError(name, "missing_value", "XXX-XX-XXXX or XX-XXXXXXX format.")
        return false
    }

    if(!stringValidation(value, /^\d{3}-\d{2}-\d{4}$/i) && !stringValidation(value, /^[1-9]\d?-\d{7}$/i) && value){
        props.setError(name, "invalid_character", "XXX-XX-XXXX or XX-XXXXXXX format.")
        return false
    }

    props.clearError(name)
    return true
}

export function checkEmailAddress(value, name, props, validate){
    //update state with the value
    if(typeof(name) === 'object'){
        props.setValues({...props.values, payment_info: {...props.values[name.parent], [name.field]: value}})
    } else {
        props.setValues({...props.values, [name]: value})
    }

    if((!valueExists(value, false) || !minLengthCheck(value, 1)) && validate) {
        props.setError(deriveFieldName(name), "missing_value", "Please enter a valid email address.")
        return false
    }

    if(!stringValidation(value, /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i) && value){
        props.setError(deriveFieldName(name), "invalid_character", "Please enter a valid email address.")
        return false
    }

    props.clearError(deriveFieldName(name))
    return true
}

export function checkPhoneNumber(value, name, props, validate){
    //update state with the value
    if(typeof(name) === 'object'){
        props.setValues({...props.values, payment_info: {...props.values[name.parent], [name.field]: value}})
    } else {
        props.setValues({...props.values, [name]: value})
    }

    if((!valueExists(value, false) || !minLengthCheck(value, 1)) && validate) {
        props.setError(name, "missing_value", "Please enter a valid phone number")
        return false
    }

    if(!stringValidation(value, /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/i) && value){
        props.setError(name, "invalid_character", "Please enter a valid phone number.")
        return false
    }

    props.clearError(name)
    return true
}

export function checkRoutingNumber(value, name, props, validate){
    //update state with the value
    if(typeof(name) === 'object'){
        props.setValues({...props.values, payment_info: {...props.values[name.parent], [name.field]: value.toUpperCase()}})
    } else {
        props.setValues({...props.values, [name]: value.toUpperCase()})
    }

    if((!valueExists(value, false) || !minLengthCheck(value, 1)) && validate) {
        props.setError([name.key], "missing_value", "Please enter your bank account number")
        return false
    }

    if(!stringValidation(value, /^[0-9]{7,14}$/i) && value){
        props.setError([name.key], "invalid_character", "Please enter your bank account number")
        return false
    }

    props.clearError([name.key])
    return true
}

export function checkAbaNumber(value, name, props, validate){
    //update state with the value
    if(typeof(name) === 'object'){
        props.setValues({...props.values, payment_info: {...props.values[name.parent], [name.field]: value.toUpperCase()}})
    } else {
        props.setValues({...props.values, [name]: value.toUpperCase()})
    }

    if((!valueExists(value, false) || !minLengthCheck(value, 1)) && validate) {
        props.setError([name.key], "missing_value", "Please enter your bank's routing number")
        return false
    }

    if(!stringValidation(value, /^((0[0-9])|(1[0-2])|(2[1-9])|(3[0-2])|(6[1-9])|(7[0-2])|80)([0-9]{7})$/i) && value){
        props.setError([name.key], "invalid_character", "Please enter your bank's routing number")
        return false
    }

    props.clearError([name.key])
    return true
}

export function doesValueExist(value, name, props){
    if((!valueExists(value, false) || !minLengthCheck(value, 1))) {
        props.setError(name, "missing_value", "Please select a type")
        return false
    }
    return true
}

export function checkAddress(value, name, props){
    if((!valueExists(value, false) || !minLengthCheck(value, 5))) {
        props.setError(name, "missing_value", "Please enter an address")
        return false
    }

    if(!stringValidation(value, /^\s*\S+(?:\s+\S+){2}/i) && value){
        props.setError(name, "invalid_character", "Invalid address")
        return false
    }
    return true
}

export function checkZipCode(value, name, props){
    if((!valueExists(value, false) || !minLengthCheck(value, 5))) {
        props.setError(name, "missing_value", "Please enter a zip code")
        return false
    }

    if(!stringValidation(value, /^\d{5}(?:[-\s]\d{4})?$/i) && value){
        props.setError(name, "invalid_character", "Invalid zip code")
        return false
    }
    return true
}