import React from 'react'
import {Link,Redirect} from 'react-router-dom'
import {logIn} from '../lib/oktaAuthHelpers'

export default function MenuBarUnauthed(props){
    return(
        <nav className="navbar flex-md-nowrap p-0 shadow">
            <Link to={'/'} className='navbar-brand col-sm-3 col-md-2 mr-0'>Vendor Portal</Link>
            <ul className="navbar-nav px-3">
                <li className="nav-item text-nowrap sign-in-out">
                    {true ?
                        <a onClick={() => logIn(props.auth)} href="#">Sign In</a>
                    : ''}
                </li>
            </ul>
        </nav>
    )
}