import React, {useState,useEffect} from 'react'
import {Modal,Button, Spinner} from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import appConfig from '../../config/app.json'
import currency from 'currency.js'
import {PlusCircle,File,FilePlus,Trash2,ArrowRight, Edit} from 'react-feather'
import {SubmitNewInvoice} from '../../lib/mongoDbhelpers'

export default function FinalReviewModal(props){
    const [loading, setLoading] = useState(false)

    var register = props.register
    var handleSubmit = props.handleSubmit
    var setValue = props.setValue
    var errors = props.errors
    var setError = props.setError
    var clearError = props.clearError
    var setStep = props.setStep
    var values = props.values
    var getValues = props.getValues
    var setValues = props.setValues
    var step = props.step
    var invoiceLines = values.invoiceLines
    var readOnly = true

    function checkInvoiceNumber(){}
    function checkPoNumber(){}
    function processFile(){}
    function checkAmount(){}
    function setToCurrency(){}
    function checkMiscLine(){}
    function addNewMiscLine(){}
    function checkItemDescription(){}
    function calculateLineTotal(){}
    function deleteLine(){}
    function addLine(){}
    function calculateTotalLineAmount(){}

    async function validateInvoice(){
        //perform validations here

        //submit to mongodb for processing
        try{
            setLoading(true)
            var result = await SubmitNewInvoice({...values, wfType: "submission"}, props.userData)
            if(result.errorCode){
                setLoading(false)
                setError('final_invoice_sumbit', 'submit_error', result.errorMessage)
                return false
            }

            if(result){
                setTimeout(() => {
                    setLoading(false)
                    setStep(5)
                    sessionStorage.removeItem('invoices')
                    props.refreshInvoices()
                },750)
            }
        } catch(e){
            setError('final_invoice_submit', 'submit_error', e.toString())
            setLoading(false)
        }
    }

    return (
        <Modal size="lg" show={step === 4 ? true: false} onHide={(e) => {setStep(3)}}>
            <Modal.Header closeButton>
                <Modal.Title>Please review invoice information before submitting</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{paddingTop: '0'}}>
                <form onSubmit={handleSubmit(validateInvoice)} className="submit-confirmation">
                    <section style={{marginTop: '0'}}>
                        <p><strong>Invoice Information</strong></p>
                        <Button className="btn btn-secondary btn-xs btn-icon-left" onClick={() => setStep(1)}><Edit /> Edit</Button>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="invoice_number">Vendor Name</label>
                                <input readOnly={true} type="text" className={errors.invoice_number ? "form-control is-invalid" : "form-control"} name="invoice_number" id="invoice_number" defaultValue={values.vendor_number.label}></input>
                                <span className="error">{errors.vendor_name && errors.vendor_name.message}</span>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="invoice_number">Invoice Number</label>
                                <input readOnly={true} type="text" onChange={(e) => checkInvoiceNumber(e.target.value)} ref={register} className={errors.invoice_number ? "form-control is-invalid" : "form-control"} name="invoice_number" id="invoice_number" defaultValue={values.invoice_number}></input>
                                <span className="error">{errors.invoice_number && errors.invoice_number.message}</span>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="inputPassword4">Invoice Date</label>
                                <DatePicker
                                    readOnly={true} 
                                    selected={values.invoice_date}
                                    onChange={(e) => {
                                        setValue("invoice_date", e)
                                        setValues({...values, invoice_date: e})
                                        clearError("invoice_date")
                                        }
                                    }
                                    className={errors.invoice_date ? "form-control is-invalid" : "form-control"}
                                />
                                <span className="error">{errors.invoice_date && errors.invoice_date.message}</span>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="invoice_number">PO Number</label>
                                <input readOnly={true}  type="text" ref={register} onChange={(e) => {checkPoNumber(e.target.value)}} class="form-control" name="po_number" id="po_number" defaultValue={values.po_number}></input>
                                {errors.po_number && errors.po_number.message}
                            </div>
                        </div>
                        <div className="tile-secondary">
                            <label>Attachments</label>
                            <div className="attachments">
                                {errors.missing_files ? <p className="error">{errors.missing_files.message}</p> : ''}
                                {values.files.map((file) => {
                                    return (
                                        <div className="attachment"><File size={16}/> {file.name}
                                            <img onClick={() => window.open(window.URL.createObjectURL(file), '_blank')} style={{height:25,width:25}} src={window.URL.createObjectURL(file)}></img>
                                        </div>
                                    )
                                })}
                            </div>
                            {/* <label className="btn btn-secondary btn-xs" for="file"><FilePlus size={16}/> Attach a file</label> */}
                            {/* <input onChange={(e) => processFile(e)} name="file" id="file" type='file'></input> */}
                        </div>
                    </section>
                    <section>
                        <p><strong>Invoice Amounts</strong></p>
                        <Button className="btn btn-secondary btn-xs btn-icon-left" onClick={() => setStep(2)}><Edit /> Edit</Button>
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="invoice_number">Invoice Amount</label>
                                <input readOnly={true} type="text" required={true} onChange={(e) => checkAmount(e.target.value, false, e.target.name)} onBlur={(e) => setToCurrency(e.target.value, e.target.name)} className={errors.invoice_amount ? "form-control is-invalid" : "form-control"} name="invoice_amount" id="invoice_amount" value={values.invoice_amount}></input>
                                <span className="error">{errors.invoice_amount && errors.invoice_amount.message}</span>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="invoice_number">Sales Tax</label>
                                <input readOnly={true} type="text" onChange={(e) => checkAmount(e.target.value, false, e.target.name)} onBlur={(e) => setToCurrency(e.target.value, e.target.name)} className={errors.sales_tax ? "form-control is-invalid" : "form-control"} name="sales_tax" id="sales_tax" value={values.sales_tax}></input>
                                <span className="error">{errors.sales_tax && errors.sales_tax.message}</span>
                            </div>
                            <div class="form-group col-md-4">
                                <label for="invoice_number">Freight Amount</label>
                                <input readOnly={true} type="text" onChange={(e) => checkAmount(e.target.value, false, e.target.name)} onBlur={(e) => setToCurrency(e.target.value, e.target.name)} className={errors.freight_amount ? "form-control is-invalid" : "form-control"} name="freight_amount" id="freight_amount" value={values.freight_amount}></input>
                                <span className="error">{errors.freight_amount && errors.freight_amount.message}</span>
                            </div>
                        </div>
                        <div class="form-row one">
                            {step === 4 && values.misc_charges.length !== 0 ? <p className="lead font-weight-bold">Miscellaneous Charges</p> : ''}
                        </div>
                        <div className="form-row misc-charges tile-secondary">
                            {step !== 4 ? miscellaneousChargeRow(checkMiscLine, register, true, null, values, addNewMiscLine) : ''}
                            {values.misc_charges ? values.misc_charges.map((charge, index) => {
                                return (
                                    miscellaneousChargeRow(checkMiscLine, register, false, charge, values, addNewMiscLine, index)
                                )
                            }): ''}
                        </div>
                    </section>
                    <section>
                        <p><strong>Invoice Details</strong></p>
                        <Button className="btn btn-secondary btn-xs btn-icon-left" onClick={() => setStep(3)}><Edit /> Edit</Button>
                        <table className="table table-sm add-records invoices">
                            <thead>
                                <th><label for="invoice_number">Line #</label></th>
                                <th><label for="invoice_number">Item Description</label></th>
                                <th><label for="invoice_number">Quantity</label></th>
                                <th><label for="invoice_number">Price</label></th>
                                <th><label for="invoice_number">Extended Amount</label></th>
                            </thead>
                            <tbody>
                                {values.invoice_lines.map((line, index) => {
                                    return (
                                        <tr>
                                            <td className="line-number">
                                                <input type="text" readOnly={true} className="form-control line-number" name={`line_number${index}`} id="line_number" value={index + 1}></input>
                                            </td>
                                            <td className="item-description">
                                                <input type="text" readOnly={true} onChange={(e) => checkItemDescription(e.target.value, false, 'line_description', index)} className={errors[`line_description${index}`] ? "form-control is-invalid" : "form-control"} name={`line_description`} id={`line_description${index}`} value={line.line_description}></input>
                                                {errors[`line_description${index}`] && errors[`line_description${index}`].message}
                                            </td>
                                            <td>
                                                <input type="text" readOnly={readOnly} required={true} onChange={(e) => checkAmount(e.target.value, false, 'line_quantity', index, calculateLineTotal)} className={errors[`line_quantity${index}`] ? "form-control is-invalid" : "form-control"} value={line['line_quantity']} name={`line_quantity`} id={`line_quantity${index}`}></input>
                                                {errors[`line_quantity${index}`] && errors[`line_quantity${index}`].message}
                                            </td>
                                            <td>
                                                <input type="text" readOnly={readOnly} required={true} onChange={(e) => checkAmount(e.target.value, false, 'line_price', index, calculateLineTotal)} className={errors[`line_price${index}`] ? "form-control is-invalid" : "form-control"} value={line.line_price} name={`line_price`} id={`line_price${index}`} onBlur={(e) => document.getElementById(e.target.id).value = currency(!e.target.value ? 0 : e.target.value)}></input>
                                                {errors[`line_price${index}`] && errors[`line_price${index}`].message}
                                            </td>
                                            <td>
                                                <input type="text" readOnly={true} className={errors.line_extended_amount ? "form-control is-invalid" : "form-control"} name={`line_extended_amount`} id={`line_extended_amount${index}`} value={line.line_extended_amount}></input>
                                                {errors[`line_extended_amount${index}`] && errors[`line_extended_amount${index}`].message}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </section>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <React.Fragment>
                    <span className="error">{errors.final_invoice_submit && errors.final_invoice_submit.message}</span>
                    {loading ? 
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button>
                    :
                        <Button variant="primary" onClick={() => validateInvoice(values)}>
                            Submit
                        </Button>
                    }
                </React.Fragment>
                
            </Modal.Footer>
        </Modal>
    )
}

function miscChargeOptions(options, values){
    return (
        options.map((option) => {
            if(!values.misc_charges) return <option>{option.charge_name}</option>
            if(values.misc_charges.findIndex(a => a.charge_type === option.charge_type) > -1) return null
            return <option>{option.charge_name}</option>
        })
    )
}

function miscellaneousChargeRow(checkMiscLine, register, blank, charge, values, addNewMiscLine, index){
    if(blank) {
        return ( //this is the null line
            <div class="input-group">
                <select ref={register} name="newMiscLineType" class="form-control" id="exampleFormControlSelect1">
                    {miscChargeOptions(appConfig.misc_charge_fields, values)}
                </select>
                <input ref={register} type="text" id="newMiscLineAmount" onBlur={(e) => document.getElementById(e.target.id).value =  currency(e.target.value)} name="newMiscLineAmount" class="form-control col-md-2 newMiscLineAmount" defaultValue={currency(0)}></input>
                <button onClick={() => addNewMiscLine()} type="button" className="btn btn-secondary btn-xs"><PlusCircle /> Add another charge</button>
            </div>
        )
    } else { //this is existing line with data
        return (
            <div class="input-group">
                <select disabled={true} onChange={(e) => checkMiscLine(e.target.value, index, e, 'charge_type')} value={charge.charge_type} class="form-control" id={`charge_type${index}`}>
                    {miscChargeOptions(appConfig.misc_charge_fields, values)}
                </select>
                <input readOnly={true} onChange={(e) => checkMiscLine(e.target.value, index, e, 'charge_amount')} type="text" class="form-control col-md-2 newMiscLineAmount" value={charge.charge_amount}></input>
            </div>
        )
    }   
}

    