import React, {useState,useEffect} from 'react'
import InvoicesTable from '../Invoices/InvoicesTable'
import SendRemotModal from './SendRemitModal'
import InvoiceContext from '../../Contextuals/InvoiceContext'
import useFilterHook from '../../hooks/useFilterHook'
import usePageOptions from '../../hooks/usePageOptions';
import Loading from '../Loading'
import appConfig from '../../config/app.json'
import {ArrowLeftCircle} from 'react-feather'
import cssConfig from '../../config/css.json'
import {fieldValueFormatter} from '../../lib/util'
import {formatPrice} from '../../lib/util'
import SendRemitModal from './SendRemitModal'

export default function PaymentDetails(props){
    const [invoiceContext, setInvoiceContext] = useState(false)
    const invoiceList = useFilterHook(props.payment.invoices)
    const pageOptions = usePageOptions({idxStart: 0, idxEnd: 15, selectedHeaderButton: 'View All',title: 'Remittance Details',filterOptions: false})
    const invoiceTableOptions = usePageOptions({idxStart: 0, idxEnd: 15, selectedHeaderButton: 'View All',headerOptions: true})

    const [modals, setModals] = useState({
        sendRemitModal: {open: false, data: false}
    })

    // Field Value Formatter could be used for the below, but I couldn't get it working
    const formatDate = new Date(props.payment.payment_date);

    var classes = props.classes

    function getInvoiceDetailsFromSession(){
        var invoices = []
        var sessionData = sessionStorage.getItem("invoices")
        sessionData = JSON.parse(sessionData).data
        props.payment.invoices.forEach((invoice) => {
            var inv = sessionData.find(a => {
                return a.invoice_number == invoice.invoiceNumber
            })
            if(!inv) return
            invoices.push(inv)
        })

        return invoices
    }

    if(!invoiceList) return <Loading/>

    classes = cssConfig.defaultSection + " col-12";

    var readOnly = props.readOnly
    var payment = props.payment

    //initialize our page options
    if(pageOptions.options === null) return <Loading/>

    return (
        <React.Fragment>
            <button className="btn-icon no-border btn-back" onClick={() => props.history.push('/payments')}>
                <ArrowLeftCircle size={20} className='featherTableButtons feather-home mt-1'/> Back to Payments
            </button>
            <div className="tile">
                <div className="invoice-header">
                    <button onClick={() => setModals({...modals, sendRemitModal: {open: true, data: payment}})} className="btn btn-sm btn-primary">Send Remittance</button>
                    <ul className="big-fields">
                        <li className='number'>
                            <span>Payment Number:</span>
                            {props.payment.payment_number}
                        </li>
                        <li>
                            <span>Payment Amount:</span>
                            {formatPrice(props.payment.payment_amount.toString().replace('.', ''))}
                        </li>
                        <li className='date'>
                            <span>Payment Date:</span>
                            { formatDate.toLocaleDateString('en-US') }
                        </li>
                    </ul>

                    {/* <div className="top-total">
                        <span>Payment Amount:</span>
                        ${props.payment.payment_amount}
                    </div> */}

                    {/* //PRIMARY FORM FIELDS// */}
                    <ul>
                        {appConfig.payment_table_fields.map((hdr, index) => {
                            const excludeFields = [
                                'Payment Amount',
                                'Payment #',
                                'Payment Number',
                                'Payment Date'
                            ]
                            if (excludeFields.includes(hdr.name)) {
                                return
                            }
                            return (
                                <li>
                                    {hdr.name}: <strong>{fieldValueFormatter(hdr, payment)}</strong>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>

            <InvoicesTable
                classes={classes}
                invoices={getInvoiceDetailsFromSession()}
                options={invoiceTableOptions.options}
                setOptions={invoiceTableOptions.setOptions}
                addFilterLogic={invoiceList.addFilterLogic}
                invoiceContext={invoiceContext}
                setInvoiceContext={setInvoiceContext}
                filters={invoiceList.filters}
                handlePageIncrement={invoiceTableOptions.handlePageIncrement}
            />
            {invoiceContext && invoiceContext.open ? 
                <InvoiceContext
                    invoice={invoiceContext}
                    onClose={setInvoiceContext}
                    history={props.history}
                />
            : ''}
            <SendRemitModal modals={modals} setModals={setModals}/>
        </React.Fragment>
    )
}