import React, {useState,useEffect} from 'react'
import {addLine,deleteLine} from '../SubmitInvoice/SubmitInvoiceFunctions'
import {checkItemDescription,checkAmount} from '../../lib/fieldValidation'
import {calculateTotal,toBeDistributedCalc} from '../../lib/fieldCalculations'
import {setToCurrency} from '../../lib/util'
import currency from 'currency.js'
import {Trash2, PlusCircle} from 'react-feather'
import _ from 'lodash'

export default function NonPolines(props){
    const {register, setValue, errors, setError, setStep, step, values, setValues, getValues, readOnly} = props

    return (
        <React.Fragment>
            <div className="top-total">
                <span>Invoice Total:</span>
                ${values.invoice_amount}
            </div>
            <div className="top-subtotal">
                <span>To Be Distributed</span>
                ${toBeDistributedCalc(props)}
            </div>
            <div className="top-subtotal">
                <span>Lines Total</span>
                ${currency(calculateTotal(props.values.invoice_lines, 'line_extended_amount')).toString()}
            </div>
            <table className="table table-sm add-records invoices">
                <thead>
                    <tr>
                        <th><label for="invoice_number">Line #</label></th>
                        <th><label for="invoice_number">Item Description</label></th>
                        <th><label for="invoice_number">Quantity</label></th>
                        <th><label for="invoice_number">Price</label></th>
                        <th><label for="invoice_number">Extended Amount</label></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {values.invoice_lines.map((line, index) => {
                        return (
                            <tr>
                                <td className="line-number">
                                    <input type="text" readOnly={true} className="form-control line-number" name={`line_number${index}`} id="line_number" value={index + 1}></input>
                                </td>
                                <td className="item-description">
                                    <input type="text" onChange={(e) => checkItemDescription(props, e, {name: "invoice_lines", index: index}, false)} className={errors[`line_description${index}`] ? "form-control is-invalid" : "form-control"} name={`line_description`} id={`line_description${index}`} value={line.line_description}></input>
                                    {errors[`line_description${index}`] && errors[`line_description${index}`].message}
                                </td>
                                <td>
                                    <input type="text" readOnly={readOnly} required={true} onChange={(e) => checkAmount(e, props, false, {name: 'invoice_lines', index: index}, true)} className={errors[`line_quantity${index}`] ? "form-control is-invalid" : "form-control"} value={line['line_quantity']} name={`line_quantity`} id={`line_quantity${index}`}></input>
                                    {errors[`line_quantity${index}`] && errors[`line_quantity${index}`].message}
                                </td>
                                <td>
                                    <input type="text" readOnly={readOnly} required={true} onChange={(e) => checkAmount(e, props, false, {name: 'invoice_lines', index: index}, true)} className={errors[`line_price${index}`] ? "form-control is-invalid" : "form-control"} value={line.line_price} name={`line_price`} id={`line_price${index}`} onBlur={(e) => setToCurrency(props, e.target.value, 'line_price', {index: index, name: 'invoice_lines'})}></input>
                                    {errors[`line_price${index}`] && errors[`line_price${index}`].message}
                                </td>
                                <td>
                                    <input type="text" readOnly={true} className={errors.line_extended_amount ? "form-control is-invalid" : "form-control"} name={`line_extended_amount`} id={`line_extended_amount${index}`} value={line.line_extended_amount}></input>
                                    {errors[`line_extended_amount${index}`] && errors[`line_extended_amount${index}`].message}
                                </td>
                                <td>
                                    <Trash2 onClick={() => deleteLine(props, index)}/>
                                </td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td colSpan={6} style={{paddingTop: "20px"}}>
                            <button onClick={() => addLine(props)} type="button" className="btn btn-tertiary btn-sm btn-icon-left"><PlusCircle />Add another line</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </React.Fragment>
    )
}