import React, {useState, useEffect} from 'react'
import moment from 'moment'
import appConfig from '../config/app.json'
import { template } from '@babel/core'

export default function useFilterHook(sourceList){
    const [list, setList] = useState(sourceList)
    const [filters, setFilters] = useState([])

    useEffect(() => {
        setList(filterArray(sourceList, filters))
    }, [filters])

    function addFilterLogic(key, newValue){
        //here we determine whether the requeseted filter information should be added to the array or not
        var tempFilters = [...filters]  //create copy of current filters
        var keyIndex = tempFilters.findIndex(a => a.key === key)  //find index of current key

        //update the value of this key, don't add a new one
        if(keyIndex > -1) tempFilters[keyIndex].value = newValue
        else tempFilters.push({key: key, value: newValue})
        setFilters(tempFilters)
    }

    return {list, addFilterLogic, filters}
}

function filterArray(list, filterArray){
    var tempList = [...list]
    filterArray.forEach((filter) => {
        try {
            if(filter.key === appConfig.payment_status_key){
                tempList = tempList.filter(a => a[filter.key] === filter.value)
            } else if (filter.key === 'invoice_date' || filter.key === "payment_date") {
                if(filter.value !== null || filter.value){
                    tempList = tempList.filter(a => moment(a[filter.key]).format('MM/DD/YYYY') == moment(filter.value).format('MM/DD/YYYY'))
                }
            } else if(['payment_type','invoice_status'].includes(filter.key)){
                if(filter.value !== 'All') tempList = tempList.filter(a => a[filter.key].toString().toUpperCase().indexOf(filter.value.toUpperCase()) > -1)
            } else {
                try{
                    tempList = tempList.filter(a => a[filter.key].toString().toUpperCase().indexOf(filter.value.toUpperCase()) > -1)
                } catch(e){
                    
                }
            }
        } catch(e) {
            if(e.toString().indexOf('TypeError: undefined is not an object') > -1){
                //console.log(`Filter key ${filter.key.toUpperCase()} is not a valid key for the list`)
            }
        }
    })
    return tempList
}

function resetFilterValues(){
    var fields = document.getElementsByName('invoiceTableFilters')
    fields.forEach((field) => {
        document.getElementById(field.id).value = ''
    })
}