import React,{useState,useEffect} from 'react'
import {getNewVendorRequestDetails,SubmitNewVendorForm} from '../lib/mongoDbhelpers'
import Loading from '../components/Loading'
import useForm from 'react-hook-form'
import NVSWelcome from '../components/NewVendorSetup/NVSWelcome'
import NVSPartOne from '../components/NewVendorSetup/NVSPartOne'
import NVSPartTwo from '../components/NewVendorSetup/NVSPartTwo'
import NVSPartThree from '../components/NewVendorSetup/NVSPartThree'
import FinalReviewModal from '../components/NewVendorSetup/FinalReviewModal'

export default function NewVendorSetup(props){
    const [setupId, setSetupId] = useState(null)
    const [requestDetails, setRequestDetails] = useState(null)
    const [loading, setLoading] = useState(false)
    const [submitStatus, setSubmitStatus] = useState(false)
    const [step, setStep] = useState(1)

    const [values, setValues] = useState({primary_contact_email: requestDetails ? requestDetails.contact_email : '', primary_contact_name: requestDetails ? requestDetails.contact_name : '', legal_name: '', dba_name: '', tax_id: '', primary_contact_number: '', payment_info: {payment_type: "credit"},  addresses: [{"address_type": '', "address_attn": '', "address_address": "", "address_city": '', "address_state": "", "address_zip": ""}], usePrimary: false})
    const {register, handleSubmit, watch, errors, setValue, getValues, setError, clearError } = useForm()

    useEffect(() => {
        if(requestDetails === null && !loading){
            getNewVendorRequestDetails(setRequestDetails, setLoading)
            setLoading(true)
        }
    }, [requestDetails])

    if(requestDetails === null) return <Loading/>

    if(requestDetails === false) return (
        <main role="main" class="col-10 ml-sm-auto">
            <h1>UH OH</h1>
        </main>
    )

    if(values.primary_contact_email === ''){
        setValues({...values, primary_contact_email: requestDetails.contact_email, primary_contact_name: requestDetails.contact_name})
    }

    if(step === 4 && !submitStatus){
        return <FinalReviewModal {...props} requestDetails={requestDetails} setStep={setStep} register={register} handleSubmit={handleSubmit} setValue={setValue} errors={errors} getValues={getValues} setError={setError} clearError={clearError} setValues={setValues} values={values} step={step}/>
    }

    if(step === 5){
        return (
            <main role="main" class="col-10 mr-auto ml-auto">
                <div className="col-12 jumbotron my-0 py-4">
                    <h4>Success!</h4>
                    <p className="lead">Your setup is complete.  The setup will now be reviewed and once approved, you will receive an email notification.</p>
                </div>
            </main>
        )
    }

    return (
        <main role="main" class="col-12">
            {step === 0 ? <NVSWelcome {...props} setStep={setStep} register={register} handleSubmit={handleSubmit} setValue={setValue} errors={errors} getValues={getValues} setError={setError} clearError={clearError} setValues={setValues} values={values} step={step}/> : ''}
            {step === 1 ? <NVSPartOne {...props} setStep={setStep} register={register} handleSubmit={handleSubmit} setValue={setValue} errors={errors} getValues={getValues} setError={setError} clearError={clearError} setValues={setValues} values={values} step={step}/> : ''}
            {step === 2 ? <NVSPartTwo {...props} setStep={setStep} register={register} handleSubmit={handleSubmit} setValue={setValue} errors={errors} getValues={getValues} setError={setError} clearError={clearError} setValues={setValues} values={values} step={step}/> : ''}
            {step === 3 ? <NVSPartThree {...props} setStep={setStep} register={register} handleSubmit={handleSubmit} setValue={setValue} errors={errors} getValues={getValues} setError={setError} clearError={clearError} setValues={setValues} values={values} step={step}/> : ''}
        </main>
    )
}