import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

export default class OktaSignInWidget extends Component {
    onSuccess(res){
        console.log(res);
        //res.session.setCookieAndRedirect()
        // widget.tokenManager.add('my_id_token', res);
    
        // // If the widget is configured for OIDC with multiple responseTypes, the
        // // response will be an array of tokens:
        // // i.e. authParams.responseType = ['id_token', 'token']
        // widget.tokenManager.add('my_id_token', res[0]);
        // widget.tokenManager.add('my_access_token', res[1]);
    }

    onError(){

    }

    componentDidMount() {
        const el = ReactDOM.findDOMNode(this);
        this.widget = new OktaSignIn({
        baseUrl: 'https://dev-128370.okta.com',
        "issuer": "https://dev-128370.okta.com/oauth2/default",
        "redirect_uri": "https://localhost:3000/implicit/callback",
        "client_id": "0oa17rhajgxpCDeWf357",
        authParams: {
            responseType: "id_token"
        }
        });
        this.widget.renderEl({el}, this.onSuccess, this.props.onError);
    }

    componentWillUnmount() {
        this.widget.remove();
    }

    render() {
        return <div />;
    }
};