import React, {useState, useEffect} from 'react'
import InvoicesTable from '../components/Invoices/InvoicesTable'
import InvoiceContext from '../Contextuals/InvoiceContext'
import InvoiceDetails from '../components/Invoices/InvoiceDetails'
import Loading from '../components/Loading'
import useFilterHook from '../hooks/useFilterHook';
import {useGetInvoiceByUrlHook} from '../hooks/useInvoicesHook'

import cssConfig from '../config/css.json'
import usePageOptions from '../hooks/usePageOptions';

export default function Invoices(props){
    //State - Standard React Hooks
    const [invoiceContext, setInvoiceContext] = useState(false)
    const [readOnly, setReadOnly] = useState(true)

    //State - Custom React hooks
    const pageOptions = usePageOptions({idxStart: 0, idxEnd: 15, selectedHeaderButton: 'View All',headerOptions: true})
    const invoiceList = useFilterHook(props.invoices)
    const invoiceQuery = useGetInvoiceByUrlHook('i', props.invoices)
    
    var classes = cssConfig.defaultSection + " col-12"
    
    //initialize our page options
    //if(pageOptions.options === null) pageOptions.setOptions({idxStart: 0, idxEnd: 15, selectedHeaderButton: 'View All',headerOptions: true})
    if(pageOptions.options === null) return <Loading/>

    return (
        <main role="main" className="col-10 ml-sm-auto">
            {invoiceList && invoiceQuery === null ?
                <React.Fragment>
                    <InvoicesTable
                        classes={classes}
                        options={pageOptions.options}
                        invoices={invoiceList.list} 
                        invoiceContext={invoiceContext} 
                        setOptions={pageOptions.setOptions}
                        setInvoiceContext={setInvoiceContext}
                        addFilterLogic={invoiceList.addFilterLogic}
                        filters={invoiceList.filters}
                        handlePageIncrement={pageOptions.handlePageIncrement}
                    />
                </React.Fragment>              
            : ''}
            {invoiceContext && invoiceContext.open && invoiceQuery === null ?
                <InvoiceContext
                    invoice={invoiceContext}
                    onClose={setInvoiceContext}
                    history={props.history}
                />
            : ''}

            {invoiceList && invoiceQuery ? 
                <InvoiceDetails
                    classes={classes}
                    invoice={invoiceQuery}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                    {...props}
                />
            : ""}
        </main>
    )
}