import React, {useState, useEffect} from 'react'
import useForm from 'react-hook-form'
import SubmitInvoiceWelcome from '../components/SubmitInvoice/SubmitInvoiceWelcome'
import SubmitInvoicePartOne from '../components/SubmitInvoice/SubmitInvoicePartOne'
import SubmitInvoicePartTwo from '../components/SubmitInvoice/SubmitInvoicePartTwo'
import SubmitInvoicePartThree from '../components/SubmitInvoice/SubmitInvoicePartThree'
import SubmitInvoiceSuccess from '../components/SubmitInvoice/SubmitInvoiceSuccess'
import FinalReviewModal from '../components/SubmitInvoice/FinalReviewModal'
import Loading from '../components/Loading'
import {ResetForm,ValidateInvoice} from '../components/SubmitInvoice/SubmitInvoiceFunctions'
import {GetVendorNames} from '../lib/mongoDbhelpers'
import appConfig from '../config/app.json'
import _ from 'lodash'

export default function SubmitInvoice(props){
    const {register, handleSubmit, errors, setValue, getValues, setError, clearError } = useForm()
    const [step, setStep] = useState(appConfig.submit_invoice_start_screen) //controls what page of the submit invoice process is showing
    const [values, setValues] = useState(_.cloneDeep(appConfig.submit_invoice_structure)) //this holds the values of our document
    const [vendors, setVendors] = useState(null)
    const [loading, setLoading] = useState(false)

    if(vendors === null && !loading){
        setLoading(true)
        GetVendorNames(props.userData.vendors, setVendors)
    }

    if(props.userData.security_group === 'vendor-admin' && vendors && !values.vendor_number){
        setValues({...values, vendor_number: vendors.find(a => a.value == props.userData.vendors[0])})
    }

    if(!vendors) return <Loading/>
    
    //Render Welcome Page if desired
    if([0].includes(step)) return <SubmitInvoiceWelcome setStep={setStep}/>

    //Render our first step
    if(step === 1) return <SubmitInvoicePartOne totalSteps={appConfig.submit_invoice_steps.length} setStep={setStep} register={register} handleSubmit={handleSubmit} setValue={setValue} errors={errors} getValues={getValues} setError={setError} clearError={clearError} setValues={setValues} values={values} step={step} userData={props.userData} vendors={vendors}/>

    //Render our second step
    if(step === 2) return <SubmitInvoicePartTwo totalSteps={appConfig.submit_invoice_steps.length} setStep={setStep} register={register} handleSubmit={handleSubmit} setValue={setValue} errors={errors} getValues={getValues} setError={setError} clearError={clearError} setValues={setValues} values={values} step={step}/>

    //Render our third step
    if(step === 3) return <SubmitInvoicePartThree totalSteps={appConfig.submit_invoice_steps.length} setStep={setStep} register={register} handleSubmit={handleSubmit} setValue={setValue} errors={errors} getValues={getValues} setError={setError} clearError={clearError} setValues={setValues} values={values} step={step}/>

    //Render our review modal
    if(step === 4) return <FinalReviewModal userData={props.userData} validateInvoice={ValidateInvoice} setStep={setStep} register={register}  handleSubmit={handleSubmit} setValue={setValue} errors={errors} getValues={getValues} setError={setError} clearError={clearError} setValues={setValues} values={values} step={step} refreshInvoices={props.refreshInvoices} userDate={props.userData}/>

    //Render our Success Page
    if(step === 5) return <SubmitInvoiceSuccess resetForm={ResetForm} setValues={setValues} setStep={setStep}/>
}