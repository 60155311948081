import React, {useState,useEffect} from 'react'
import InvoiceLines from '../Invoices/InvoiceLines'
import {Modal,Spinner,Button} from 'react-bootstrap'
import appConfig from '../../config/app.json'
import {fieldValueFormatter} from '../../lib/util'
import {XCircle,PenTool} from 'react-feather'
import {ApproveNewInvoice,RejectNewInvoice} from '../../lib/mongoDbhelpers'

export default function NewInvoiceModal(props){
    const modals = props.modals
    const setModals = props.setModals
    const formHelper = props.formHelper

    const handleClose = () => setModals({...modals, viewNewInvoiceModal: {open: false, data: false}})
    const formatDate = new Date(modals.viewNewInvoiceModal.data.invoice_date)

    const errors = formHelper.errors
    const register = formHelper.register
    const readOnly = true
    const [activeButton, setActiveButton] = useState(false)

    async function approveInvoice(button){
        setActiveButton(button)
        formHelper.setLoading(true)
        var result = await ApproveNewInvoice(modals.viewNewInvoiceModal.data, props.userData)
        if(result.errorCode){
            setTimeout(() => {
                formHelper.setError("approve_invoice", "error", result.errorMessage.toString())
                formHelper.setLoading(false)
                setActiveButton(false)
            },1500)
        } else {
            setTimeout(() => {
                if(result === true){
                    formHelper.setLoading(false)
                    formHelper.clearError()
                    formHelper.setShowToast(true)
                    handleClose()
                    props.updateWorkflowItems()
                    props.invoiceHooks.refresh()
                    setActiveButton(false)
                }
            },3000)
        }
    }

    async function rejectInvoice(button){
        setActiveButton(button)
        formHelper.setLoading(true)
        var result = await RejectNewInvoice(modals.viewNewInvoiceModal.data, props.userData)
        if(result.errorCode){
            setTimeout(() => {
                formHelper.setError("approve_invoice", "error", result.errorMessage.toString())
                formHelper.setLoading(false)
                setActiveButton(false)
            },1500)
        } else {
            setTimeout(() => {
                if(result === true){
                    formHelper.setLoading(false)
                    formHelper.clearError()
                    formHelper.setShowToast(true)
                    handleClose()
                    props.updateWorkflowItems()
                    props.invoiceHooks.refresh()
                    setActiveButton(false)
                }
            },3000)
        }
    }

    return (
        <Modal size="lg" show={modals.viewNewInvoiceModal.open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>New Invoice Request</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{paddingTop: '0'}}>
                <div className='tile'>
                    <div className="invoice-header">
                        <ul className="big-fields">
                            <li>
                                <span>Invoice Number:</span>
                                <strong>{modals.viewNewInvoiceModal.data.invoice_number}</strong>
                            </li>
                            <li className="date">
                                <span>Invoice Date:</span>
                                {formatDate.toLocaleDateString('en-US') }
                            </li>
                        </ul>
                        <div className="top-total">
                            <span>Invoice Amount:</span>
                            ${modals.viewNewInvoiceModal.data.invoice_amount}
                        </div>
                        <ul>
                        {appConfig.invoice_table_fields.map((field) => {
                            const excludeFields = [
                                'Invoice Amount',
                                'Invoice #',
                                'Invoice Number',
                                'Invoice Date'
                            ]
                            if (excludeFields.includes(field.name)) {
                                return
                            }
                            return (
                                <li>
                                    {field.name}: <strong>{fieldValueFormatter(field, modals.viewNewInvoiceModal.data)}</strong>
                                </li>
                            )

                            // if(!modals.viewNewInvoiceModal.data[field.key]) return
                            // if(field.upper) {
                            //     return (
                            //         <div class="col-md-3 mb-3 detail-group">
                            //             <label for="invoice_number">{field.name}</label>
                            //             <h5 name={field.key}>{`${modals.viewNewInvoiceModal.data[field.key].charAt(0).toUpperCase()}${modals.viewNewInvoiceModal.data[field.key].slice(1)}`}</h5>
                            //         </div>
                            //     )
                            // }

                            // if(field.amount){
                            //     return (
                            //         <div class="col-md-3 mb-3 detail-group">
                            //             <label for="invoice_number">{field.name}</label>
                            //             <h5 name={field.key}>{`$${modals.viewNewInvoiceModal.data[field.key]}`}</h5>
                            //         </div>
                            //     )
                            // }

                            // if(field.format){
                            //     return (
                            //         <div class="col-md-3 mb-3 detail-group">
                            //             <label for="invoice_number">{field.name}</label>
                            //             <h5 name={field.key}>{moment(modals.viewNewInvoiceModal.data[field.key]).format(field.format)}</h5>
                            //         </div>
                            //     )
                            // }

                            // if (field.name !== 'Invoice #') {
                            //     return (
                            //         <div class="col-md-3 mb-3 detail-group">
                            //             <label for="invoice_number">{field.name}</label>
                            //             <h5 name={field.key}>{modals.viewNewInvoiceModal.data[field.key]}</h5>
                            //         </div>
                            //     )
                            // }
                            
                        })}
                        </ul>
                    </div>

                    {/* Show our miscellaneous charges */}
                    {/* {miscCharges.length > 0 ? 
                        <React.Fragment>
                            <div class="form-row">
                                <h4>Miscellaneous Charges</h4>                  
                            </div>
                            <div class="form-row">
                                {Object.keys(modals.viewNewInvoiceModal.data).filter(a => a.indexOf('misc_charge') > -1).map((charge) => {
                                    return (
                                        <div>

                                        </div>
                                    )
                                })}
                            </div>
                        </React.Fragment>
                    :''} */}
                    
                    {/* <h1 className="no-margin">Invoice Lines</h1> */}
                    <div class="form-row">
                        {/* Need to resolve the data structure issues when submitting an invoices that results in using invoiceLines and invoice_lines */}
                        <InvoiceLines errors={errors} readOnly={readOnly} register={register} invoiceLines={modals.viewNewInvoiceModal.data.invoice_lines || modals.viewNewInvoiceModal.data.invoiceLines}/>
                    </div>
                    {modals.viewNewInvoiceModal.data.files && modals.viewNewInvoiceModal.data.files.map((file, index) => {
                        return (
                            <React.Fragment>
                                <h6>Invoice Attachments</h6>
                                <div className="attachment">{/*<File size={16}/>*/}<img href={file} onClick={(e) => window.open(file)}  style={{height:100, cursor: 'pointer'}} src={`${file}`}/></div>
                            </React.Fragment>
                        )})}
                </div>
            </Modal.Body>
            {modals.viewNewInvoiceModal.data.status === 'pending' ? 
                <Modal.Footer>
                    {activeButton === 'approved' && formHelper.loading ? 
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button>
                    :
                        <Button onClick={() => approveInvoice('approved')} className="btn btn-primary">
                            Approve
                        </Button>
                    }
                    {activeButton === 'rejected' && formHelper.loading ? 
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </Button>
                    :
                        <Button onClick={() => rejectInvoice('rejected')} className="btn btn-primary">
                            Reject
                        </Button>
                    }
                    {false ? <button
                        type="button" 
                        className={"View All" === 'test' ? "btn btn-sm btn-secondary btn-icon-left" + " active selected" : "btn btn-sm btn-danger btn-icon-left"} 
                        onClick={() => setModals({...modals, addVendorModal: {open: true}})}
                    >
                        <PenTool /> Edit
                    </button>: ''}
                    {formHelper.errors.approve_invoice && formHelper.errors.approve_invoice.message}
                </Modal.Footer>
            : ''}
        </Modal>
    )
}