//Modules/Packages
import React, {useState,useEffect} from 'react';
import {Security, ImplicitCallback,SecureRoute,withAuth,Auth} from '@okta/okta-react'
import {BrowserRouter,Route} from 'react-router-dom'
import {checkAuthentication, OktaAuth} from './lib/oktaAuthHelpers'

//Handlers
import Authenticated from './handlers/Authenticated'
import Unauthenticated from './handlers/Unauthenticated'

//Components
import Loading from './components/Loading'
import ErrorPage from './Pages/ErrorPage'

//Css Files
import './css/bootstrap.min.css'
import './css/App.scss'

//Config Files
import appConfig from './config/app.json'
import oktaConfig from './config/okta.json'

function App() {
  const [authStatus, setAuthStatus] = useState(null)
  const auth = OktaAuth()

  useEffect(() => {
    if(authStatus === null) checkAuthentication(auth, setAuthStatus)
  },[authStatus])

  //this handles our callback URL for Okta
  if(window.location.pathname === '/implicit/callback') {
    return (
      <BrowserRouter>
        <Security 
          issuer={auth._config.issuer}
          client_id={auth._config.client_id}
          redirect_uri={auth._config.redirect_uri}
        >
          {/* --Okta Callback-- */}
          <Route path='/implicit/callback' component={ImplicitCallback}/>
          <Route exact path="/" render={(props) => window.location.replace(`/`)}/>
        </Security>
      </BrowserRouter>
    )
  }
  
  //If authentication check hasn't completed, deliver loading page
  if(authStatus === null) return <Loading/>

  //Check if there was an error in our auth status
  if(authStatus.errorCode) return <ErrorPage error={authStatus.errorMessage}/>

  //If authentication is false, deliver unathenicated routing options and screens
  if(authStatus === false) return <Unauthenticated auth={auth}/>

  //if authentication is true, deliver the full app
  if(authStatus) return <Authenticated auth={auth}/>
}

export default App;
