import React, {useState,useEffect} from 'react'
import {getUserAccountData} from '../lib/mongoDbhelpers'

export async function useGetUserHook(props){
    const [user, setUser] = useState(null)

    useEffect(() => {
        if(user === null) getUser(props)
    })

    async function getUser(props){
        setUser(await props.auth.getUser())
    }

    return user
}

export function useGetUserDataHook(auth, sub){
    const [userData, setUserData] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(userData === null && !loading){
            startGetUserAccountData(auth)
            setLoading(true)
        }

        async function startGetUserAccountData(auth){
            var user = await getUserAccountData(auth)
            if(user.errorCode) setUserData(user)
            setUserData(user)
            setLoading(false)
        }
    })

    return userData
}