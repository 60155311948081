import React,{useState} from 'react'
import moment from 'moment'
import {ArrowLeft,ArrowRight,Search} from 'react-feather'
import Select from 'react-select'
import {fieldValueFormatter} from '../../lib/util'
import Datepicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import appConfig from '../../config/app.json'

export default function InvoicesTable(props){
    //State
    const [selectedDate, setSelectedDate] = useState(null)
    
    return (
        <React.Fragment>
            {/* Header options */}
            <div className="tile">
                <p>Lookup specific invoices by invoice number, date, and amount.  Click on an invoice to see more information.</p>
                <table className="table table-sm table-hover border-bottom invoices-table">
                    <thead className="thead-light table-striped table-filters">
                        {/* Table header Names */}
                        <tr>
                            {appConfig.invoice_table_fields.map((hdr, index) => {
                                if(hdr.hidden) return null
                                return <th key={index}>{hdr.name}</th>
                            })}
                        </tr>

                        {/* Table input filters  -- The input field values are derviced from our filter hook*/}
                        <tr>
                            {appConfig.invoice_table_fields.map((hdr, index) => {
                                //Ensure there is a value for this field
                                var value = props.filters.find(a => a.key === hdr.key)
                                if(value) value = value.value

                                if(hdr.hidden) return null

                                if(hdr.date) return (
                                    <th key={index}>
                                        <Datepicker selected={selectedDate} name="invoiceTableFilters" id={`invoiceTableFilters${index}`} onChange={(e) => {
                                            props.addFilterLogic(hdr.key, e)
                                            setSelectedDate(e)
                                        }}></Datepicker>
                                        <Search />
                                    </th>
                                )

                                if(hdr.dropdown){
                                    return(
                                        <th key={index}>
                                            <select value={value} className="form-control" onChange={(e) => props.addFilterLogic(hdr.key, e.target.value)} name="invoiceTableFilters">
                                                <option>All</option>
                                                <option>Open</option>
                                                <option>Paid</option>
                                            </select>
                                        </th>
                                    )
                                }

                                return(
                                    <th key={index}>
                                        <Search />
                                        <input className="mx-0 px-0" name="invoiceTableFilters" id={`invoiceTableFilters${index}`} onChange={(e) => props.addFilterLogic(hdr.key, e.target.value)} value={value} type="text"></input>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {/* Primary Invoice List */}
                        {props.invoices && props.invoices.slice(props.options.idxStart, props.options.idxEnd).map((inv, index) => {
                            return(
                                <tr key={index} onClick={(e) => props.setInvoiceContext({invoice: inv, open: true})} className={props.invoiceContext && inv.invoice_number === props.invoiceContext.invoice.invoice_number ? 'table-primary' : ''}>
                                    {appConfig.invoice_table_fields.map((hdr, index) => {
                                        return <td style={{width:"10%"}} key={index}>{fieldValueFormatter(hdr, inv)}</td>
                                    })}
                                </tr>
                            )
                        })}

                        {/* Navigation options for list */}
                        {props.invoices && props.invoices.length > 15 ?
                            <tr>
                                <td colSpan={appConfig.invoice_table_fields.length}>
                                    <div className="pagination col-12 mr-auto ml-auto text-center">
                                        {props.options.idxStart != 0 ? <ArrowLeft onClick={() => props.handlePageIncrement(-15, props.invoices.length)} className={"featherTableButtons left feather-home mt-1"}/>: ''}
                                        <p style={{fontSize:20}} className="align-middle">{`${props.options.idxStart}-${props.options.idxEnd} of ${props.invoices.length}`}</p>
                                        {props.options.idxEnd != props.invoices.length ? <ArrowRight onClick={() => props.handlePageIncrement(15, props.invoices.length)} className={"featherTableButtons right feather-home mt-1"}/>: ''}
                                    </div>
                                </td>
                            </tr>
                        : ''}

                        {/*Text for when no results from search*/}
                        {props.invoices && props.invoices.length === 0 ? 
                            <tr>
                                <td colSpan="7">
                                    <h6 className="error">No results were found.</h6>
                                </td>
                            </tr>
                        : ''}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}