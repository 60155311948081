import React from 'react'

export default function NVSWelcome(props){

    return (
        <main role="main" class="col-10 mr-auto ml-auto">
            <div className="col-12 jumbotron my-0 py-4">
                <p className="lead font-weight-bold">Welcome!  We look forward to doing business with you!</p>
                <p className="lead">Here you will be guided through our onboarding process.  This will ensure you are setup in our system correctly!  You will need the below information:</p>
                <div className="d-flex flex-wrap flex-md-nowrap align-items-left pt-2 mt-3">
                        <ul>
                            <li>W9</li>
                            <li>Primary Contact Information</li>
                            <li>Payment Information (ACH, or Credit Card)</li>
                        </ul>
                    </div> 
                <button className="btn btn-primary btn-lg" type="button" onClick={() => props.setStep(1)}>Start</button>
            </div>
        </main>
    )
}