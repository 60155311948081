import React, {useState, useEffect} from 'react'
import {parseUrl} from 'query-string'
import {GetPayments,GetPaymentDetailsByNumber} from '../lib/mongoDbhelpers'
import {getSessionItem} from '../lib/sessionStorageHelper'

export function useGetPaymentsHook(userData, refreshCache){
    const [payments, setPayments] = useState(null)
    const [loading, setLoading] = useState(false)

    if(!userData) return null

    var paymentsInSession = null
    if(!refreshCache) paymentsInSession = getSessionItem('payments', userData)
    if(paymentsInSession && !payments) setPayments(paymentsInSession)
    if((userData && payments === null && !loading && !paymentsInSession) || refreshCache) {
        GetPayments(setPayments, setLoading, userData)
        setLoading(true)
    }

    return payments
}

export function useGetPaymentByUrlHook(key, paymentList){
    const [payment, setPayment] = useState(null)

    //parse the url
    var urlQuery = parseUrl(window.location.search)

    useEffect(() => {
        if(Object.keys(urlQuery.query).length === 0 && payment) setPayment(null)
        if(Object.keys(urlQuery.query).length > 0 && payment === null) GetPaymentDetails(urlQuery, key)

        async function GetPaymentDetails(urlQuery, key){
            var lookupValue = urlQuery.query[key]
            try {
                var paymentDetails = await GetPaymentDetailsByNumber(lookupValue)
                if(!paymentDetails && payment) setPayment(null)
                if(paymentDetails && payment === null) setPayment(paymentDetails)
                if(paymentDetails && payment && payment.payment_number !== paymentDetails.payment_number) setPayment(paymentDetails)
            } catch(e){
                setPayment({errorCode: '2001', errorMessage: `Failed to load payment information due to ${e.toString()}`})
            }
        }
    })
    return payment
}