import React, {useState,useEffect} from 'react'
import InvoicesTable from '../components/Invoices/InvoicesTable'
import InvoiceContext from '../Contextuals/InvoiceContext'
import DashboardGraphs from '../components/DashboardGraphs'
import cssConfig from '../config/css.json'
import appConfig from '../config/app.json'
import useFilterHook from '../hooks/useFilterHook';
import usePageOptions from '../hooks/usePageOptions';

export default function Dashboard(props){
    const [invoiceContext, setInvoiceContext] = useState(false)
    const pageOptions = usePageOptions()
    const invoiceList = useFilterHook(props.invoices, 'status')
    
    //initialize our page options
    if(pageOptions.options === null) pageOptions.setOptions({idxStart: 0, idxEnd: 15, selectedHeaderButton: 'View All'})

    var classes = invoiceContext && invoiceContext.open ? cssConfig.defaultSection + " col-9" : cssConfig.defaultSection + " col-12"

    if(pageOptions.options === null) return <div>Loading</div>

    return(
        <main role="main" class="col-10 ml-sm-auto">
            {appConfig.dashboard ? <DashboardGraphs classes={classes}/> : ''}
            {invoiceList ? 
                <InvoicesTable
                    classes={classes}
                    options={pageOptions.options}
                    invoices={invoiceList.list} 
                    invoiceContext={invoiceContext} 
                    setOptions={pageOptions.setOptions}
                    setInvoiceContext={setInvoiceContext}
                    addFilterLogic={invoiceList.addFilterLogic}/>
            : ''}
            {invoiceContext && invoiceContext.open ? 
                <InvoiceContext
                    invoice={invoiceContext}
                    onClose={setInvoiceContext}
                    history={props.history}
                />
            : ''}
        </main>
    )
}