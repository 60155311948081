import React from 'react'
import {Button} from 'react-bootstrap'
import moment from 'moment'
import {ArrowRightCircle} from 'react-feather'
import cssConfig from '../config/css.json'
import appConfig from '../config/app.json'
import {formatPrice} from '../lib/util'

export default function PaymentContext(props){
    var payment = props.payment.payment

    // Animate the sidebar in/out, setTimeout waits till sidebar is mounted to the DOM
    setTimeout(() => {
        const sidebar = document.querySelector('.sidebar-right');
        sidebar.setAttribute('style', 'right: 0');
    }, 10);

    const closeSidebar = () => {
        const sidebar = document.querySelector('.sidebar-right');
        sidebar.setAttribute('style', 'right: -238px');
        setTimeout(() => props.onClose({...props.payment, open: false}), 150);
    }

    return(
        <nav class="col-md-2 d-none d-md-block bg-light sidebar-right ml-4">
            <div class="sidebar-sticky col-12">
                <div className={cssConfig.defaultSection}>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <button className="btn btn-icon-left no-border btn-close" onClick={closeSidebar}>
                            <ArrowRightCircle size={20} className='featherTableButtons feather-home mt-1'/>
                            Close
                        </button>
                        <h5 className="mx-1">Payment Information</h5>
                    </div>              
                </div>
                <div className={cssConfig.defaultSection}>
                    <table className="table table-sm border-bottom">
                        <tbody>
                            {appConfig.payment_table_fields.map((field) => {
                                if(!appConfig.display_null_context_fields && !payment[field.key]) return null

                                if(field.mask){
                                    return (
                                        <tr>
                                            <td>{field.name}</td>
                                            <td>{`XXXXXX${(payment[field.key]).slice(6)}`|| ''}</td>
                                        </tr>
                                    )
                                }

                                if(field.method) {
                                    return (
                                        <tr>
                                            <td>{field.name}</td>
                                            <td>{payment[field.key][field.method] || ''}</td>
                                        </tr>
                                    )
                                }
                                if(field.format) {
                                    return (
                                        <tr>
                                            <td>{field.name}</td>
                                            <td>{moment(payment[field.key]).format(field.format)}</td>
                                        </tr>
                                    )
                                }

                                if(field.amount){
                                    console.log('%cVar Type', 'font-size: 20px; color: #bada55');
                                    // let amount; 
                                    // if(typeof payment[field.key] === 'string') amount = 
                                    return (
                                        <tr>
                                            <td>{field.name}</td>
                                            <td>{`${formatPrice(payment[field.key].toString().replace('.', ''))}`}</td>
                                        </tr>
                                    )
                                }

                                if(field.length) {
                                    return (
                                        <tr>
                                            <td>{field.name}</td>
                                            <td>{payment[field.key].length}</td>
                                        </tr> 
                                    )
                                }

                                return (
                                    <tr>
                                        <td>{field.name}</td>
                                        <td>{payment[field.key]}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="col-12">
                    <Button
                        className="btn btn-info btn-sm btn-block"
                        onClick={() => {
                            props.onClose({...props.payment, open: false})
                            props.history.push(`/payments?p=${payment['payment_number']}`)
                        }}
                    >More Information</Button>
                </div>
            </div>
        </nav>
    )
}