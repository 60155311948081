import React, {useState} from 'react'
import PaymentContext from '../Contextuals/PaymentContext'
import PaymentsTable from '../components/Payments/PaymentsTable'
import PaymentDetails from '../components/Payments/PaymentDetails'
import useFilterHook from '../hooks/useFilterHook';
import {useGetPaymentByUrlHook} from '../hooks/usePaymentsHook'
import usePageOptions from '../hooks/usePageOptions';
import Loading from '../components/Loading'
import ErrorPage from '../Pages/ErrorPage'

import cssConfig from '../config/css.json'
import appConfig from '../config/app.json'

export default function Payments(props){
    const [paymentContext, setPaymentContext] = useState(false)
    const [readOnly, setReadOnly] = useState(true)

    const paymentList = useFilterHook(props.payments)
    const paymentQuery = useGetPaymentByUrlHook('p', props.payments)
    const pageOptions = usePageOptions({idxStart: 0, idxEnd: 15, selectedHeaderButton: 'View All',headerOptions: true})

    var classes = cssConfig.defaultSection + " col-12"
    
    //initialize our page options
    //if(pageOptions.options === null) pageOptions.setOptions({idxStart: 0, idxEnd: 15, selectedHeaderButton: 'View All',headerOptions: true})
    if(pageOptions.options === null) return <Loading/>

    //Show error page is errors are detected
    if(paymentQuery && paymentQuery.errorCode) return <ErrorPage error={paymentQuery.errorMessage}/>
    if(paymentList && paymentList.errorCode) return <ErrorPage error={paymentList.errorMessage}/>

    return (
        <main role="main" class="col-10 ml-sm-auto">
            {paymentList && paymentQuery === null ? 
                <PaymentsTable
                    classes={classes}
                    payments={paymentList.list}
                    paymentContext={paymentContext}
                    setPaymentContext={setPaymentContext}
                    addFilterLogic={paymentList.addFilterLogic}
                    setOptions={pageOptions.setOptions}
                    options={pageOptions.options}
                    filters={paymentList.filters}
                    handlePageIncrement={pageOptions.handlePageIncrement}
                />
            :''}
            {!appConfig.payment_context_inline && paymentContext && paymentContext.open && paymentQuery === null ?
                <PaymentContext
                    payment={paymentContext}
                    onClose={setPaymentContext}
                    history={props.history}
                />
            :''}
            {paymentList && paymentQuery ?
                <PaymentDetails
                    {...props}
                    classes={classes}
                    payment={paymentQuery}
                    readOnly={readOnly}
                    filters={paymentList.filters}
                />
            :''}
        </main>
    )
}