import React from 'react'

export default function DashboardGraphs(props){
    return(
        <React.Fragment>
            <div className={props.classes}>
                <h1 class="h2">Dashboard</h1>
            </div>
            <div className={props.classes}>
                <div className="col-6">
                    <iframe style={{border: 'none', width: "600px", height:"420px"}} src="https://charts.mongodb.com/charts-vendorportal-anjyn/embed/charts?id=18d76a30-9e8a-4b4a-8cc0-e4b2758b82d9&tenant=6792ef1a-9dff-43e9-85d8-65a4defefa4d"></iframe>
                </div>
                <div className="col-6">
                    <iframe style={{border: 'none', width: "600px", height:"420px"}} src="https://charts.mongodb.com/charts-vendorportal-anjyn/embed/charts?id=d9dd2ec6-069e-470f-8bd5-f97a976a33cb&tenant=6792ef1a-9dff-43e9-85d8-65a4defefa4d"></iframe>
                </div>
            </div>
        </React.Fragment>   
    )
}