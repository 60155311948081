import React, {useState, useEffect} from 'react'
import {Modal,Spinner,Button} from 'react-bootstrap'

export default function CancelWorkflowItemModal(props){
    const handleClose = () => props.setModals({...props.modals, cancelWorkflowItemModal: {open: false, data: false}})

    return(
        <Modal size="lg" show={props.modals.cancelWorkflowItemModal.open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>This will cancel the pending setup.</h4>
            </Modal.Body>
        </Modal>
    )
}