import {Stitch,RemoteMongoClient,CustomCredential,BSON} from 'mongodb-stitch-browser-sdk'
import {setSessionItem} from '../lib/sessionStorageHelper'
import {createSelectFields,getUrlParam} from '../lib/util'
import {getUserToken} from '../lib/oktaAuthHelpers'
import config from '../config/mongo.json'
import appConfig from '../config/app.json'
import mongoConfig from '../config/mongo.json'

//Derives which Okta and Stitch configuration should be used by the application.
//Okta and Stitch have production and test versions.  If the environment is dev, we will always use the test environments unless expressly overrode
function determineEnvironment(type){
    if(type == 'appClient' && process.env.NODE_ENV === 'development'){
        return mongoConfig.atlasTest
    }

    if(type == 'appClient' && process.env.NODE_ENV != 'development'){
        return mongoConfig.atlasTest
    }

    if(!type && process.env.NODE_ENV != 'development'){
        return mongoConfig.stitchAppNameTest
    }

    if(!type && process.env.NODE_ENV === 'development'){
        return mongoConfig.stitchAppNameTest
    }
}

export function intializeStitchClient(){
    try {
        return Stitch.initializeDefaultAppClient(determineEnvironment('appClient'));
    } catch(err){
        return Stitch.defaultAppClient
    }
}

export function initalizeStitchServiceClient(client){
    try{
        return client.getServiceClient(RemoteMongoClient.factory, determineEnvironment(false)).db(config.defaultDb);
    } catch(err){
        return {errorCode: '002', errorMessage: err}
    }
}

function establishMongoDbConnection(){
    //get our default app client
    const client = intializeStitchClient()

    if(client.errorCode) return {errorCode: '002', errorMessage: client}

    const mongodb = initalizeStitchServiceClient(client)

    if(mongodb.errorCode) return {errorCode: '003', errorMessage: mongodb}
    
    return mongodb
}

export async function authenticateUserWithStitch(client, token){
    try {
        var user = client.auth.loginWithCredential(new CustomCredential(token))
        return user
    } catch (err){
       return {errorCode: '101', errorMessage: err}
    }
}

//FUNCTIONS
//START NEW VENDOR SETUP
export async function StartNewVendorSetup(values){
    try{
        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("StartNewVendorSetup", [{name: values.new_vendor_contact_name, email: values.new_vendor_contact_email}]);
        return result
    } catch(e){
        console.log(e)
    }
}

//GET ALL VENDORS INCLUDING VENDOR SETUPS
export async function GetAllVendorsAndSetups(values){
    try{
        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("GetAllVendorsAndSetups", []);
        return result
    } catch(e){
        return {errorCode: '002', errorMessage: e.toString()}
    }
}

//CANCEL NEW VENDOR SETUP
export async function CancelNewVendorSetup(id, reason){
    console.log(id, reason)
    try{
        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("CancelNewVendorSetup", [{id: id, reason: reason}]);
        if(result && result.errorCode) return {errorCode: result.errorCode, errorMessage: result.errorMessage}
        else return true
    } catch(e){
        return {errorCode: '002', errorMessage: e.toString()}
    }
}

//RESEND SETUP EMAIL
export async function ResendSetupEmail(id){
    try{
        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("ResendVendorSetupEmail", [{id: id}]);
        if(result && result.errorCode) return {errorCode: result.errorCode, errorMessage: result.errorMessage}
        else return true
    } catch(e){
        return {errorCode: '002', errorMessage: e.toString()}
    }
}

//GET VENDOR CONTACTS
export async function GetVendorContacts(id){
    var db = establishMongoDbConnection()
    if(db.errorCode) return db
    
    //add a user record
    try{
        const results = await db.collection('vendors').aggregate([{$match: {vendor_number: id}}, {$project: {contact: 1}}])
        if(!results) return false
        return results.first()
    } catch(err){
        return {errorCode: '004', errorMessage: 'Failed to add new user'}
    }
}

//GET VENDOR NAMES
export async function GetVendorNames(vendors, setVendors){
    try{
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        const vendors = await db.collection('vendors').aggregate([{$match: {status: {$ne: "pending setup"}}},{$project: {value: "$vendor_number", label: "$legal_name"}}]).toArray()
        setVendors(vendors)
    } catch(e){
        return e
    }
}

//SUBMIT SEND REMITTANCE JOB
export async function SubmitRemittanceJob(payment, emails){
    try{
        //get our default app client
        const client = intializeStitchClient()
        var params = {...payment}
        params.type = 'remittance'
        params.sendTo = emails

        var result = await client.callFunction("AddJobToJobQueue", [{...params}]);
        if(result && result.errorCode) return {errorCode: result.errorCode, errorMessage: result.errorMessage}
        else return true
    } catch(e){
        return {errorCode: '002', errorMessage: e.toString()}
    }
}

//GET ALL APP USERS
export async function GetAllUsers(){
    try{
        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("GetAllUsers", []);
        return result
    } catch(e){
        return {errorCode: '002', errorMessage: e.toString()}
    }
}

//ADD NEW USER
export async function AddNewUser(user){
    try{
        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("AddNewUser", [user]);
        return result
    } catch(e){
        return {errorCode: '002', errorMessage: e.toString()}
    }
}

//DEACTIVATE USER
export async function DeactivateUser(userId){
    try {
        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("DeactivateUser", [userId]);
        return result
    } catch(e) {
        return {errorCode: '002', errorMessage: e.toString()}
    }
}

//ACTIVATE USER
export async function ActivateUser(userId){
    try {
        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("ActivateUser", [userId]);
        return result
    } catch(e) {
        return {errorCode: '002', errorMessage: e.toString()}
    }
}

//RESET USER PASSWORD
export async function ResetUserPassword(userId){
    try {
        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("UserResetPassword", [userId]);
        return result
    } catch(e) {
        return {errorCode: '002', errorMessage: e.toString()}
    }
}


export async function createNewUserRequest(body, oktaResponse){
    var db = establishMongoDbConnection()
    if(db.errorCode) return db

    //add a user record
    try{
        const results = await db.collection('users').insertOne({...body, ...oktaResponse})
        if(!results) return false
        return true
    } catch(err){
        return {errorCode: '004', errorMessage: 'Failed to add new user'}
    }
}

export async function getUserAccountData(auth){
    try {
        //Get our user's Okta Token
        const token = await getUserToken(auth)

        //Authenticate our user in Stitch
        const user = await authenticateUserWithStitch(intializeStitchClient(), token)

        //Find the user in mongodb
        const mdbUserData = await findOneUser(user.id)

        //Create our full user object by combining user data and okta data
        const userObj = createUserObject(user, mdbUserData)

        return userObj
    } catch(e){
        return {errorCode: '1001', errorMessage: e}
    }
}


//Get Queries
async function findOneUser(id){
    try {
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        return await db.collection('users').findOne({mId: id})
    } catch(e){
        return e
    }
}

function createUserObject(user, mdbUser){
    return {...user.profile.data, ...user.profile.identities[0], id: user.id, security_group: mdbUser ? mdbUser.security_group : 'admin', vendors: mdbUser.vendors}
}

export async function GetWorkflowItems(setWorkflowItems){
    try{
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        const invoices = await db.collection('invoice_submissions').aggregate([{$addFields: {type: "New Invoice", submitted: "$submitted", status: '$invoice_status', last_updated: "$submitted", key_data: {vendor_number: "$vendor_name", invoice_number: "$invoice_number", invoice_date: "$invoice_date", invoice_amount: "$invoice_amount", }}}]).toArray()

        const vendors = await db.collection('vendor_setups').aggregate([{$addFields: {type: "New Vendor", submitted: "$created", last_updated: "$submitted", status: "$status", key_data: {contact_name: "$contact_name"}}}]).toArray()

        //const users = await db.collection('user_setups').find({status: 'pending'}).toArray()
        setWorkflowItems([...invoices, ...vendors])
    } catch(e){
        return e
    }
}

export async function doesInvoiceExist(invoiceNumber){
    try{
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        const invoices = await db.collection('invoices').findOne({"invoice_number": invoiceNumber.toString()})
        if(!invoices) return false
        return true
    } catch(e){
        return e
    }
}

export async function GetInvoiceDetailsByNumber(invoiceNumber){
    try{
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        var invoices = await db.collection('invoices').findOne({"invoice_number": invoiceNumber.toString()})
        if(!invoices) {
            invoices = await db.collection('invoice_submissions').findOne({"invoice_number": invoiceNumber.toString()})
        }
        return invoices
    } catch(e){
        return e
    }
}

export async function GetPaymentDetailsByNumber(paymentNumber){
    try{
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        const payments = await db.collection('payments').findOne({"payment_number": Number(paymentNumber)})
        return payments
    } catch(e){
        return e
    }
}

export async function GetInvoices(setInvoices, setLoading, userData){
    try{
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        var fieldsToSelect = createSelectFields(appConfig.invoice_context_fields)
        const invoices = await db.collection('invoices').aggregate([{$match: {"wfType": {$exists: false}}},{$project: fieldsToSelect}, {$limit: 1000},{$sort: {invoice_number: 1, invoice_date: 1}}]).toArray()
        const invoiceSubmissions = await db.collection('invoice_submissions').aggregate([{$match: {"wfType": {$exists: false}}},{$project: fieldsToSelect}, {$limit: 1000},{$sort: {invoice_number: 1, invoice_date: 1}}]).toArray()

        console.log(invoiceSubmissions)

        var allInvoices = [...invoices, ...invoiceSubmissions]

        if(setInvoices) {
            setSessionItem('invoices', allInvoices, userData)
            setInvoices(allInvoices)
            setLoading(false)
        }
        return invoices
    } catch(e){
        return e
    }
}

export async function GetPayments(setPayments, setLoading, userData){
    try{
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        var fieldsToSelect = createSelectFields(appConfig.payment_table_fields)
        const payments = await db.collection('payments').aggregate([{$project: fieldsToSelect}, {$limit: 50},{$sort: {payment_number: 1}}]).toArray()
        if(setPayments) {
            setSessionItem('payments', payments, userData)
            setPayments(payments)
            setLoading(false)
        }
        return payments
    } catch(e){
        return e
    }
}

export async function GetCompanyInfo(setCompanyInfo, setLoading, userData){
    try{
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        const info = await db.collection('vendors').find({"vendor_status": {$in: ['active', 'inactive']}, "vendor_number": {$in: userData.vendors}}).toArray()
        if(setCompanyInfo) {
            setCompanyInfo(info)
            setLoading(false)
        }
        return info
    } catch(e){
        return e
    }
}

export async function SubmitNewInvoice(body, userData){
    try{
        var files = []

        async function readFileAsDataURL(file) {
            let result_base64 = await new Promise((resolve) => {
                let fileReader = new FileReader();
                fileReader.onload = (e) => resolve(fileReader.result);
                fileReader.readAsDataURL(file);
            });
        
            return result_base64;
        }

        body.files = [await readFileAsDataURL(body.files[0])]

        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("SubmitNewInvoice", [{...body, ...userData}]);
        return true
    } catch(e){
        return {errorCode: '002', errorMessage: e.toString()}
    }
}

export async function GetVendorList(){
    try{
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        const vendors = await db.collection('vendors').find({"vendor_status": {$in: ['active', 'inactive', 'pending']}}).toArray()
        return vendors
    } catch(e){
        return {errorCode: '001', errorMessage: e}
    }
}

export async function ApproveNewInvoice(invoice, userData){
    try{
        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("ApproveNewInvoice", [{invoice: invoice, userData: userData}]);
        return true
    } catch(e){
        return {errorCode: '002', errorMessage: e.toString()}
    }
}

export async function RejectNewInvoice(invoice, userData){
    try{
        //get our default app client
        const client = intializeStitchClient()
        var result = await client.callFunction("RejectNewInvoice", [{invoice: invoice, userData: userData}]);
        return true
    } catch(e){
        return {errorCode: '002', errorMessage: e.toString()}
    }
}

export async function ApproveNewVendor(request, vendorId){
    try{
        //Initalize the connection to our configured database
        const client = intializeStitchClient()
        console.log(request)

        //mark request as approved transfer vendor to main table
        request.status = 'approved'
        request.vendor_status = 'active'
        request.vendor_number = 10005

        var result = await client.callFunction("ApproveNewVendor", [request]);

        console.log(result)

        return result

        // const status = await db.collection('vendors').insertOne({...request})
        // const original = await db.collection('vendor_setups').updateOne({"_id": new BSON.ObjectId(request._id)}, {...request})
        // var name = request.primary_contact_name.split(' ')

        // //create in okta
        // var response = await fetch("https://dev-128370.okta.com/api/v1/users?activate=true", {
        //     method: "POST",
        //     headers: {
        //         "Accept": "application/json",
        //         "Content-Type": "application/json",
        //         "Authorization": "SSWS 00SV4N0OFwjfI3p_4sadByCpeCnjGlB-FZhZ1tR-z2"
        //     },
        //     body: JSON.stringify({
        //         "profile": {
        //             "firstName": `${name[0]}`,
        //             "lastName": `${name[1]}`,
        //             "email": `${request.primary_contact_email}`,
        //             "login": `${request.primary_contact_email}`
        //         }
        //     })
        // })
        // response = await response.json()
        // var oId = response.id
        // response.vendors = [10004]
        // response.group = 'vendor-admin'
        // response.email = request.primary_contact_email
        // var addUser = await db.collection('users').insertOne({...response, groups: 'vendor-admin'})

        // return await db.collection('vendors').findOne({_id: status})
    } catch(e){
        console.log(e)
        return false
    }
}

export async function rejectNewVendor(request){
    //Initalize the connection to our configured database
    var db = establishMongoDbConnection()
}


export async function startNewVendorSetup(name, email){
    //get our default app client
    const client = intializeStitchClient()

    var result = await client.callFunction("initalizeNewVendor", [{name: name, email: email}]);
    return result
}

export async function RunReport(options){
    //get our default app client
    const client = intializeStitchClient()

    var result = await client.callFunction("RunReport", [options]);
    return result
}

export async function getNewVendorRequestDetails(setRequestDetails, setLoading){
    try{
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        const reqId = getUrlParam('id')

        const request = await db.collection('vendor_setups').findOne({_id: new BSON.ObjectId(reqId), 'status': "pending setup"})
        if(!request) {
            setRequestDetails(false)
            setLoading(false)
        } else {
            setRequestDetails(request)
            setLoading(false)
        }
    } catch(err){
        return false
    }
}

export async function SubmitNewVendorForm(values, requestDetails){
    try{
        //get our default app client
        const client = intializeStitchClient()

        var setupData = {
            requestId: requestDetails._id,
            legal_name: values.legal_name,
            dba_name: values.dba_name,
            tax_id: values.tax_id,
            primary_contact_number: values.primary_contact_number,
            primary_contact_email: values.primary_contact_email,
            primary_contact_name: values.primary_contact_name,
            vendor_class: values.vendor_class,
            payment_info: values.payment_info,
            addresses: values.addresses.map(a => { 
                return {address_type: a.address_type.label, address_attn: a.address_attn, address_address: a.address_address, address_city: a.address_city, address_state: a.address_state.label, address_zip: a.address_zip}
            }),
            contact: [
                {
                    contact_type: 'primary',
                    contact_number: values.primary_contact_number,
                    contact_email: values.primary_contact_email,
                    contact_name: values.primary_contact_name,
                }
            ],
            workflow: [
                {action: "submitted_by_vendor", date: new Date()}
            ],
            vendor_status: "pending approval"
        }

        var result = await client.callFunction("SubmitNewVendorSetup", [setupData, requestDetails.id]);
        return result
    } catch(e){
        console.log(e)
    }
}

export async function getUsers(){
    try{
        //Initalize the connection to our configured database
        var db = establishMongoDbConnection()

        const users = await db.collection('users').find({type: "normal"}).toArray()
        return users
    } catch(e){
        console.log(e)
        return false
    }
}