import React, {useState, useEffect} from 'react'
import useForm from 'react-hook-form'
import moment from 'moment'
import InvoiceLines from '../Invoices/InvoiceLines'
import {ArrowLeftCircle} from 'react-feather'
import {fieldValueFormatter,setBackUrl,setBackButtonTest} from '../../lib/util'
import appConfig from '../../config/app.json'
import {formatPrice} from '../../lib/util'

export default function InvoiceDetails(props){
    const { register, errors } = useForm()

    console.log('%cBread brumbs', 'font-size: 20px; color: #bada55');
    console.log(props.location.state) //bread 

    // Field Value Formatter could be used for the below, but I couldn't get it working
    const formatDate = new Date(props.invoice.invoice_date);

    var readOnly = props.readOnly
    var setReadOnly = props.setReadOnly

    return (
        <React.Fragment>
            <button className="btn-icon no-border btn-back" onClick={() => props.history.push(setBackUrl(props.location))}>
                <ArrowLeftCircle size={20} className='featherTableButtons feather-home mt-1'/> Back to {setBackButtonTest(props.location)}
            </button>
            <div className='tile'>
                <div className="invoice-header">
                    <ul className="big-fields">
                        <li className='number'>
                            <span>Invoice Number:</span>
                            {props.invoice.invoice_number}
                        </li>
                        <li>
                            <span>Invoice Amount:</span>
                            {formatPrice(props.invoice.invoice_amount.replace('.', ''))}
                        </li>
                        <li>
                            <span>Invoice Date:</span>
                            { formatDate.toLocaleDateString('en-US') }
                        </li>
                    </ul>
                    {/* <div className="top-total">
                        <span>Invoice Amount:</span>
                        ${props.invoice.invoice_amount}
                    </div> */}
                    <ul>
                    {appConfig.invoice_table_fields.map((field) => {
                        const excludeFields = [
                            'Invoice Amount',
                            'Invoice #',
                            'Invoice Number',
                            'Invoice Date'
                        ]
                        if (excludeFields.includes(field.name)) {
                            return
                        }
                        return (
                            <li>
                                {field.name}: <strong>{fieldValueFormatter(field, props.invoice)}</strong>
                            </li>
                        )

                        // if(!props.invoice[field.key]) return
                        // if(field.upper) {
                        //     return (
                        //         <div class="col-md-3 mb-3 detail-group">
                        //             <label for="invoice_number">{field.name}</label>
                        //             <h5 name={field.key}>{`${props.invoice[field.key].charAt(0).toUpperCase()}${props.invoice[field.key].slice(1)}`}</h5>
                        //         </div>
                        //     )
                        // }

                        // if(field.amount){
                        //     return (
                        //         <div class="col-md-3 mb-3 detail-group">
                        //             <label for="invoice_number">{field.name}</label>
                        //             <h5 name={field.key}>{`$${props.invoice[field.key]}`}</h5>
                        //         </div>
                        //     )
                        // }

                        // if(field.format){
                        //     return (
                        //         <div class="col-md-3 mb-3 detail-group">
                        //             <label for="invoice_number">{field.name}</label>
                        //             <h5 name={field.key}>{moment(props.invoice[field.key]).format(field.format)}</h5>
                        //         </div>
                        //     )
                        // }

                        // if (field.name !== 'Invoice #') {
                        //     return (
                        //         <div class="col-md-3 mb-3 detail-group">
                        //             <label for="invoice_number">{field.name}</label>
                        //             <h5 name={field.key}>{props.invoice[field.key]}</h5>
                        //         </div>
                        //     )
                        // }
                        
                    })}
                    </ul>
                </div>

                {/* Show our miscellaneous charges */}
                {/* {miscCharges.length > 0 ? 
                    <React.Fragment>
                        <div class="form-row">
                            <h4>Miscellaneous Charges</h4>                  
                        </div>
                        <div class="form-row">
                            {Object.keys(props.invoice).filter(a => a.indexOf('misc_charge') > -1).map((charge) => {
                                return (
                                    <div>

                                    </div>
                                )
                            })}
                        </div>
                    </React.Fragment>
                :''} */}
                
                {/* <h1 className="no-margin">Invoice Lines</h1> */}
                <div class="form-row">
                    {/* Need to resolve the data structure issues when submitting an invoices that results in using invoiceLines and invoice_lines */}
                    <InvoiceLines {...props} errors={errors} readOnly={readOnly} register={register} invoiceLines={props.invoice.invoice_lines || props.invoice.invoiceLines}/>
                </div>
                <div>
                    
                    {props.invoice.files && props.invoice.files.map((file, index) => {
                        return (
                        <React.Fragment>
                            <h6>Invoice Attachments</h6>
                            <div className="attachment">{/*<File size={16}/>*/}<img href={file} onClick={(e) => window.open(file)}  style={{height:100, cursor: 'pointer'}} src={`${file}`}/></div>
                        </React.Fragment>
                    )})}
                </div>
            </div>
        </React.Fragment>
    )
}