import React, {useState,useEffect} from 'react'
import {Modal,Button,Spinner} from 'react-bootstrap'
import Select from 'react-select'
import {AddNewUser} from '../../lib/mongoDbhelpers'
import {valueExists,stringValidation} from '../../lib/fieldValidation'

export default function NewUserModal(props){
    const handleClose = () => props.setModals({...props.modals, newUserModal: {open: false, data: false}})
    const [form, setForm] = useState({first_name: '', last_name: '', contact_email: '', security_group: '', vendors: [], created_by: {id: props.userData.id, userName: props.userData.userName, fullName: props.userData.fullName}})

    async function addNewUser(){
        var valid = true
        props.formHelper.clearError()
        if(!valueExists(form.first_name, false)) {
            props.formHelper.setError('first_name', 'missing', 'Please enter a first name.')
            valid = false
        }
        
        if(!valueExists(form.last_name, false)) {
            props.formHelper.setError('last_name', 'missing', 'Please enter a last name.')
            valid = false
        }


        if(!valueExists(form.contact_email, false)) {
            props.formHelper.setError('contact_email', 'missing', 'Please enter a contact email address.')
            valid = false
        }

        if(!stringValidation(form.contact_email, /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i)) {
            props.formHelper.setError('contact_email', 'missing', 'Please enter a valid email address.')
            valid = false
        }

        if(!valueExists(form.security_group, false)) {
            props.formHelper.setError('security_group', 'missing', 'Please select a security group.')
            valid = false
        }

        if(!valueExists(form.vendors, false) || form.vendors.length === 0) {
            props.formHelper.setError('vendors', 'missing', 'Please select a vendor')
            valid = false
        }

        if(valid) {
            props.formHelper.clearError()
            props.formHelper.setLoading(true)
            var result = await AddNewUser(form)
            if(result.error && result.error.errorCode){
                var errors = result.error.errorCauses.map(a => a.errorSummary)
                props.formHelper.setError('submit_error', 'function_failed', errors.join('~'))
                props.formHelper.setLoading(false)
                return false
            } else if (result.errorCode){
                props.formHelper.setError('submit_error', 'function_failed', result.errorMessage)
                props.formHelper.setLoading(false)
                return false
            } else {
                props.formHelper.setLoading(false)
                props.formHelper.clearError()
                props.formHelper.setShowToast(true)
                setForm({first_name: '', last_name: '', contact_email: '', security_group: '', vendors: []})
                props.usersHook.refresh()
                handleClose()
            }
        }

        return false
    }


    return (
        <Modal show={props.modals.newUserModal.open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Vendor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please enter a name and email.</p>
                <form>
                    <div className="form-row">
                        <div class="col-md-6">
                            <label for="invoice_number">First Name</label>
                            <input className="form-control" value={form.first_name} onChange={(e) => setForm({...form, [e.target.name]: e.target.value})} type="text" name="first_name" id="first_name"></input>
                            <span className="error">{props.formHelper.errors.first_name && props.formHelper.errors.first_name.message}</span>
                        </div>
                        <div class="col-md-6">
                            <label for="invoice_number">Last Name</label>
                            <input className="form-control" value={form.last_name} onChange={(e) => setForm({...form, [e.target.name]: e.target.value})} type="text" name="last_name" id="last_name"></input>
                            <span className="error">{props.formHelper.errors.last_name && props.formHelper.errors.last_name.message}</span>
                        </div>
                        <div class="col-md-6">
                            <label for="invoice_number">Contact Email</label>
                            <input className="form-control" value={form.contact_email} onChange={(e) => setForm({...form, [e.target.name]: e.target.value})} type="text" name="contact_email" id="contact_email"></input>
                            <span className="error">{props.formHelper.errors.contact_email && props.formHelper.errors.contact_email.message}</span>
                        </div>
                        <div class="col-md-6">
                            <label for="invoice_number">Security Group</label>
                            <Select value={form.security_group} onChange={(e) => setForm({...form, security_group: e})} options={[{label: "Vendor Admin", value: "vendor-admin"},{label: "AP Admin", value: "ap-admin"},{"label": "App Admin", "value": "admin"}]}></Select>
                            <span className="error">{props.formHelper.errors.security_group && props.formHelper.errors.security_group.message}</span>
                        </div>
                    </div>
                    <div className="form-row">
                        <p>Please select the vendors to affiliate this user with.</p>
                    </div>
                    <div className="form-row">
                        <div class="col-md-12">
                            <label for="invoice_number">Vendors</label>
                            <Select onChange={(e) => setForm({...form, vendors: e})} name="vendors" value={form.vendors} isMulti={true} options={props.vendorsHook.vendors.map((vendor) => {
                                return {label: `${vendor.vendor_number} - ${vendor.dba_name}`, value: vendor.vendor_number}
                            })}></Select>
                            <span className="error">{props.formHelper.errors.vendors && props.formHelper.errors.vendors.message}</span>
                        </div>
                    </div>
                    <span className="error">{props.formHelper.errors.submit_error && props.formHelper.errors.submit_error.message}</span>
                </form>
            </Modal.Body>
            <Modal.Footer>
                {props.formHelper.loading ? 
                    <Button variant="primary" disabled>
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        />
                    </Button>
                : 
                    <React.Fragment>
                        <Button className="btn btn-secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button type="button" onClick={() => addNewUser()} className="btn btn-primary">
                            Submit
                        </Button>
                    </React.Fragment>
                }
            </Modal.Footer>
        </Modal>
    )
}