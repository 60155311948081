import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import {Home, File, ShoppingCart, Users, Layers, Settings, Download} from 'react-feather'
import {Modal,Button} from 'react-bootstrap'
import {RunReport} from '../lib/mongoDbhelpers'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import appConfig from '../config/app.json'

const sidebarOptions = [
    {redirect: 'dashboard', name: 'dashboard', titleName: 'Dashboard', curPath: '/dashboard', icon: Home},
    {redirect: 'invoices', name: 'invoices', titleName: 'Invoices', curPath: '/invoices', icon: File},
    {redirect: 'payments', name: 'payments', titleName: 'Payments', curPath: '/payments', icon: ShoppingCart},
    {redirect: 'submit', name: 'submit', titleName: 'Add Invoices', curPath: '/submit', icon: Layers},
    {redirect: 'info', name: 'info', titleName: 'Company Info', curPath: '/info', icon: Users, vendor: true},
    {redirect: 'settings', name: 'settings', titleName: 'Settings', curPath: '/settings', icon: Settings, admin: true}
]

export default function Sidebar(props){
    const [reportModal, setReportModal] = useState({})

    function findLocation() {
        const links = document.getElementsByClassName('nav-link');
        const highLight = document.querySelector('.menu-highlight');
        sidebarOptions.map((option) => {
            if (window.location.pathname == `/${option.name}`) {
                highLight.innerHTML = (option.titleName);
                // highLight.innerHTML = (`<i data-feather="circle"></i> ${option.titleName}`);
                // let navIcon = <option.icon className={"feather feather-home"}/>
                // let navIcon = () => { return (<option.icon className={"feather feather-home"}/>) }
                Object.values(links).map((link) => {
                    for (const a of document.querySelectorAll(".main-nav a")) {
                        if (a.textContent.replace(' (current)', '') == option.titleName) {
                            const pos = a.offsetTop;
                            highLight.setAttribute("style", "top: " + pos + 'px');
                        }
                    }
                });                
            }
        });
    }

    function highLight(e, string) {
        const s = new XMLSerializer();
        const icon = s.serializeToString(e.target.querySelector('svg'));
        const pos = e.target.offsetTop;;
        const highLight = document.querySelector('.menu-highlight');
        const highLightText = e.target.innerText.replace('(current)', '');        
        highLight.setAttribute("style", "top: " + pos + 'px');
        highLight.innerHTML = icon + highLightText;
    }

    return (
        <nav className="col-md-2 d-none d-md-block sidebar">
            <div className="sidebar-sticky">
                <div className="menu-highlight" onClick={highLight}>
                    {
                        (function () {
                            const highLight = document.querySelector('.menu-highlight');
                            if (highLight) {
                                // If hightlight isn't populated, mainly on page load/refresh
                                if (!highLight.innerHTML) {
                                    findLocation();
                                }
                            }
                          }())
                    }              
                </div>
                <ul className="nav flex-column main-nav">
                    {sidebarOptions.map((option, index) => {
                        if(option.name === "dashboard" && !appConfig.dashboard) return null
                        if(option.name === 'settings' && props.userData.fullName === '') return null
                        if(option.admin && props.userData.security_group !== 'admin') return false
                        if(option.vendor && props.userData.security_group === 'admin') return false
                        return (
                            <li key={index}>
                                <Link to={option.redirect} className={window.location.pathname == `/${option.name}` ? 'nav-link active' : 'nav-link'} onClick={highLight}>
                                    <option.icon className={"feather feather-home"}/>
                                    {option.titleName} <span className="sr-only">(current)</span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                {appConfig.reports && appConfig.reports.length > 0 ? 
                     <React.Fragment>
                         <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                             <span>Reports</span>
                             <a className="d-flex align-items-center text-muted" href="#">
                                 <span data-feather="plus-circle"></span>
                             </a>
                         </h6>
                         <ul className="nav flex-column mb-2">
                             {appConfig.reports.map((report) => {
                                 return (
                                     <li className="nav-item" onClick={() => setReportModal({open: true, setReportModal: setReportModal, report: report})}>
                                         <a className="nav-link" href="#">
                                         <span data-feather="file-text"></span>
                                             {report.name}
                                         </a>
                                     </li>
                                 )
                             })}
                         </ul>
                     </React.Fragment>
                 : ''}
             {ReportModal(reportModal)}
            </div>
        </nav>
    )
}

function ReportModal(report){
    const [reportParams, setReportParams] = useState({})
    const [result, setResult] = useState(false)
    const [error, setError] = useState(false)
    if(!report.open) return false

    async function runReport(){
        //call monogo db function to create the report file
        setResult(false)
        setError(false)

        var results = ''

        if(report.report.name === 'Invoices By PO'){
            results = await RunReport({type: report.report.name, start: reportParams.range_start, end: reportParams.range_end})
        } else if(report.report.name === "Invoices on Record") {
            results = await RunReport({
                type: report.report.name, 
                date_range_start: moment(reportParams.date_range_start).format(), 
                date_range_end: moment(reportParams.date_range_end).format(),
                invoice_range_start: reportParams.invoice_range_start,
                invoice_range_end: reportParams.invoice_range_end
            })
        } else {
            results = await RunReport({type: report.report.name, start: moment(reportParams.range_start).format(), end: moment(reportParams.range_end).format()})
        }

        if(results.errorCode) {
            setError(results.errorMessage)
            return
        }

        if(results && !results.errorCode){
            const element = document.createElement("a");
            const file = new Blob([results], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = "myFile.csv";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            setResult('Success')
        }
    }

    return (
        <Modal show={report.open} onHide={() => {
            report.setReportModal({open: false})
            setError(false)
            setResult(false)
            setReportParams({})
        }}>
            <Modal.Header closeButton>
                <Modal.Title>{report.report.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <p>{report.report.message}</p>
                    <form>
                        <div className="form-row">
                            {report.report.parameters.map((parameter) => {
                                if(parameter.type === 'date') {
                                    return (
                                        <div class="col-md-6">
                                            <label for="inputPassword4">{parameter.name}</label>
                                            <DatePicker
                                                selected={reportParams[parameter.key] ? reportParams[parameter.key] : ''}
                                                onChange={(e) => {
                                                    //setValue([parameter.key], e)
                                                    setReportParams({...reportParams, [parameter.key]: e})
                                                    //clearError(parameter.key)
                                                    }
                                                }
                                                className={false ? "form-control is-invalid" : "form-control"}
                                            />
                                        </div>
                                    )
                                }

                                return (
                                    <div class="col-md-6">
                                        <label for="invoice_number">{parameter.name}</label>
                                        <input type="text" onChange={(e) => setReportParams({...reportParams, [parameter.key]: e.target.value})} className={"form-control"} name="invoice_number" id="invoice_number"></input>
                                        {/* {errors.invoice_number && errors.invoice_number.message} */}
                                    </div>
                                )
                            })}
                        </div>
                    </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="btn btn-primary btn-icon-left" onClick={() => runReport()}>
                    <Download /> Download Report
                </Button>
                {error ? <h6>{error}</h6> : ''}
                {result ? <h6>{result}</h6> : ''}
            </Modal.Footer>
        </Modal>
    )
}